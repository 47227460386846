import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import CurrencySign from '../../../components/UI/CurrencySign';
import CampsiteTypeIcon from '../../../components/UI/CampsiteTypeIcon';
import {SINGLE_POST_PAGE} from '../../../settings/constant';
import LocationIcon from '../../../assets/images/icons/location.svg';
import RateShareFavoriteBox from '../../../components/UI/RateShareFavoriteBox';
import Image from '../../../components/UI/ImageComponent';
import PlaceholderImage from '../../../assets/images/campsites/placeholder.jpg';

import * as Style from './style';
import AmenitiesList from './parts/AmenitiesList';
import SpotTypeIcon from './parts/SpotTypeIcon';

const CampsiteCard = ({
  t,
  campsiteImage,
  campsiteSlug,
  campsiteType,
  city,
  address,
  name,
  types,
  amenities,
  price,
  currency,
  rating,
  isHighlighted = false,
  hideBorder = false,
  useOpenInNewTab = false,
}) => {

  const getCampsiteLink = (content) =>  (
    <Link target={useOpenInNewTab ? '_blank' : '_self'} to={`${SINGLE_POST_PAGE}/${campsiteSlug}`}>
      {content}
    </Link>
  )
  const getPriceComponent = () => (
    <Style.Price>
      <Style.Price.Title>{t('BrowseCampsites.common.from')}</Style.Price.Title>
      <Style.Price.Amount><CurrencySign isoCode={currency} amount={price} /></Style.Price.Amount>
      <Style.Price.SubText>/ {t('BrowseCampsites.common.night')}</Style.Price.SubText>
    </Style.Price>
  );

  const getMetaComponent = () => (
    <Style.Meta>
      <Style.Meta.Location><img src={LocationIcon} alt="location" />
        {city || ''}{city && address && ' / '}{address || ''}
      </Style.Meta.Location>
      <Style.Meta.Name>
        {getCampsiteLink((<>{name} <CampsiteTypeIcon campsiteType={campsiteType} /></>))}
      </Style.Meta.Name>
    </Style.Meta>
  );

  const getPropertyTypesComponent = () => types && Object.entries(types).map(([key, value]) => (
    <Style.PropertyType key={key}>
      <SpotTypeIcon spotType={key} value={value} />
    </Style.PropertyType>
  ));

  return (
    <>
      <Style.DWrapper hideBorder={hideBorder} key={`d-${campsiteSlug}`} isHighlighted={isHighlighted}>
        <Style.DImage>
          {getCampsiteLink((<Image useAnimations src={campsiteImage || PlaceholderImage}/>))}
        </Style.DImage>
        <Style.DContent>
          <Style.DRow>
            {getMetaComponent()}
            {price && getPriceComponent()}
          </Style.DRow>
          <Style.DRow>
            {getPropertyTypesComponent()}
          </Style.DRow>
          {amenities && amenities.length > 0 &&
            <Style.DRow>
              <AmenitiesList amenities={amenities} />
            </Style.DRow>
          }
          <Style.DRowClear>
            <RateShareFavoriteBox rating={rating} slug={campsiteSlug} />
          </Style.DRowClear>
        </Style.DContent>
      </Style.DWrapper>
      {/* TODO: HPY-341 | Mobile version of new card */}
      <Style.MWrapper hideBorder={hideBorder} key={`m-${campsiteSlug}`} isHighlighted={isHighlighted}>
        <Style.MImage>
          {getCampsiteLink((<Image useAnimations src={campsiteImage || PlaceholderImage}/>))}
        </Style.MImage>
        <Style.MContent>
          <Style.MRow>
            {getMetaComponent()}
            {price && getPriceComponent()}
          </Style.MRow>
          <Style.MRow>
            {getPropertyTypesComponent()}
          </Style.MRow>
          {amenities && amenities.length > 0 &&
            <Style.MRow>
              <AmenitiesList isSmall amenities={amenities} />
            </Style.MRow>
          }
          <Style.MRowClear>
            <RateShareFavoriteBox rating={rating} slug={campsiteSlug} />
          </Style.MRowClear>
        </Style.MContent>
      </Style.MWrapper>
    </>
  );

};

export default withTranslation()(CampsiteCard);
