import {createContext, useContext} from 'react';

import {withTranslation} from 'react-i18next';

import useNotification from '../library/hooks/useNotification';

import {AuthContext} from './AuthProvider';

export const AfaContext = createContext({});

export const AfaURL = Object.freeze({
  LIST: '/profile/afa',
  ADD: '/profile/afa/add',
});

export const AfaProvider = ({children, t}) => {
  const authProvider = useContext(AuthContext);
  const authIsLoggedIn = authProvider.getLoggedIn();
  const notification = useNotification();

  const addAfa = async (data) => {
    if (authIsLoggedIn) {
      await authProvider.APIProvider.makeRequest(AfaURL.ADD, data, 'POST').then(() => {
        notification.show(t('afa.afaAdded'), 'success');
      }).catch(err => {
        notification.show(err.message, 'error');
      });
    }
  }

  return (
    <AfaContext.Provider
      value={{authIsLoggedIn, addAfa}}
    >
      {children}
    </AfaContext.Provider>
  );
}

export default withTranslation()(AfaProvider);
