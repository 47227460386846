import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 140%;
  text-align: center;
  color: ${themeGet('textColor.primaryDark')};
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 170%;
  margin: 0;
  padding: 0;
  color: ${themeGet('textColor.secondary')};
  text-align: center;
  margin-top: 24px;
`;

export const ButtonsSection = styled.div`
  width: max-content;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
`;
