import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

import headerBackground from '../../assets/images/header-background.png';

export const StaticPageBody = styled.div`
  padding: 0 ${themeGet('globalPaddingH.mobile')};
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    padding: 0 ${themeGet('globalPaddingH.desktop')};
  }
`;

export const StaticPageContent = styled.div`
    display: block;
    max-width: 60rem;
    margin: 5rem auto;
`;

export const StaticPageHeader = styled.div`
    margin-bottom: 1rem;
    background-image: url(${headerBackground});
    background-position: center;
    background-size: cover;
    height: 180px;
    position: relative;
    z-index: 1;
    
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
     margin: 0 ${themeGet('globalPaddingH.desktop')};
     border-radius: 24px;
    }
`;

export const StaticPageHeaderOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 2;
    
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
        border-radius: 24px;
    }
`;

export const StaticPageTitle = styled.h2`
    text-align: center;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${themeGet('textColor.white')};
    font-size: 42px;
    font-weight: 300;
    line-height: 140%;
`;

export const StaticPageSubHeader = styled.h3`
    font-style: italic;
    margin-bottom: 0;
`;


export const StaticPageDate = styled.div`
    font-weight: 200;
`;

export const StaticPageSection = styled.div`
    ul { list-style: outside; }
    li { list-style-type: disc; margin-left: 2rem; }
`;

export const StaticPageSubSection = styled.div`
    margin: 0 5rem;
`;