import styled from 'styled-components';

import {CampsiteRatingWrapper} from '../CampsiteRating/style';

const LoadingEffectWrapper = styled.div`
  pointer-events: none;
  animation: blur 3s infinite;
  letter-spacing: -3px;
  mask-image: linear-gradient(to right, white, rgba(255, 255, 255, 0.2), white);
  mask-size: 200% 100%;
  animation: wave 3s infinite alternate;

  @keyframes wave { 100% { mask-position: right; } }

  ${CampsiteRatingWrapper} { opacity: 0; }
`;

const TextSpan = styled.span`
  letter-spacing: -0.4rem;
  padding: 0 0.4rem;
`;

const LoadingUtils = () => {
  const getRandom = (min, max) => min + Math.floor(Math.random() * (max - min));

  const getText = (min, max) => '■'.repeat(getRandom(min, max));

  const getSpanText = (min, max) => (<TextSpan>{getText(min, max)}</TextSpan>)
 
  return {LoadingEffectWrapper, getText, getSpanText, getRandom}
};

export default LoadingUtils;
