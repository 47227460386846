import React from 'react';

import Modal from 'react-bootstrap/Modal';
import {withTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';

import reservation_check from '../../../../../assets/images/singlepage/reservation-check.png';
import Button from '../../../../../components/UI/Button/Button';
import {PAYMENT_SESSION_ID_KEY} from '../../../../../settings/configuration';
import {ReservationModalIconWrapper, ReservationModalIcon, ReservationModalTitle, ReservationModalText} from '../style';

const SinglePageConfirmationModal = ({
  t,
  isModalShowing,
  setIsModalShowing,
  isOnlyPayment = false,
}) => {
  const history = useHistory();
  const location = useLocation();

  const closeModal = () => {
    localStorage.removeItem(PAYMENT_SESSION_ID_KEY);
    history.replace({pathname: location.pathname, search: ''});
    setIsModalShowing(false);
  }

  return (
    <Modal
      show={isModalShowing}
      onHide={() => closeModal()}
      dialogClassName="modal-90w reservation_completed"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t(`singlePage.${isOnlyPayment ? 'PaymentCompleted' : 'ReservationCompleted'}`)}!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReservationModalIconWrapper>
          <ReservationModalIcon src={reservation_check} />
        </ReservationModalIconWrapper>
        {isOnlyPayment
          ? (<ReservationModalTitle>{t('singlePage.ThanksForPayment')}!</ReservationModalTitle>)
          : (
            <>
              <ReservationModalTitle>{t('singlePage.ThanksForReservation')}!</ReservationModalTitle>
              <ReservationModalText>{t('singlePage.ReservationCompletedText')}</ReservationModalText>
            </>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => closeModal()}
          text={t('singlePage.Close')}
          type="primary"
          size={100}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default withTranslation()(SinglePageConfirmationModal);
