import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

import placeholderImg from '../../../../assets/images/campsites/placeholder.jpg';

export const SinglePagePricingGroupsWrapper = styled.div`
    margin-top: 24px;
    
    .nav-tabs {
        margin: 0;
        font-size: 14px;
        border-bottom: none;
        width: 100%;
        
        .nav-link {
          color: ${themeGet('textColor.secondary')};
          font-weight: 500;
          outline: none;
          border: none;
          padding: 10px;
          border-bottom: 3px solid transparent;
                
          @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
            padding: 8px 12px;
          }
        }
        
        .nav-link.active {
            border-left:none;
            border-top:none;
            border-right:none;
            border-color: ${themeGet('backgroundColor.primary')};
            color: ${themeGet('textColor.primaryDark')};
        }
        
        .nav-link:hover {
            border-left:none;
            border-top:none;
            border-right:none;
            border-color: ${themeGet('backgroundColor.primary')};
        }
        
        .nav-link:focus {
            border-left:none;
            border-top:none;
            border-right:none;
            border-color: ${themeGet('backgroundColor.primary')};
        }
    }
`
export const SinglePagePricingGroupsSingleWrapper = styled.div`
    border: 1px ${themeGet('borderLines.wrapper')} solid;
    border-radius: ${themeGet('borderRadius.small')};
    margin-top: 12px;
`

export const SinglePagePricingGroupsSingleImage = styled.div`
    width: 130px;
    height: 90px;
    background-color: ${themeGet('backgroundColor.lightBlueBox')};
    background-image: url("${props => props.imageSrc || placeholderImg}");
    background-position: center;
    background-size: cover;
    border-radius: ${themeGet('borderRadius.small')};
    
    @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
      width: 180px;
      height: 126px;
    }
`

export const SinglePagePricingGroupsSingleTitle = styled.div`
    color: ${themeGet('textColor.primaryDark')};
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    width: 100%;
    
    @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
        font-size: 20px;
    }
`
export const SinglePagePricingGroupsSingleDescription = styled.div`
    color: ${themeGet('textColor.secondary')};
    font-size: 14px;
    
    @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
        font-size: 16px;
    }
`
export const SinglePagePricingGroupsSinglePrice = styled.div`
    color: ${themeGet('textColor.primary')};
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -1px;
    
    .SinglePagePricingGroupsSinglePrice__from {
      font-weight: 400;
      font-size: 14px;
      color: ${themeGet('textColor.secondary')};
      margin-right: 4px;
      letter-spacing: 0;
    }
    
    .SinglePagePricingGroupsSinglePrice__unit-label {
        color: ${themeGet('textColor.secondary')};
        font-size: 14px;
        font-weight: 400;
        margin-left: 4px;
        letter-spacing: 0;
    }
    
    @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
        font-size: 32px;
        text-align: right;
    }
`
export const SinglePagePricingGroupsSinglePriceCtr = styled.span`
    font-size:14px;
    color:${themeGet('textColor.primary', '#FF8E00')} !important;
    @media only screen and (max-width: 767px) {
        font-size:10px;
    }

`
export const SinglePagePricingGroupsSingleTop = styled.div`
    display: block;
    width: 100%;
    padding: 16px;

    @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
`

export const SinglePagePricingGroupsSingleHeadline = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  
  @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
`;

export const SinglePagePricingGroupsSingleModalTop = styled.span`
    padding-bottom:20px;
    display:flex;
    @media only screen and (max-width: 767px) {
        display:block;
    }
`
export const SinglePagePricingGroupsSingleTagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width:100%;
    padding:28px 24px;
    font-size:14px;
    border-top: 1px ${themeGet('borderLines.wrapper')} solid;
    color:${themeGet('textColor.secondary')};
    gap: 30px;
    row-gap: 15px;
`

export const SinglePagePricingGroupsSingleTagsWrapperFeature = styled.div`
    min-width: max-content;
    display: flex;
    gap: 8px;
    width: 20%;

    img {
        align-self: center;
        margin-right:5px;
        width: 16px;
        height: 16px;
    }

    div {
        white-space: nowrap;
        height: 100%;
        box-sizing: border-box;
    }
`

export const SinglePagePricingGroupsSingleTitleWrapper = styled.div`
    padding:32px 24px;
    @media only screen and (max-width: 767px) {
        width:60%;
        padding:0px 16px;
    }
`
export const SinglePagePricingGroupsSinglePriceWrapper = styled.div`
    text-align: right;
    width: 40%;
    
    @media only screen and (max-width: 767px) {
        width: 60%;
        text-align: left;
        padding: 0px 16px;
    }
`

export const SinglePagePricingGroupsSingleTagsImg = styled.img`
    margin-top:4px;
    margin-right:5px;
`

export const SinglePageButtonMobile = styled.div`
  width: 100%;
  @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
    display: none;
  }
`;

export const SinglePageButtonDesktop = styled.div`
  display: none;
  @media only screen and (min-width: ${themeGet('breakpoint.medium')} ) {
    display: block;
  }
`;

export const ReservationModalIcon = styled.img`
    
`

export const ReservationModalTitle = styled.div`
    float:left;
    width:100%;
    text-align:center;
    font-size:20px;
    margin-bottom: 16px;
    color: ${themeGet('textColor.primaryDark')};
`
export const ReservationModalText = styled.div`
    margin:0 auto;
    width:70%;
    text-align:center;
    font-size:16px;
    color: ${themeGet('textColor.secondary')};
    white-space: break-spaces;
}
`

export const ReservationModalIconWrapper = styled.div`
    text-align:center;
    margin-bottom:18px;
`
export const ReservationModalFormRow = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;

    & > div { width: 50%; }
`;

export const SinglePagePricingGroupsSingleForm = styled.div`
    .afa_button {
        & button {
            margin-left: auto;
            order: 2;
            width: 100%;
            
            @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
                width: max-content;
            }
        }
    }
    .ant-form-item-required:before{
        display:none !important;
    }
    .ant-form-item-label{
        float:left;
        width:100%;
        text-align:left;
    }
    .additional_pets{
        & .ant-form-item-label{
            float:left;
            width:100%;
            text-align:left;
            font-size:14px;
            color: ${themeGet('textColor.primaryDark')};
        }
        & .ant-switch{
            float:left;
            background: ${themeGet('textColor.primary', '#FF8E00')} !important;
        }
        
    }
    .personal_info{
        padding:24px 0px;
        border-top:1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
        & .ant-form-item-label{
            float:left;
            width:100%;
            text-align:left;
            font-size:14px;
            color: ${themeGet('textColor.primaryDark')};
        }
        & .ant-form-item-control-input-content{
            border: 1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
            border-radius: ${themeGet('borderRadius.small')};
        }
        
    }
    .number_of_people{
        padding:24px 0px;
        border-top:1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
    }
    .additional_features{
        padding:24px 0px;
        border-top:1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
        & .ant-form-item-label{
            float:left;
            width:100%;
            text-align:left;
            font-size:14px;
            color: ${themeGet('textColor.primaryDark')};
        }
        input{border: 1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
            border-radius: ${themeGet('borderRadius.small')};}
    }
    .additional_comment{
        padding:24px 0px;
        border-top:1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
        & .ant-form-item-label{
            float:left;
            width:100%;
            text-align:left;
            font-size:14px;
            color: ${themeGet('textColor.primaryDark')};
        }
    }
    .additional_pets{
        padding:24px 0px 0px 0px;
        border-top:1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
    }
    .additional_comment_afa{
        padding:0px;
        & .ant-form-item-label{
            float:left;
            width:100%;
            text-align:left;
            font-size:14px;
            color: ${themeGet('textColor.primaryDark')};
        }
    }
    .date_range_label{
        font-size:14px;
        color: ${themeGet('textColor.primaryDark')};
    }
    .reservation_datepicker{
        padding-top:4px;
        & .DateRangePicker{
            float:left;
            width:100%;
            & .DateInput{
                width:48%;
                @media only screen and (max-width: 768px) {
                    width:100%;
                    float:left;
                }
                & .DateInput_input{
                    border: 2px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
                    border-radius: ${themeGet('borderRadius.small')};
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;
                    font-variant: tabular-nums;
                    list-style: none;
                    font-feature-settings: 'tnum', "tnum";
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    min-width: 0;
                    padding: 10px 12px;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
                    line-height: 1.5715;
                    background-color: ${themeGet('backgroundColor.white', '#FFFFFF')};
                    background-image: none;
                    transition: all 0.3s;
                    
                }
            }
            
            .DateRangePickerInput_arrow{
                display:none;
            }
        }  
    }

`

export const ReservationModalSpacer = styled.div`
    width: 100%;
    border-top:1px solid ${themeGet('borderLines.inputBorder', '#CDDAE1')};
    margin: 24px 0;
`;

export const ReservationModalPaymentTotal = styled.div`
    float:left;
    width:100%;
    font-size:18px;
    color: ${themeGet('textColor.primaryDark')};
    font-weight:600;
`

export const SinglePagePricingGroupsSingleFormDateNot = styled.div`
    border-radius: 4px;
    padding: 12px 16px;
    margin-top: 24px;
    margin-bottom:20px;

    img {
        margin-right: 8px;
    }
`

export const SinglePagePricingGroupsSingleFormDateErrorNot = styled(SinglePagePricingGroupsSingleFormDateNot)`
    color: ${themeGet('status.text.error')};
    background: ${themeGet('status.background.error')};
`;

export const SinglePagePricingGroupsSingleFormDateOkNot = styled(SinglePagePricingGroupsSingleFormDateNot)`
    color: ${themeGet('status.text.success')};
    background: ${themeGet('status.background.success')};
`;

export const ReserveModalCheckboxLabelMainText = styled.span`
    color: ${themeGet('textColor.primaryDark')};
    font-weight: 400;
    font-size: 1rem
    line-height: 1.25rem;
`;

export const ReserveModalCheckboxLabelSubText = styled(ReserveModalCheckboxLabelMainText)`
    font-weight: 300;
    font-size: 0.825rem;
    padding-left: 0.4rem;
`;

export const DatePickerWrapper = styled.div`
    margin-bottom: 1rem;
    padding: 0.25rem 0.75rem 0.4rem 0.75rem;
    background-color: ${themeGet('backgroundColor.lightBlue')};

    :hover { box-shadow: ${themeGet('boxShadow.small')}; }

    .DateRangePickerInput_calendarIcon {
        background-color: ${themeGet('backgroundColor.lightBlue')};
        padding-bottom: 0.5rem; 
    }
    
    > div {
      width: calc(100% - 16px);
    }
`;
