import React from 'react';

import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import ReactGA from 'react-ga';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import 'antd/dist/antd.css';
import './App.css';
import {Toaster} from 'react-hot-toast';

import GlobalStyles from './assets/style/Global.style';
import AfaProvider from './context/AfaProvider';
import AuthProvider from './context/AuthProvider';
import FavoritesProvider from './context/FavoritesProvider';
import ProfileProvider from './context/ProfileProvider';
import RouterProvider from './context/RouterProvider';
import Routes from './router';
import theme from './themes/default.theme';
import LocationHintsProvider from './context/LocationHintsProvider';

// Google Analytics
const TRACKING_ID = process.env.REACT_APP_GA_API_KEY; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// Sentry
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <BrowserRouter>
        <AuthProvider>
          <LocationHintsProvider>
            <FavoritesProvider>
              <AfaProvider>
                <ProfileProvider>
                  <RouterProvider>
                    <Routes />
                  </RouterProvider>
                </ProfileProvider>
              </AfaProvider>
            </FavoritesProvider>
          </LocationHintsProvider>
        </AuthProvider>
      </BrowserRouter>
      <Toaster />
    </>
  </ThemeProvider>
);

export default App;
