import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

export const LangMenuMobileWrapper = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 32px !important;
  font-size: 18px;
  color: ${themeGet('textColor.secondary')} ;
  cursor: pointer;
`;

export const LangMenuMobileFlag = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
