import moment from 'moment';
import 'moment/locale/se';
import 'moment/locale/en-gb';

import useIsSwedish from '../hooks/useIsSwedish';

const setLocale = (i18n) => {
  if (useIsSwedish(i18n.language)) {
    moment.locale('sv')
  } else {
    moment.locale('en-gb');
  } 
};

const getLocale = (i18n, includeTime) => {
  setLocale(i18n);
  const localeData = moment.localeData();
  return includeTime 
    ? localeData.longDateFormat('L') + ' ' + localeData.longDateFormat('LT')
    : localeData.longDateFormat('L')
}

const format = (i18n, dateString, includeTime) => {
  const formatString = getLocale(i18n, includeTime);
  return moment(dateString).format(formatString);
};

const dateTimeHelper = {getLocale, format};

export default dateTimeHelper;
