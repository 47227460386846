import {useState} from 'react';

import {Transition} from 'react-transition-group';

import {clickTransition} from '../../../assets/style/Animations.style';

import {ButtonWrapper} from './Button.style';

export const Button = ({
  text,
  children, 
  type, 
  size,
  disabled= false,
  onClick = () => null, 
  htmlType = 'button',
  useFullWidth = false,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleOnClick = () => {
    setIsClicked(true);
    if (onClick) onClick();
  }

  return (
    <Transition in={isClicked} timeout={250} onEntered={() => setIsClicked(false)}>
      {state => (
        <ButtonWrapper
          className={`Button--${type}`}
          size={size}
          type={htmlType}
          disabled={disabled}
          style={clickTransition(state)}
          onClick={handleOnClick}
          useFullWidth={useFullWidth}
        >
          {text}{children}
        </ButtonWrapper>
      )}
    </Transition>
  )
}

export default Button;
