export const error = {
  backToHomepage: 'Back to homepage',

  error404: {
    pageNotFound: 'Page not found',
    nothingWasFoundHere: 'Nothing was found here',
    contentText: `The page you’re looking for might have been removed or temporarily unavailable. Try to use search, 
    top navigation or links from the footer.`,
  },

  error500: {
    title: 'Error occurred',
    reloadPage: 'Reload page',
    header: 'Something went wrong.',
    content: 'Try to refresh the page or feel free to contact us if the problem persists.'
  }
}
