import React from 'react';

import mailImg from '../../../../../assets/images/footer/mail.png'
import phoneImg from '../../../../../assets/images/footer/phone.png'

import {ContactWrapper, ContactIcon, ContactSection} from './style';

const FooterContact = ({phone, mail}) => {
  return (
    <ContactSection>
      <ContactWrapper onClick={() => window.open(`tel:${phone}`)}>
        <ContactIcon src={phoneImg} /> {phone}
      </ContactWrapper>
      <ContactWrapper onClick={() => window.open(`mailto:${mail}`)}>
        <ContactIcon src={mailImg} /> {mail}
      </ContactWrapper>
    </ContactSection>
  )
}

export default FooterContact;