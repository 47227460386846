import React, {useState, useEffect, useContext} from 'react';

import axios from 'axios';
import * as Sentry from '@sentry/browser';
import {useHistory} from 'react-router-dom';

import {LOGIN_PAGE} from '../settings/constant';

export const AuthContext = React.createContext();

const USER_TYPE = process.env.REACT_APP_USER_TYPE;
const AUTH_URL = process.env.REACT_APP_AUTH_API;
const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const API_URL = process.env.REACT_APP_BACKEND_API;

const URL = Object.freeze({
  API_URL,
  SIGN_UP: `${AUTH_URL}/profile/register`,
  SIGN_IN: `${AUTH_URL}/user/login`,
  REFRESH_TOKEN: `${AUTH_URL}/user/refresh-token`,
  RESET_PASSWORD: `${AUTH_URL}/user/password/reset`,
  RESET_PASSWORD_CONFIRM: `${AUTH_URL}/user/password/confirm-reset`,
  CONFIRMATION_REGISTRATION: `${AUTH_URL}/profile/register/confirm`,
  RESEND_CONFIRMATION_CODE: `${AUTH_URL}/profile/register`,
});

const formatAxiosResponse = (res, isError) => {
  const resObj = {...res, axiosResponseError: isError, errorMessage: ''};

  if (isError && resObj.response !== undefined) {
    if (resObj.response.data.message !== undefined) {
      // Error found within data
      resObj.errorMessage = resObj.response.data.message;
    } else if (resObj.response.data.error_fields !== undefined) {
      // Error in error_fields
      resObj.errorMessage = resObj.response.data.error_fields.message;
    }
  }

  return resObj;
}

const AuthProvider = (props) => {
  const localStorageExpiresName = 'auth_expires';
  const localStorageDefaultCollectionName = 'user_info';
  const localStorageNames = ['access_token', 'refresh_token', 'id_token', 'email', localStorageDefaultCollectionName];

  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);

  // User Data stores pieces of information about user session and auth details
  const UserData = {
    get: () => {
      let data = Object.fromEntries(
        localStorageNames.map(name => [name, localStorage.getItem(name)])
      );
      if (data.userInfo) {
        try {
          const userInfoEncoded = JSON.parse(data.user_info);
          data = {...data, ...userInfoEncoded};
        } catch (err) {
          data.userInfoError = err.message;
        }
      }
      return data;
    },
    set: (data) => {
      const userInfo = {};
      Object.entries(data).forEach(([entryName, entryValue]) => {
        if (localStorageNames.includes(entryName)) {
          localStorage.setItem(entryName, entryValue);
        } else {
          userInfo[entryName] = entryValue;
        }
      });
      localStorage.setItem(localStorageDefaultCollectionName, JSON.stringify(userInfo));
      localStorage.setItem(localStorageExpiresName, getExpiresTime())
    },
    erase: () => {
      localStorageNames.forEach(name => localStorage.removeItem(name));
    },
    clearExpired: () => {
      const expirationTime = localStorage.getItem(localStorageExpiresName);
      const currentTime = getExpiresTime(0);

      if (expirationTime && expirationTime < currentTime) {
        localStorageNames.forEach(name => localStorage.removeItem(name));
        localStorage.removeItem(localStorageExpiresName);
      }
    }
  }

  // APIProvider - expose universal method for making authorized request to API.
  const APIProvider = {
    API_URL,
    UserData,
    makeRequest: async (url, data, method, options) => {
      method = method || 'GET';
      url = String(url).match(/^https?.*$/) ? url : `${API_URL}${url}`;

      // Options object allows to pass additional elements into makeRequest method
      const {extraHeaders, skipAuthError} = options || {};

      const headers = {
        'Authorization': (UserData.get())?.id_token || '',
        'Content-Type': 'application/json',
        ...(extraHeaders || {}),
      };

      try {
        const response = await axios({method, url, data, headers});
        if (response.status < 300) {
          // detect missing content from response
          if (response.data?.data === null && response.data?.message === '') {
            Sentry.addBreadcrumb({category: 'API_ERROR', data: {response}});
            throw new Error('API Error - missing response error');
          }
          // detect python native error - that's return 200 status code
          if (response.data?.errorType && response.data?.stackTrace) {
            Sentry.addBreadcrumb({category: 'API_ERROR', data: {response}});
            throw new Error('API Error - python native error')
          }
          return response.data.data || response.data;
        }
      } catch (err) {
        if (IS_DEBUG) {
          console.log(`Failed to make ${method} request to ${url} with error: \n${err.message}`);
        }

        if (err.response?.status === 401 && !skipAuthError) {
          const isRefreshSuccess = await refreshToken();
          return (isRefreshSuccess)
            ? APIProvider.makeRequest(url, data, method, {skipAuthError: true})
            : history.push(LOGIN_PAGE);
        } else {
          Sentry.addBreadcrumb({category: 'API_ERROR', data: {url, method, data}});
          Sentry.captureException(err);  
        }
      }
    },
    validateAllDefined: (valuesArray, errorMessage) => {
      if (valuesArray.filter(entry => entry === undefined).length > 0) {
        throw new Error(errorMessage || 'auth.missingRequestData')
      }
    }
  }

  /**
   * Refresh API token and saves new values in localStorage
   */
  const refreshToken = async () => {
    const userData = UserData.get();
    if (!userData.refresh_token) {
      setLoggedIn(false);
      return false;
    }

    const payload = {refresh_token: userData.refresh_token, user_type: USER_TYPE}
    try {
      const response = await axios.post(URL.REFRESH_TOKEN, payload);
      const result = formatAxiosResponse(response, false);
      UserData.set(result.data);
      return true;
    }
    catch {
      return false;
    }
  }

  /**
   * Authorize user with provided credentials
   * 
   * @param {*} params - needs email, password 
   * @returns {Object} axios response object
   */
  const signIn = async (params) => {
    const payload = {...params, user_type: USER_TYPE};
    const response = await axios.post(URL.SIGN_IN, payload)
      .then(result => formatAxiosResponse(result, false))
      .catch(error => formatAxiosResponse(error, true));

    if (!response.axiosResponseError && response.data.status === 201) {
      delete response.data.status;
      UserData.set(response.data);
      setLoggedIn(true);
    }
    return response
  };

  /**
   * Create new user entity with provided credentials for future authorization
   * 
   * @param {*} params - needs email, password
   * @returns {Object} axios response object
   */
  const signUp = async (params) => {
    const payload = {...params, user_type: USER_TYPE};
    return axios.post(URL.SIGN_UP, payload)
      .then((result) => formatAxiosResponse(result, false))
      .catch((error) => formatAxiosResponse(error, true));
  };

  /**
   * Remove user authorization information from the application
   */
  const logOut = () => {
    UserData.erase();
    setLoggedIn(false);
    history.go(0);
  };

  /**
   * Confirm new registration
   *
   * @param {*} params - needs email, registrationCode
   * @returns {Object} axios response object
   */
  const confirmRegistration = async (params) => {
    const payload = {...params, user_type: USER_TYPE};
    return axios.post(URL.CONFIRMATION_REGISTRATION, payload)
      .then((result) => formatAxiosResponse(result, false))
      .catch((error) => formatAxiosResponse(error, true));
  };

  /**
   * Confirm new registration
   *
   * @param {*} params - needs email, registrationCode
   * @returns {Object} axios response object
   */
  const resendConfirmationCode = async (params) => {
    const payload = {...params, user_type: USER_TYPE, resend: 1};
    return axios.post(URL.RESEND_CONFIRMATION_CODE, payload)
      .then((result) => formatAxiosResponse(result, false))
      .catch((error) => formatAxiosResponse(error, true));
  };

  /**
   * Send a request for resetting password
   * 
   * @param {*} params - needs email
   * @returns {Object} axios response object
   */
  const requestPasswordReset = async (params) => {
    const payload = {...params, user_type: USER_TYPE};
    return axios.post(URL.RESET_PASSWORD, payload)
      .then((result) => formatAxiosResponse(result, false))
      .catch((error) => formatAxiosResponse(error, true));
  }

  /**
  * Send a request for confirming resetting password
  * 
  * @param {*} params - needs email, password, code
  * @returns {Object} axios response object
  */
  const confirmResetPassword = async (params) => {
    const payload = {...params, user_type: USER_TYPE};
    return axios.post(URL.RESET_PASSWORD_CONFIRM, payload)
      .then((result) => formatAxiosResponse(result, false))
      .catch((error) => formatAxiosResponse(error, true));
  }


  /**
   * Calculates the X hours timestamp in the future.
   * 
   * @param {Number} hours - how many hours to add
   * @returns {Number} Time in the future
   */
  const getExpiresTime = (hours = 10) => {
    let dateObj = new Date();
    dateObj.setTime(dateObj.getTime() + (hours * 3600000));
    return dateObj
  }

  /**
   * Temporary method for checking if user is logged in
   * TODO: [HPY-168] after fixing cognito refreshing it should be changed to bellow code
   * try {refreshToken();} catch (err) { UserData.erase(); }
   */
  const checkIfUserIsLoggedIn = async () => {
    const headers = {'Authorization': (UserData.get())?.id_token || ''};
    const url = `${API_URL}/profile/read`;

    try {
      await refreshToken();
      await axios({url, headers});
      setLoggedIn(true);
    } catch (err) {
      setLoggedIn(false);
    }
  };

  // eslint-disable react-hooks/exhaustive-deps
  useEffect(() => {
    checkIfUserIsLoggedIn();
  }, []);
  // eslint-enable react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        APIProvider,
        logOut,
        signIn,
        signUp,
        refreshToken,
        requestPasswordReset,
        confirmResetPassword,
        confirmRegistration,
        resendConfirmationCode,
        getUser: UserData.get,
        getLoggedIn: () => loggedIn,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export const useAPIProvider = () => {
  const {APIProvider} = useContext(AuthContext);
  return APIProvider;
}

export default AuthProvider;
