import {aboutUs} from './se/aboutUs_se';
import {afa} from './se/afa_se';
import {allSwedenCampsites} from './se/allSwedenCampsites_se';
import {auth} from './se/auth_se';
import {authMenu} from './se/authMenu_se';
import {BrowseCampsites} from './se/BrowseCampsites';
import {campsiteOffer} from './se/campsiteOffer_se';
import {campsitesInSpotlight} from './se/campsitesInSpotlight_se';
import {common} from './se/common_se';
import {contact} from './se/contact_se';
import {experiences} from './se/experiences_se';
import {faq} from './se/faq_se';
import {footer} from './se/footer_se';
import {hero} from './se/hero_se';
import {menu} from './se/menu_se';
import {error} from './se/error_se';
import {ratings} from './se/ratings_se';
import {searchForm} from './se/searchForm_se';
import {searchPlanBookDone} from './se/searchPlanBookDone_se';
import {singlePage} from './se/singlePage_se';
import {userAFA} from './se/userAFA_se';
import {userBooking} from './se/userBooking_se';
import {userFavorites} from './se/userFavorites_se';
import {userProfileAsideMenu} from './se/userProfileAsideMenu_se';
import {userSettings} from './se/userSettings_se';
import {shareButton} from './se/shareButton_se';

const se = {
  translations: {
    aboutUs,
    afa,
    allSwedenCampsites,
    auth,
    authMenu,
    BrowseCampsites,
    campsiteOffer,
    campsitesInSpotlight,
    common,
    contact,
    experiences,
    faq,
    footer,
    hero,
    menu,
    error,
    ratings,
    searchForm,
    searchPlanBookDone,
    shareButton,
    singlePage,
    userAFA,
    userBooking,
    userFavorites,
    userProfileAsideMenu,
    userSettings,
  }
}

export default se;
