import styled from 'styled-components';

import {animateImageIconOnHover} from '../../assets/style/Animations.style';

export const ShareButton = styled.img`
  ${animateImageIconOnHover}
  
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in;
  


  &:active {
    opacity: .2;
  }
`;
