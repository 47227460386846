/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react';

import ReactGA from 'react-ga';
import {useTranslation} from 'react-i18next';
import Loadable from 'react-loadable';
import {Route, Redirect, Switch} from 'react-router-dom';


import Layout from './container/Layout/Layout';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  REGISTRATION_CONFIRMATION_PAGE,
  FORGET_PASSWORD_PAGE,
  CHANGE_PASSWORD_PAGE,
  HOME_PAGE,
  BROWSE_CAMPSITES,
  SINGLE_POST_PAGE,
  USER_PROFILE_PAGE,
  CAMPSITE_OFFER_PAGE,
  PRIVACY_PAGE,
  TERMS_PAGE,
  FAQ_PAGE,
  ABOUT_US_PAGE,
  CONTACT_PAGE,
  MOBILE_PAYMENT,
} from './settings/constant';

const Loading = () => null;

export const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "SignIn" */
        './container/Auth/SignIn'
      ),
      loading: Loading,
      modules: ['SignIn'],

    }),
    exact: true,
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "SignUp" */
        './container/Auth/SignUp'
      ),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: REGISTRATION_CONFIRMATION_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "RegistrationConfirmation" */
        './container/Auth/RegistrationConfirmation'
      ),
      loading: Loading,
      modules: ['RegistrationConfirmation'],
    }),
    exact: true,
  },
  {
    path: `${REGISTRATION_CONFIRMATION_PAGE}/:initialEmailCode`,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "RegistrationConfirmation" */
        './container/Auth/RegistrationConfirmation'
      ),
      loading: Loading,
      modules: ['RegistrationConfirmation'],
    }),
    exact: true,
  },
  {
    path: CHANGE_PASSWORD_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "RegistrationConfirmation" */
        './container/Auth/PasswordResetConfirmation'
      ),
      loading: Loading,
      modules: ['PasswordResetConfirmation'],
    }),
    exact: true,
  },
  {
    path: `${CHANGE_PASSWORD_PAGE}/:initialEmailCode`,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "RegistrationConfirmation" */
        './container/Auth/PasswordResetConfirmation'
      ),
      loading: Loading,
      modules: ['PasswordResetConfirmation'],
    }),
    exact: true,
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "ForgotPassword" */
        './container/Auth/ForgotPassword'
      ),
      loading: Loading,
      modules: ['ForgotPassword'],
    })
  },
  {
    path: BROWSE_CAMPSITES,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "BrowseCampsites" */
        './container/BrowseCampsites/BrowseCampsites'
      ),
      loading: Loading,
      modules: ['BrowseCampsites'],
    })
  },
  {
    path: USER_PROFILE_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "ForgotPassword" */
        './container/UserProfile/UserProfile'
      ),
      loading: Loading,
      modules: ['UserProfile'],
    })
  },
  {
    path: CAMPSITE_OFFER_PAGE,
    component: Loadable({
      loader: () => import(
        /* webpackChunkName: "CampsiteOffer" */
        './container/CampsiteOffer/CampsiteOffer'
      ),
      loading: Loading,
      modules: ['CampsiteOffer']
    })
  },
  {
    path: `${SINGLE_POST_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */
          './container/SinglePageView/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    path: ABOUT_US_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AboutUs" */
          './container/StaticPage/AboutUs/AboutUs'
        ),
      loading: Loading,
      modules: ['AboutUs'],
    }),
  },
  {
    path: FAQ_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "FAQ" */
          './container/StaticPage/FAQ/FAQ'
        ),
      loading: Loading,
      modules: ['FAQ'],
    }),
  },
  {
    path: CONTACT_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Contact" */
          './container/StaticPage/Contact'
        ),
      loading: Loading,
      modules: ['Contact'],
    }),
  },
  {
    path: MOBILE_PAYMENT,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "MobilePaymentScreen" */
          './container/MobilePaymentScreen'
        ),
      loading: Loading,
      modules: ['MobilePaymentScreen'],
    }),
  },
];

const redirects = [
  {
    path: PRIVACY_PAGE, 
    isStaticFile: true, 
    redirect: {
      se: 'https://hpy-camper-static-files.s3.eu-west-1.amazonaws.com/Integritetspolicy.pdf', 
      en: 'https://hpy-camper-static-files.s3.eu-west-1.amazonaws.com/Privacy+Statement.pdf'
    }
  },
  {
    path: TERMS_PAGE,
    isStaticFile: true, 
    redirect: {
      se: 'https://hpy-camper-static-files.s3.eu-west-1.amazonaws.com/Villkor.pdf', 
      en: 'https://hpy-camper-static-files.s3.eu-west-1.amazonaws.com/T%26C.pdf'
    }
  }
];

/* Not Found Route Component */
const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/StaticPage/Page404/Page404'),
  loading: Loading,
  modules: ['NotFound'],
});


/* Router Component */
const Routes = () => {
  const {i18n} = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Layout>
      <Switch>
        {routes.map(({path, component, exact = false}) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        {redirects.map(({path, redirect, isStaticFile = false, exact = false}) => {
          const url = (typeof redirect === 'object') ? redirect[language] || redirect.en : redirect;
          const key = `${path}->${url}`;
          return isStaticFile
            ? (<Route key={key} path={path} exact={exact} render={() => window.location.replace(url)} />)
            : (<Redirect key={key} from={path} to={url} exact={exact} />)
        })}
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
