
import LoadingUtils from '../utils';
import SinglePagePricingGroupsSingle from '../../../../container/SinglePageView/parts/SinglePagePricingGroups/parts/SinglePagePricingGroupsSingle';

const LoadingSpotList = ({
  amount = 10,
}) => {
  const utils = LoadingUtils();

  const generateFakeSpots = (count) => Array(count)
    .fill(null)
    .map((_, i) => ({
      isLoading: true,
      id: `loading-spot-${i}-${new Date().getTime()}`,
      name:  utils.getText(10, 25),
      slug: '#', 
      single: {
        photos: {main: {original: '#'}},
        pricing_group_name: {
          se: utils.getText(20, 30),
          en: utils.getText(20, 30)
        },
        pricing_group_description: {
          se: utils.getText(20, 30),
          en: utils.getText(20, 30)
        },
        price: '',
        currency: utils.getSpanText(2, 3),
        features: [],
      }
    }));

  return (
    <utils.LoadingEffectWrapper>
      {
        generateFakeSpots(amount).map(entry => (
          <SinglePagePricingGroupsSingle {...entry} key={entry.id} />
        ))
      }
    </utils.LoadingEffectWrapper>
  )
}


export default LoadingSpotList;
