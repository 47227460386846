import React, {Fragment, useState} from 'react';

import {Layout as LayoutWrapper} from 'antd';
import {withRouter} from 'react-router-dom';
import {Fade} from 'react-reveal';

import {LoadingLayer} from '../../components/UI/LoadingComponent/LoadingComponent';
import LayoutProvider from '../../context/LayoutProvider';
import useWindowSize from '../../library/hooks/useWindowSize';
import {
  AUTH_PAGES,
  SINGLE_POST_PAGE,
} from '../../settings/constant';
import ErrorBoundaries from '../../library/helpers/withErrorBoundary';

import Footer from './Footer/Footer';
import Header from './Header/Header';

const {Content} = LayoutWrapper;

export default withRouter(function Layout({children, location}) {
  const [isLoading, setIsLoading] = useState(false);
  const [mountedLocation, setMountedLocation] = useState(null);
  const {width} = useWindowSize();
  const singlePageUrlFromConst = SINGLE_POST_PAGE.split('/');
  const singlePageUrlFormLocation = location.pathname.split('/');

  // Check if current URL is an auth related page, if so don't load header
  const isAuthPage = AUTH_PAGES
    .map(url => url.split('/')[1])  
    .includes(location.pathname.split('/')[1]);

  window.onbeforeunload = () => setIsLoading(true);

  return (
    <LayoutProvider>
      <Fragment>
        {!isAuthPage && <Header />}
        <LoadingLayer isLoading={isLoading} useScrollToTop>
          <Fade 
            spy={location}
            wait={1000} 
            onReveal={() => (mountedLocation === location || setMountedLocation(location))}
          >
            <>
              <ErrorBoundaries>
                <Content>{children}</Content>
              </ErrorBoundaries>
              {!isAuthPage && (mountedLocation === location) && <Footer />}
            </>
          </Fade>
        </LoadingLayer>
        <Fragment>
          {singlePageUrlFormLocation[1] === singlePageUrlFromConst[1] && (
            <Fragment>
              {width < 1200 && <div style={{height: '74px'}} />}
            </Fragment>
          )}
        </Fragment>
      </Fragment>
    </LayoutProvider>
  );
});
