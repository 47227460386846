export const searchPlanBookDone = {
  everyoneLikesDifferentThings: 'Alla gillar olika saker, - Vad gör er semester extra speciell? Genom att skapa er egna ',
  campingProfileHere: 'Campingprofil här på HPY Camper',
  weWillHelpYou: ' hjälper vi er att ',
  sortOutTheCampsites: 'hitta de campingar',
  thatProbably: ' som ni troligtvis uppskattar lite extra',
  search: 'Search',
  plan: 'Plan',
  book: 'Book',
  done: 'Done',
  createAnAccount: 'Skapa ditt konto',
  browseCampsites: 'Sök på campingkartan'
}