import styled from 'styled-components';


const LoadingLayerContainer = styled.div`
    opacity: ${props => props.isLoading ? '0.4' : '1'};
    transition: opacity 1s;
    pointer-events: ${props => props.isLoading ? 'none' : 'all'};
    display: ${props => props.display ? props.display : 'block'};
`;

export default LoadingLayerContainer;
