export const campsiteOffer = {
  type: {
    tent: 'Tent',
    cabin: 'Cabin',
    vehicle: 'Vehicle',
  },
  sent: 'Sent',
  pets: 'Pets',
  no: 'No',
  yes: 'Yes',
  hello: 'Hello',
  submit: 'Send offer',
  decline: 'Decline offer',
  seeDetails: 'See details',
  checkInCheckOut: 'Check in - check out',
  bookingReference: 'Booking reference: ',
  detailsIntro: 'is waiting for the booking details',
  detailsIntroName: 'John',
  spotType: 'Spot type',
  adultsAndChildren: 'Adults/Children',
  additionalComments: 'Additional comments',
  spotKindQuestion: 'What kind of spot do you have available?',
  additionalInfo: 'Additional info about the spots (optional)',
  additionalInfoPlaceholder: 'E.g. 2 bed cabin with view over the lake, close to service house',
  priceOffer: 'Your offered total price for stay (in kr)',
  totalPrice: 'Total offered price',
  missingRequestInformation: 'Please check your URL address. It seems that it misses some information.',
  warningHeading: 'After you send your offer, {{firstName}} will have 48 hours to accept or decline.',
  warningText: '' + 
`If customer accepts, they will pay the offered amount directly to HPY camper. HPY Camper
will then transfer the money to you after we deducted our commission of XX %
The booking terms is non refundable.

We will contact you as soon as the customer accepts the offer to confirm the reservation. If
the customer don't answer, we will contact you as well!
`
};
