import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

const LogoArea = styled.div`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    min-width: 150px;
  }

  img {
    width: 36px;
  }

  h1, h3 {
    color: ${themeGet('textColor.primary')};
    margin: 0 0 0 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.05em;
    ${'' /* min-width: 102px; */}
  }
`;

export default LogoArea;
