import React, {useContext} from 'react';

import {Menu} from 'antd';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {AuthContext} from '../../../context/AuthProvider';
import {
  HOME_PAGE,
  BROWSE_CAMPSITES,
  ABOUT_US_PAGE,
  FAQ_PAGE,
  CONTACT_PAGE
} from '../../../settings/constant';

import LangMenuMobile from './LangMenu/LangMenuMobile';

const MobileMenu = ({t, className}) => {
  // auth context
  const {loggedIn, logOut, getUser} = useContext(AuthContext);
  getUser();
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          {t('menu.home')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${BROWSE_CAMPSITES}`}>
          {t('menu.browseCampsites')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${ABOUT_US_PAGE}`}>
          {t('menu.aboutUs')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${FAQ_PAGE}`}>
          {t('menu.FAQ')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${CONTACT_PAGE}`}>
          {t('menu.contact')}
        </NavLink>
      </Menu.Item>
      {/*todo: commented before release*/}
      {/*{!loggedIn && (*/}
      {/*  <Menu.Item key="5">*/}
      {/*    TODO*/}
      {/*    /!* <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>Kontoinställningar</NavLink> *!/*/}
      {/*  </Menu.Item>*/}
      {/*)}*/}
      {loggedIn && (
        <Menu.Item key="6">
          <button onClick={logOut}>
            {t('menu.logOut')}
          </button>
        </Menu.Item>
      )}
      <LangMenuMobile />
    </Menu>
  );
};

export default withTranslation()(MobileMenu);
