export const userBooking = {
  'name&address': 'Name and address',
  cancelReservation: 'Cancel reservation',
  cancelSuccess: 'Reservation was cancelled successfully',
  cancelError: 'Something went wrong. Reservation could not be cancelled',
  checkIn: 'Check In',
  checkOut: 'Check Out',
  viewDetails: 'View details',
  bookAgain: 'Book again',
  makePayment: 'Make payment',
  postReview: 'Post a review',
  receipt: 'Receipt',
  new: 'New',
  paid: 'Paid',
  completed: 'Completed',
  current: 'Current',
  history: 'History',
  status: {
    'new': 'New',
    'paid': 'Paid',
    'installment': 'Installment',
    'cancelled': 'Cancelled',
    'checked_out': 'Checked Out',
    'checked_in': 'Checked In',
    'processingPayment': 'Processing payment',
  },
  noDataInformation: {
    header: 'You don\'t have any bookings yet',
    text: 'Browse campsites to find your next place to stay at!',
    action: 'Browse campsites',
  },
  details: {
    visitors: 'Visitors',
    adults: 'Adults',
    children: 'Children',
    price: 'Price',
    created: 'Creation Date',
    details: 'Additional Information',
    reservationNumber: 'Reservation Number',
  },
  paymentInFuture : 'The payments will be available soon and the information will be sent to the e-mail address you used for the registration process.'
}
