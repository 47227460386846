import React from 'react';

import PropTypes from 'prop-types';

import NavbarWrapper, {
  LogoArea,
  MenuArea,
  LangWrapper,
  AuthWrapper,
  MenuWrapper,
} from './Navbar.style';

const Navbar = ({
  className,
  logo,
  navMenu,
  langMenu,
  authMenu,
  isLogin,
  headerType
}) => {

  const addAllClasses = ['navbar'];
  
  if (className) {
    addAllClasses.push(className);
  }
  
  if (headerType) {
    addAllClasses.push(`is_${headerType}`);
  }

  return (
    <NavbarWrapper className={addAllClasses.join(' ')}>
      {navMenu && (
        <MenuArea>
          <LogoArea>
            {logo}
          </LogoArea>
        
          <MenuWrapper className="main_menu">{navMenu}</MenuWrapper>
          <AuthWrapper isLogin={isLogin} className="auth_menu">{authMenu}</AuthWrapper>

          <LangWrapper classMane="lang_menu">{langMenu}</LangWrapper>
        </MenuArea>
      )}
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  navMenu: PropTypes.element,
  avatar: PropTypes.element,
  authMenu: PropTypes.element,
  langMenu: PropTypes.element,
  isLogin: PropTypes.bool,
  headerType: PropTypes.oneOf(['transparent', 'default']),
};

export default Navbar;
