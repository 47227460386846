import styled from 'styled-components';

import {animateImageIconOnHover} from '../../../../assets/style/Animations.style';

export const LangMenuFlag = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
  
  ${animateImageIconOnHover}
  transition: all 0.25s ease-in;
`;
