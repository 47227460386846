import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

export const FooterSocialWrapper = styled.div`
    margin-top: 24px;
    display: none;
    float: right;
    
    @media only screen and (min-width: ${themeGet('breakpoint.medium')}) {
        display: block;
    }
`

export const FooterSocialSingleIcon = styled.img`
    :hover {
        ${'' /* Filter bellow transform white image into orange image (primary color from our theme) */}
        filter: brightness(0) saturate(100%) invert(79%) sepia(48%) brightness(102%) contrast(109%) saturate(7023%) hue-rotate(359deg) 
    }
`;

export const FooterSocialSingleWrapper = styled.div`
    float: left;
    margin-right: 24px;
    
    &:last-child {
        margin-right: 0;
    }
`
