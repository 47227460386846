import SearchPlanBookDone from '../SearchPlanBookDone/SearchPlanBookDone';

import {StaticPageBody, StaticPageHeader, StaticPageHeaderOverlay, StaticPageTitle, StaticPageContent} from './StaticPage.style';

const StaticPage = ({title, children}) => {
  return (
    <>
      <StaticPageHeader>
        <StaticPageHeaderOverlay />
        <StaticPageTitle>
          { title }
        </StaticPageTitle>
      </StaticPageHeader>
      <StaticPageBody>
        <StaticPageContent>
          {children}
        </StaticPageContent>
      </StaticPageBody>
      <SearchPlanBookDone />
    </>
  )
}

export default StaticPage;