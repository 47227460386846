import {useState, useEffect} from 'react';

import AnchorIcon from '../../../assets/images/icons/anchor.svg';
import GolfIcon from '../../../assets/images/icons/golf.svg';

const CampsiteTypeIcon = ({campsiteType}) => {
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    if (campsiteType === 'Guest Harbour') setIcon(AnchorIcon);
    else if (campsiteType === 'Golf Club') setIcon(GolfIcon);
    else setIcon(null);
  }, [campsiteType])

  return (
    <>{icon && <img src={icon} alt={campsiteType} />}</>
  )
};

export default CampsiteTypeIcon;
