import React from 'react';

import {Menu} from 'antd';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {LOGIN_PAGE, REGISTRATION_PAGE} from '../../../../settings/constant';

import AuthMenuUserDropdown from './AuthMenuUserDropdown/AuthMenuUserDropdown';
  
const AuthMenu = ({ 
  className, 
  isLogin,
  t
}) => {
  return (
    <Menu className={className}>
      {!isLogin ? (
        <>
          <Menu.Item key="0">
            <NavLink to={LOGIN_PAGE}>
              {t('authMenu.login')}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="1">
            <NavLink to={REGISTRATION_PAGE}>
              {t('authMenu.register')}
            </NavLink>
          </Menu.Item>
        </>
      ) : (
        <AuthMenuUserDropdown />
      )}
    </Menu>
  );
};

AuthMenu.propTypes = {
  className: PropTypes.string,
  isLogin: PropTypes.bool,
};

export default withTranslation()(AuthMenu);
