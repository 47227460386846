import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

import * as Animations from '../../../assets/style/Animations.style';

const CommonWrapper =  styled.div`
    overflow: hidden;
    border-radius: 0.25rem;
    border-width: ${props => props.hideBorder ? '0px' : (props.isHighlighted ? '2px' : '1px')};
    border-color: ${props => props.isHighlighted ? themeGet('backgroundColor.primary') : themeGet('borderLines.inputBorder')};
    border-style: solid;
    display: flex;
    flex-wrap: wrap;
    background-color: ${themeGet('backgroundColor.white')};
    
    &:hover {
        box-shadow: ${themeGet('boxShadow.small')};
    }
`;

export const DWrapper = styled(CommonWrapper)`
    margin-bottom: ${props => props.isHighlighted ? '4rem' : '2.5rem'};
    flex-direction: row;
    display: none;
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) { display: flex; }
`;

export const DImage = styled.div`
    align-self: stretch;
    width: 18.5rem;
    max-width: 25vw;
`;

export const DRow = styled.div`
    display: flex;
    margin-bottom: 1.5rem;
`;

export const DRowClear = styled(DRow)`
    margin: 0;
    justify-content: space-between;
`;

export const DCol = styled.div``;

export const DContent = styled(DCol)`
    width: 100%;
    padding: 1rem 1.5rem;
    width: calc(100% - min(18.5rem, 25vw));
`;

export const MWrapper = styled(CommonWrapper)`
    margin-bottom: 1.25rem;
    flex-direction: column;
    display: flex;
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) { display: none; }
`;

export const MImage = styled(DImage)`
    width: 100%;
    max-width: 100%;
    height: min(25vh, 10rem)
`;

export const MRow = styled(DRow)`
    margin-bottom: 0.75rem;
`;

export const MRowClear = styled(DRowClear)``;

export const MCol = styled.div``;

export const MContent = styled(DContent)`
    width: 100%;
`;

export const Meta = styled(DCol)`
    width: 100%;

    a { color: inherit; text-decoration: inherit; }
`;

Meta.Location = styled.div`
    color: ${themeGet('textColor.secondary')};
    font-weight: 400;
    font-size: 0.8125rem;
    margin-bottom: 0.25rem;
    display: flex;

    img { margin-right: 0.25rem; }
`;

Meta.Name = styled.div`
    color: ${themeGet('textColor.primaryDark')};
    font-size: 1rem;
    font-weight: 600;
    width: 100%;

    ${() => Animations.animateLinkOnHover}
`;

export const Price = styled(DCol)`
    text-align: right;
    width: min-content;
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
`;

Price.Title = styled.div`
    color: ${themeGet('textColor.secondary')};
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 600;  
`;

Price.Amount = styled.div`
    color: ${themeGet('textColor.primary')};
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
    white-space: nowrap;
`;

Price.SubText = styled.div`
    color: ${themeGet('textColor.secondary')};
    font-weight: 500;
    font-size: 0.75rem; 
    line-height: 0.75rem;
`;

export const PropertyType = styled.div`
    margin-right: 0.75rem;
    width: 2.5rem;
`;
