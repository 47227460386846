export const experiences = {
  experiences: 'Populärt just nu',
  connectionToNature: 'Naturnära',
  connectionToNature_desc: 'Trivs du bäst med vandrarkängorna på fötterna, eller vill du bara bo naturnära med din hund? Dessa campingar erbjuder det bästa av sveriges natur!',
  closeToSea: 'Havsnära',
  closeToSea_desc: 'Havet skänker lugn åt själen, eller svalka åt kroppen. Här hittar du campingar nära havet!',
  golfTime: 'Golfnära',
  golfTime_desc: 'Vill du aldrig ha långt till en driving range eller 18-hålsbana? -Ta då en extra titt på dessa campingar!',
  enjoyWithTheFamily: 'För hela familjen',
  enjoyWithTheFamily_desc: 'Glada barn är glada föräldrar! -Vissa campingar passar extra bra till barnfamiljer. Mycket att göra och upptäcka för alla åldrar!'
}