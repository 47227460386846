import React from 'react';

import i18next from 'i18next';
import {withTranslation} from 'react-i18next';

import flagEnglish from '../../../../../assets/images/flags/english.svg';
import flagSwedish from '../../../../../assets/images/flags/swedish.svg';
import useIsSwedish from '../../../../../library/hooks/useIsSwedish';

import * as Style from './style';

const LangMenuMobile = ({i18n}) => {
  const isSwedish = useIsSwedish(i18n.language);

  const handleClick = (lang) => {
    i18next.changeLanguage(lang);
  }

  return (
    <>
      { isSwedish &&
        <Style.LangMenuMobileWrapper key="lang-menu-mobile-en" onClick={() => handleClick('en')}>
          <Style.LangMenuMobileFlag src={flagEnglish} alt="english-flag" />
          <span>Switch to English</span>
        </Style.LangMenuMobileWrapper>
      }
      { !isSwedish &&
        <Style.LangMenuMobileWrapper key="lang-menu-mobile-se" onClick={() => handleClick('se')}>
          <Style.LangMenuMobileFlag src={flagSwedish} alt="swedish-flag" />
          <span>Byt till svenska</span>
        </Style.LangMenuMobileWrapper>
      }
    </>
  )
}

export default withTranslation()(LangMenuMobile)