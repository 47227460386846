import PropTypes from 'prop-types';

import CampsiteCard from './index';


const CampsiteCardList = ({
  campsites,
  hideBorder = false,
  useOpenInNewTab = false,
}) => {
  const getCampsiteCardList = () => {
    const highlightedCampsite = campsites.filter(c => c.isHighlighted)[0];
    const filteredCampsites = highlightedCampsite
      ? [highlightedCampsite, ...campsites.filter(c => c.slug !== highlightedCampsite.slug)]
      : campsites;
    return filteredCampsites.map(
      (campsite, index) => {
        const key = `${index}-${campsite.id}`;
        return (
          <div key={key}>
            <CampsiteCard
              key={`${key}--card`}
              hideBorder={hideBorder}
              name={campsite.name}
              amenities={campsite.amenities}
              city={campsite.city}
              address={campsite.address}
              types={campsite.counts}
              price={campsite.price_from?.price}
              currency={campsite.price_from?.currency}
              rating={campsite.rating}
              campsiteImage={campsite.gallery?.main?.thumbnail}
              campsiteType={campsite.campsite_type}
              campsiteSlug={campsite.slug}
              isHighlighted={campsite.isHighlighted}
              useOpenInNewTab={useOpenInNewTab}
            />
          </div>
        );
      }
    );
  }

  return (
    <div>
      {campsites && campsites.length > 0 && getCampsiteCardList()}
    </div>
  )
}

CampsiteCardList.proTypes = {
  campsites: PropTypes.arrayOf(PropTypes.object),
};

export default CampsiteCardList;
