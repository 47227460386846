import React from 'react';

import {withTranslation} from 'react-i18next';

import logoFile from '../../../assets/images/logo.svg';
import Logo from '../../../components/UI/Logo/Logo';

import {FooterSection, FooterWrapper, FooterColumn, FooterDescription, FooterBottom, FooterCopyrights} from './Footer.style';
import FooterContact from './parts/FooterContact';
import FooterMenu from './parts/FooterMenu';
import FooterSocial from './parts/FooterSocial';


const Footer = ({t}) => {

  const year = new Date().getFullYear();

  return (
    <FooterSection>
      <FooterWrapper>
        <FooterColumn>
          <Logo
            withLink
            linkTo="/"
            src={logoFile}
            title={'HPY Camper'}
          />
          <FooterDescription>{t('footer.description')}</FooterDescription>
          <FooterContact phone={t('footer.phone')} mail="info@hpycamper.se" />
        </FooterColumn>
        <FooterColumn>
          <FooterMenu title={t('footer.usefulLinks')} />
        </FooterColumn>
        <FooterColumn>
          <FooterMenu title={t('footer.forTheCampsiteOwner')} isForCampsiteOwner />
        </FooterColumn>
      </FooterWrapper>
      <FooterBottom>
        <FooterWrapper>
          <FooterColumn>
            <FooterCopyrights>© {year} {t('footer.copyrights')}</FooterCopyrights>
          </FooterColumn>
          <FooterColumn>
            <FooterSocial />
          </FooterColumn>
        </FooterWrapper>
      </FooterBottom>
    </FooterSection>
  );
};

export default withTranslation()(Footer);
