import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

import {animateButtonOnHover} from '../../../assets/style/Animations.style';

export const ButtonWrapper = styled.button`
  ${animateButtonOnHover}
  
  border: none;
  outline: none;
  color: ${themeGet('textColor.white')};
  background: ${themeGet('backgroundColor.primary')};
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  margin: 8px 0;
  width: ${props => props.size ? 'auto' : '100%'};
  padding: ${props => {
    if (props.size === 'small') return '12px 20px';
    return '12px 20px';
  }};
  
  &.Button--secondary {
    background: ${themeGet('backgroundColor.white')};
    color: ${themeGet('textColor.primaryDark')};
  }
  
  &.Button--tertiary {
    background: rgba(0,0,0,.05);
    color: ${themeGet('textColor.primaryDark')};
  }
  
  &[disabled] {
    cursor: not-allowed;
    background: ${themeGet('backgroundColor.secondary')};
  }
`;
