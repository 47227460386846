export const BrowseCampsites = {
  SearchCampsitesFilters: {
    // rating labels
    'Awesome': 'Enastående!',
    'Very good': 'Mycket bra',
    'Good': 'Bra ',
    'Average': 'Helt ok',
    // property types labels
    'type.vehicle': 'Husbil/Husvagn',
    'type.tent': 'Tält',
    'type.cabin': 'Stugor',
    // campsite types labels
    'Guest Harbour': 'Gästhamn',
    'Golf Club': 'Golfklubb',
    'Campsite': 'Camping',
    // filter group labels
    priceTitle: 'Pris per natt',
    campsiteType: 'Campingtyp',
    propertyType: 'Boendetyp',
    rating: 'Omdöme',
    amenities: 'Bekvämligheter',
    amenity: 'Bekvämligheter',
    // connected group labels
    connected: 'Bokningsbar direkt',
    onlyConnected: 'Bara bokningsbara',
    // search results
    showing: 'Visar',
    campsites: 'campingar',
    resultsTitle: 'Filtrera sökresultat',
  },
  mobileMenu: {
    filter: 'Filter',
    sort: 'Sortera',
    list: 'Lista',
    map: 'Karta',
  },
  desktopMenu: {
    switchToList: 'Byt till Lista',
    switchToMap: 'Växla till Karta',
  },
  common: {
    from: 'från',
    night: 'natt'
  },
  noDataInformation: {
    header: 'Tyvärr hittade vi ingen matchande camping',
    text: 'Försök igen med en lite bredare sökning',
  },
}
