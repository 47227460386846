import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import LogoIcon from '../../../../assets/images/logo.svg';

const LoadingWrapperContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: ${props => props.height || '100%'};
    min-width: ${props => props.width || 'inherit'};
    display: flex;
    align-items: center;
    justify-content: center;    
`;

const LoadingWrapperContent = styled.div`
    animation: colorAnimation linear 5s infinite;
    color: ${themeGet('backgroundColor.primary')};
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;

    img {
        animation: fadeImgAnimation linear 3s infinite;
        height: 3rem;
        width: 3rem !important;
    }

    span {
        animation: fadeTextAnimation linear 3s infinite;
    }

    @keyframes fadeImgAnimation {
        0% { opacity:0.3; }
        50% { opacity:1; }
        100% { opacity:0.3; }
    }

    @keyframes fadeTextAnimation {
        0% { opacity:0.6; }
        50% { opacity:1; }
        100% { opacity:0.6; }
    }

    @keyframes colorAnimation {
        0% {filter: grayscale(0); }
        50% {filter: grayscale(0.75); }
        100% {filter: grayscale(0); }
    }
`;

const Wrapper = ({height, width}) => {
  const {t} = useTranslation();

  return (
    <LoadingWrapperContainer height={height} width={width}>
      <LoadingWrapperContent>
        <img src={LogoIcon} alt="Loading" />
        <span>{t('menu.loading')}</span>
      </LoadingWrapperContent>
    </LoadingWrapperContainer>
  );
}

export default Wrapper;
