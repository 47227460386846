import '@adyen/adyen-web/dist/adyen.css';

import {useEffect, useState} from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import {withTranslation} from 'react-i18next';

import {useAPIProvider} from '../../../context/AuthProvider';
import useIsSwedish from '../../../library/hooks/useIsSwedish';
import {PAYMENT_SESSION_ID_KEY} from '../../../settings/configuration';
import {LoadingWrapper} from '../LoadingComponent/LoadingComponent';

const PaymentBox = ({
  i18n,
  reservationSub,
  session,
  onPaymentCompleted = () => null,
  onError = () => null,
}) => {
  const adyenEnvironment = process.env.REACT_APP_ADYEN_ENVIRONMENT;
  const adyenClientKey = process.env.REACT_APP_ADYEN_CLIENT_KEY;

  const APIProvider = useAPIProvider();

  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentFinished, setIsPaymentFinished] = useState(false);

  const getSession = async () => {
    const payload = {
      reservation_sub: reservationSub,
      return_url: window.location.href,
      shopper_locale: useIsSwedish(i18n.language) ? 'sv_SE' : 'en_US',
    };
    const response = await APIProvider.makeRequest('/payment/create-session', payload, 'POST');
    localStorage.setItem(PAYMENT_SESSION_ID_KEY, response.id);
    return response;
  }

  const initCheckout = async () => {
    if (!reservationSub && !session) return;

    const adyenSession = session || await getSession();
    const checkout = await AdyenCheckout({
      clientKey: adyenClientKey,
      environment: adyenEnvironment,
      session: adyenSession,
      onPaymentCompleted: (result, component) => {
        setIsPaymentFinished(true);
        onPaymentCompleted(result, component);
      },
      onError: (error, component) => onError(error, component),
    });
    setIsLoading(false);
    checkout.create('dropin').mount('#dropin-container')
  }

  useEffect(() => initCheckout(), [reservationSub, session]);

  return (
    <>
      {!isPaymentFinished &&
        <>
          <LoadingWrapper isLoading={isLoading} height="10rem" />
          <div id="dropin-container"></div>
        </>
      }
    </>
  )
}

export default withTranslation()(PaymentBox);
