import React from 'react';

import {withTranslation} from 'react-i18next';

import {
  SinglePageRatingsCommentRate,
  SinglePageSidebarRatingsWrapperRate,
  SinglePageSidebarRatingsWrapperTitle,
  SinglePageSidebarRatingsWrapper,
  CampsiteRatingWrapper
} from './style';

const MIN_VALUE_FOR_LABEL = 5.1;

const RATINGS_VALUES = [
  {from: 9, text: 'ratings.exceptional'},
  {from: 8, text: 'ratings.superb'},
  {from: 7, text: 'ratings.veryGood'},
  {from: MIN_VALUE_FOR_LABEL, text: 'ratings.good'},
];

export const RATINGS = {
  values: RATINGS_VALUES,
  getRatingCountFromRatingObject: (ratingObj) => ratingObj?.new?.rating_count,
  getRatingValueFromRatingObject: (ratingObj) => ratingObj?.new?.rating_avg,
  getRatingLabel: (ratingValue) => RATINGS_VALUES
    .sort((a, b) => (a.from > b.from))
    .reduce((text, entry) => text || (ratingValue >= entry.from ? entry.text : ''), ''),
};

const shouldLabelBeDisplayed = (ratingValue) => {
  return ratingValue >= MIN_VALUE_FOR_LABEL;
}

const CampsiteRating = ({t, rating}) => {
  const ratingValue =  RATINGS.getRatingValueFromRatingObject(rating);
  const ratingLabel = RATINGS.getRatingLabel(ratingValue);
  const ratingCount = RATINGS.getRatingCountFromRatingObject(rating);

  return (
    <CampsiteRatingWrapper>
      <SinglePageRatingsCommentRate inactive={!rating || !ratingCount}>
        { ratingValue || 0 }
      </SinglePageRatingsCommentRate>
      <SinglePageSidebarRatingsWrapper>
        { (!rating || !ratingCount) &&
          <SinglePageSidebarRatingsWrapperRate>
            No ratings yet
          </SinglePageSidebarRatingsWrapperRate>
        }
        { rating && ratingCount > 0 &&
          <>
            { shouldLabelBeDisplayed(ratingValue) &&
              <SinglePageSidebarRatingsWrapperTitle>
                {t(ratingLabel) || '\u2800'}
              </SinglePageSidebarRatingsWrapperTitle>
            }
            <SinglePageSidebarRatingsWrapperRate>
              {ratingCount} {ratingCount === 1 ? t('ratings.rating') : t('ratings.ratings')}
            </SinglePageSidebarRatingsWrapperRate>
          </>
        }
      </SinglePageSidebarRatingsWrapper>
    </CampsiteRatingWrapper>
  )
}

export default withTranslation()(CampsiteRating);
