import {themeGet} from '@styled-system/theme-get';
import {createGlobalStyle} from 'styled-components';

import * as Animations from '../../assets/style/Animations.style';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet('textColor.primary')};
    color: ${themeGet('textColor.white')};
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #root {
    min-height: 100vh;
    background-color: ${themeGet('backgroundColor.white')};

    main.ant-layout-content {
      flex: 1 0 auto;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: fixed;
      z-index: 9;
      left: 0;
      top: auto;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet('textColor.info', '#777777')};
        justify-content: flex-start;
        strong {
          color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
          width: 30%;
        }
      }
    }
  }

  .image-gallery {
    height: 360px !important;
    overflow: hidden !important;
    
    img {
      height: 100% !important;
    }
  }
  
  .image-gallery-content {
    height: 360px !important;
  }
  
  .image-gallery-slide-wrapper {
    height: 360px !important;
    display: flex;
    align-items: center;
  }
  
  .image-gallery-icon {
    color: ${themeGet('textColor.white')} !important;
  }
  
  .image-gallery-svg {
    width: 30px !important;
    height: 60px !important;
  }
  
  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('textColor.primary', '#000000')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('textColor.primary', '#000000')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('textColor.primary', '#000000')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet('backgroundColor.dropdownHover', '#F7F7F7')};
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 3px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet('backgroundColor.white')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 50px;
        .ant-modal-content{
          box-shadow: none;
          padding: 0 30px;
          background: transparent;
          @media only screen and (max-width: 480px) {
            padding: 0 20px;
          }
          .ant-modal-body {
            max-width: 1170px;
            padding: 0;
            margin: 0 auto; 
            @media only screen and (max-width: 1440px) {
              position: relative;
            }
          }
        }
        .image_gallery_close {
          background: transparent;
          border: 0;
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          right: 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media only screen and (max-width: 1440px) {
            top: 0;
            right: 0;
            svg {
              path {
                opacity: 0.8;
                fill: ${themeGet('textColor.white', '#ffffff')};
                transition: all 0.3s ease;
              }
            }
            &:hover {
              svg {
                path {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .quantity {
    button.btn {
      border: 1px solid ${themeGet('textColor.primary', '#008489')};
      svg {
        fill: ${themeGet('textColor.primary', '#008489')};
      }
      &:hover {
        background-color: ${themeGet('textColor.primary', '#008489')};
        svg {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
    }
  }

  .modal {
    .modal-dialog {
      .modal-content {
        padding: 0 !important;

        .modal-header {
          box-shadow: inset 0px -1px 0px ${themeGet('borderLines.wrapper')};
          border: none;
          padding: 20px 24px;

          .modal-title {
            font-size: 16px;
            font-weight: 600;
            color: ${themeGet('textColor.primaryDark')};
          }
        }

        .modal-body {
          padding: 24px;
        }

        .modal-footer {
          padding: 16px 24px;
          border-top: none;
        }
      }
    }

    .ant-form {
      .ant-form-item-label label {
        font-weight: 400;
        font-size: 0.875rem;
        height: 20px;
        color: ${themeGet('textColor.primaryDark')};
        display: block;
        width: 100%;
      }

      .ant-form-item-control input.ant-input {
        background: ${themeGet('textColor.white')};
        box-sizing: border-box;
        border: 1px solid ${themeGet('borderLines.inputBorder')};
        border-radius: 0.25rem;
        padding: 4px 11px;
      }

      .ant-form-item textarea {
        width: 100%;
        border: 1px solid ${themeGet('borderLines.inputBorder')};
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 0.825rem;
        padding: 0.75rem 1rem;
      }
    }
  }
  .mobile-header {
    &.ant-drawer {
      z-index: 10000;
    }
    .ant-drawer-body {
      padding: 0;
      .auth-menu {
        border: 0;
        display: flex;
        padding: 12px 24px;
        margin-top: 24px;
        align-items: center;
        background-color: ${themeGet('backgroundColor.dropdownHover', '#F7F7F7')};
        li {
          height: auto;
          line-height: 1;
          padding: 0;
          margin: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 700;
            color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
          }
          &:last-child {
            a {
              color: ${themeGet('textColor.white')};
              background-color: ${themeGet('textColor.primary', '#008489')};
              transition: opacity 0.2s ease;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .main-menu {
        border: 0;
        li {
          padding: 0;
          margin: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            border-left: 4px solid transparent;
            font-size: 18px;
            padding: 12px 32px;
            color: ${themeGet('textColor.secondary')};

            &.active {
              border-color: ${themeGet('textColor.primary')};
              font-weight: 500;
              color: ${themeGet('textColor.primaryDark')} !important;
            }
          }
          button {
            display: block;
            text-align: left;
            width: 100%;
            border: 0;
            padding: 0 32px;
            cursor: pointer;
            background-color: transparent;
            color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &:hover {
              color: ${themeGet('textColor.primary', '#008489')};
            }
            &focus {
              outline: 0;
            }
          }
          &:hover {
            a {
              color: ${themeGet('textColor.primary', '#008489')};
            }
          }
        }
      }
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet('textColor.inactive', '#909090')};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 15px;
        font-weight: 600;
        color: ${themeGet('textColor.primaryDark', '#2C2C2C')};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet('textColor.black', '#000000')};
        }
      } 
      &:hover {
        background-color: ${themeGet('backgroundColor.dropdownHover', '#F7F7F7')};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            padding-top: 50px;
            padding-bottom: 20px;
            border-color: ${themeGet('borderLines.light', '#EBEBEB')};
            @media only screen and (max-width: 375px) {
              padding-top: 30px;
            }
          }
          .DateInput__small {
            width: 112px;
          }
          input,
          .DateRangePickerInput {
            padding: 0 9px;
          }
          footer {
            border-color: ${themeGet('borderLines.light', '#EBEBEB')};
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet('textColor.inactive', '#909090')};
          transition: all 0.3s ease;
          @media only screen and (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .reservation_sidebar {
    padding: 0 !important;
    background-color: ${themeGet('textColor.white', '#ffffff')};
    header {
      padding-bottom: 27px;
      margin-bottom: 29px;
      padding: 25px 30px 26px 30px;
      border-bottom: 1px solid ${themeGet('borderLines.light', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
        &:hover {
          color: #31b8bd;
        }
      }
    }

    p {
      color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
      font-size: 15px;
      font-weight: 400;
      a {
        color: ${themeGet('textColor.primary', '#008489')};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          color: #008489d1;
          text-decoration: underline;
        }
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet('borderLines.light', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .grid_column .placeholder {
    max-width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: 3px;
    border-color: ${themeGet('borderLines.pricing', '#E6E6E6')};
  }

  .ant-input-search-icon::before {
    border-color: ${themeGet('borderLines.pricing', '#E6E6E6')};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
  .ant-checkbox:hover .ant-checkbox-inner, 
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: ${themeGet('textColor.primary', '#008489')} !important;
  }

  .ant-checkbox-group-item {
    color: ${themeGet('textColor.primaryDark')};
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    display: flex;
    
    .ant-checkbox {
      input {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        margin-right: 10px;
      }
      
      .ant-checkbox-checked {
        input {
          background-color: ${themeGet('backgroundColor.primary')} !important;
        }
      } 
    }
  }

  .modal-backdrop{
    z-index:10002 !important;
  }
  .modal{
    z-index:10003 !important;
    .modal-dialog{
      max-width:45%;
      z-index:10002;
      .modal-content{
        padding:25px;
        .image-gallery-thumbnail{
          max-height:50px ;
          overflow:hidden;
          img{
            vertical-align: top;
          }
        }
      }
    }
  }

  
  .ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  padding: 8px 0;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  box-sizing: border-box;
  
  @media only screen and (min-width: ${themeGet('breakpoint.medium')}) {
    padding: 0 6px;
  }
}
.ant-carousel .slick-slide:nth-child(4n) {
  padding-right: 0;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: white;
  font-size: 0;
  line-height: 0;
  background: black;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: -24px;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    bottom: -36px;
  }
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  display: block;
  padding: 0;
  color: transparent;
  font-size: 0;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  background: ${themeGet('backgroundColor.secondary')};
  width: 100%;
  height: 6px;
  border-radius: 4px;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: ${themeGet('backgroundColor.primary')};
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
.campsite-gallery-modal, .modal-reserve, .modal-afa{
  @media only screen and (max-width: 768px) {
    max-width:100% !important;
  }
}

.campsite-gallery-modal {
  min-width: 80% !important;

  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    min-width: 60% !important;
  }
  
  @media only screen and (min-width: ${themeGet('breakpoint.x-large')}) {
    min-width: auto !important;
  }
}

.ant-form-item-label {
  margin-bottom: 6px !important;
}

.ant-form-item-label label {
  font-weight: 500 !important;
  color: ${themeGet('textColor.primaryDark')} !important;
  font-size: 14px;
}

.ant-form-item-label label:after {
  display: none !important;
}

.ant-form-item-control input.ant-input {
  padding: 10px 12px !important;
}

.sticky-inner-wrapper {
  z-index: 999 !important;
}

.ant-menu-item {
  a {
    ${() => Animations.animateLinkOnHover}
  }
}
`;

export default GlobalStyle;

