import toast from 'react-hot-toast';

import defaultTheme from '../../themes/default.theme';

const useNotification = () => {
  const options = {
    duration: 3000,
    position: 'top-center'
  }

  const commonStyles = {
    fontSize: '14px',
    padding: '8px 16px',
    borderRadius: '4px',
  }

  const show = (text, type) => {
    if (type === 'success') {
      const style = {
        ...commonStyles,
        color: defaultTheme.status.text.success,
        borderBottom: `3px solid ${defaultTheme.status.text.success}`
      }
      const iconTheme = {primary: defaultTheme.status.text.success}
      return toast.success(text, {...options, style, iconTheme});
    } else if (type === 'error') {
      const style = {
        ...commonStyles,
        color: defaultTheme.status.text.error,
        borderBottom: `3px solid ${defaultTheme.status.text.error}`
      }
      const iconTheme = {primary: defaultTheme.status.text.error}
      return toast.error(text, {...options, style, iconTheme});
    } else {
      const style = {
        ...commonStyles,
        color: defaultTheme.status.text.info,
        borderBottom: `3px solid ${defaultTheme.status.text.info}`
      }
      const iconTheme = {primary: defaultTheme.status.text.info}
      return toast(text, {...options, style, iconTheme})
    }
  }
  return {show};
}

export default useNotification;
