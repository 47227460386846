import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

import * as Animations from '../../../assets/style/Animations.style';

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: table;
    overflow: hidden;
`;


const ImageContent = styled.div`
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    background-image: url("${props => props.src}");
    background-color: ${themeGet('backgroundColor.lightBlueBox')};
    background-position: center;
    background-size: cover;
    
    ${props => props.useAnimations && Animations.animateImageBackgroundOnHover}
`;

const ImageComponent = ({src, children, useAnimations}) => {
  return (
    <ImageWrapper>
      <ImageContent src={src} useAnimations={useAnimations}>{children}</ImageContent>
    </ImageWrapper>
  );
};

export default ImageComponent;
