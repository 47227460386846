import styled from 'styled-components';

export const RateShareFavoriteBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RateShareFavoriteBoxButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
  align-items: center;
  gap: 16px;
`;