export const BrowseCampsites = {
  SearchCampsitesFilters: {
    // rating labels
    'Awesome': 'Awesome',
    'Very good': 'Very good',
    'Good': 'Good',
    'Average': 'Average',
    // property types labels
    'type.vehicle': 'Vehicle',
    'type.tent': 'Tent',
    'type.cabin': 'Cabin',
    // campsite types labels
    'Guest Harbour': 'Guest Harbour',
    'Golf Club': 'Golf Club',
    'Campsite': 'Campsite',
    // filter group labels
    priceTitle: 'Price per night',
    campsiteType: 'Campsite Type',
    propertyType: 'Property Type',
    rating: 'Rating',
    amenities: 'Amenities',
    amenity: 'Amenity',
    // connected group labels
    connected: 'Connected to HPY',
    onlyConnected: 'Only connected campsites',
    // search results
    showing: 'Showing',
    campsites: 'campsites',
    resultsTitle: 'Filter search results',
  },
  mobileMenu: {
    filter: 'Filter',
    sort: 'Sort',
    list: 'List',
    map: 'Map',
  },
  desktopMenu: {
    switchToList: 'Switch to List',
    switchToMap: 'Switch to Map',
  },
  common: {
    from: 'from',
    night: 'night',
  },
  noDataInformation: {
    header: 'Sorry, we couldn\'t find any campsites',
    text: 'No campsites matched your search terms. Please try a different search',
  },
}
