export const authMenu = {
  login: 'Logga in',
  register: 'Registrera',
  userSettings: 'Inställningar',
  signOut: 'Logga ut',
  myAccount: 'Mitt konto',
  bookings: 'Bokningar',
  afa: 'Bokningsförfrågningar',
  favorites: 'Favoriter',
  loggedOut: 'Utloggad'
}
