export const auth = {
  welcomeTraveler: 'Welcome traveler!',
  welcomeBack: 'Welcome back!',
  logIntoAccount: 'Please log into your account',
  dontHaveAccount: 'Don\'t have an account yet?',
  registerForYourAccount: 'Please register for your account',
  haveAccountAlready: 'Have an account already?',
  enterYourEmail: 'Please enter your email bellow and we\'ll \nsend you a link to reset your password.',
  forgotPassword: 'Forgot password?',
  forgotYourPassword: 'Forgot your password?',
  emailIsRequired: 'Email address is required',
  rememberMe: 'Remember me',
  emailAddress: 'Email address',
  enterValidEmail: 'Please enter a valid email address',
  password: 'Password',
  provideValidPassword: 'Please provide a valid password. The password must contain at least one lowercase letter, one uppercase letter, and one number. Must be at least 8 characters long.',
  confirmPassword: 'Confirm password',
  passwordIsRequired: 'Password is required',
  passwordConfirmationIsRequired: 'Password confirmation is required',
  passwordConfirmationNotMatch: 'Password confirmation does not match the password',
  registrationCompleted: 'The registration has been successfully completed. Check your email to confirm account.',
  sendRequest: 'Send request',
  somethingWentWrong: 'Something went wrong. Please try again.',
  messageHasBeenSent: 'A message with a link to reset your password has been sent to the e-mail address provided',
  errorIncorrectUsernameOrPassword : 'Error: Incorrect username or password.',
  errorUsernameAlreadyExists : 'Error: Username already exists.',
  errorInvalidPassword : 'Error: Invalid password.',
  login: 'Login',
  register: 'Register',
  submit: 'Submit',
  resendCode: 'Get new confirmation code',
  codeResent: 'Code was sent. Please check your inbox',
  confirmationCode: 'Confirmation Code',
  codeIsRequired: 'Confirmation Code is required',
  welcomeNewHeader: 'Welcome new traveler!',
  welcomeNewContent: 'Please check your inbox and paste \nthe confirmation code bellow.',
  'Internal Server Error': 'Something went wrong. Please try again.',
  'user.account_confirmed': 'Your account has been successfully confirmed. You will be redirected to the login page.',
  'user.new_password_confirmed': 'Your new password has been saved. You will be redirected to the login page.',
  'user.unauthorized.incorrect_username_or_password': 'Incorrect username or password.',
  'user.username_already_exists': 'Username already exists.',
  'user.invalid_parameter': 'It seems that one of the provided parameters is incorrect',
  'user.password.incorrect_confirmation_code': 'Confirmation code is incorrect',
  'CodeMismatchException': 'Confirmation code is incorrect',
  decodeEmailError: 'Decoding email error occurred. Please fill in email input manually',
  missingRequestData: 'Cannot make request. Some data is missing',
  InvalidPasswordException: 'Invalid password.',
  ExpiredCodeException: 'It seems that your code is invalid, please make sure if it\'s correct. You can generate a new code by clicking the link below',
  NotAuthorizedException: 'You cannot perform that operation right now',
  simplifiedBookingFor: 'Simplified booking for',
  campers: 'campers',
  and: 'and',
  camping: 'camping'
}
