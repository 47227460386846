import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

export const CampsiteRatingWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const SinglePageSidebarRatingsWrapper = styled.div`
    max-width: calc(100% - 40px - 8px);
    overflow: hidden;
`;

export const SinglePageSidebarRatingsWrapperTitle = styled.div`
    color: ${themeGet('textColor.primaryDark')};
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 125%;
`;

export const SinglePageSidebarRatingsWrapperRate = styled.div`
    color: ${themeGet('textColor.secondary')};
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 125%;
`;

export const SinglePageRatingsCommentRate = styled.div`
    background: ${props => props.inactive ? themeGet('backgroundColor.secondary') : '#FFD166'};
    border-radius: 8px 8px 8px 0px;
    padding: 8px 0;
    float: left;
    color: ${themeGet('textColor.primaryDark')};
    font-size: 14px;
    font-weight: 600;
    width: 40px;
    text-align: center;
`;