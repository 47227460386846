export const aboutUs = {
  aboutUs: 'Om oss',
  title: 'Vi Samlar campingsverige!',
  contentPart1: `HPY Camper grundades 2018 med visionen att underlätta planering och bokning av boende på Sveriges 
    campingplatser.`,
  contentPart2: `HPY Camper är Sveriges bokningssida för campingplatser. Här hittar du information om nästan 1400 
    campingplatser runt vårt avlånga land vilket gör det lätt för dig att hitta resmål du tidigare inte känt till, 
    och campingplatser du inte visste fanns.`,
  contentPart3: `Vare sig du vill bo i din egen husvagn/bil, tälta eller hyra en stuga så hittar du alternativen hos 
    oss, antingen genom vår hemsida eller vår app som du kan ladda ner där appar finns.`,
  contentPart4: `Passa på att registrera dig och svara på några frågor om hur du brukar campa, så kommer vi baserat 
    på dina svar föreslå nya destinationer och campingplatser till dig.`,
  contentPart5: 'Vi ses på vägarna! :)'
}