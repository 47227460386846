import {withTranslation} from 'react-i18next';
import {Tooltip} from 'antd';

import useIsSwedish from '../../../../../library/hooks/useIsSwedish';

import * as Style from './style.js';

const AmenitiesList = ({
  t, i18n, amenities, isSmall = false
}) => {
  const isSwedish = useIsSwedish(i18n.language);

  const prepareAmenities = () => amenities.map(amenity => {
    const amenityName = isSwedish ? amenity.name.se : amenity.name.en;
    const amenityIcon = amenity.ico;
    if (!amenityIcon) {
      return null
    }
    const amenityIconUrl = `data:${amenityIcon.fileType};base64,${amenityIcon.base64StringFile}`;
    return (
      <div key={`${amenity.amenity_id}`}>
        <Tooltip title={amenityName}>
          <img src={amenityIconUrl} alt={amenityName} />
        </Tooltip>
      </div>
    )
  }).filter(e => e);

  return (
    <Style.Wrapper>
      <Style.Header>{t('BrowseCampsites.SearchCampsitesFilters.amenities')}</Style.Header>
      <Style.List isSmall={isSmall}>{prepareAmenities()}</Style.List>
    </Style.Wrapper>
  )
};

export default withTranslation()(AmenitiesList);
