// General Page Section
export const HOME_PAGE = '/';

// Results
export const BROWSE_CAMPSITES = '/browse-campsites';

// Listing Single Page Section
export const SINGLE_POST_PAGE = '/campsite';

// Static pages
export const ABOUT_US_PAGE = '/about-us';
export const FAQ_PAGE = '/faq';
export const CONTACT_PAGE = '/contact';
export const PRIVACY_PAGE = '/privacy';
export const TERMS_PAGE = '/terms-and-conditions'

export const MOBILE_PAYMENT = '/mobile-payment';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const REGISTRATION_CONFIRMATION_PAGE = '/registration-confirmation';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
export const AUTH_PAGES = [
  LOGIN_PAGE, REGISTRATION_PAGE, REGISTRATION_CONFIRMATION_PAGE, CHANGE_PASSWORD_PAGE, FORGET_PASSWORD_PAGE, MOBILE_PAYMENT,
];

// UserProfile
export const USER_PROFILE_PAGE = '/profile';
export const USER_PROFILE_PAGE_FAVORITES = '/profile/favorites';
export const USER_PROFILE_PAGE_AFA = '/profile/afa';
export const USER_PROFILE_PAGE_SETTINGS = '/profile/settings';
export const USER_PROFILE_PAGE_BOOKINGS = '/profile/bookings';


// Campsite Offer
export const CAMPSITE_OFFER_PAGE = '/campsite-offer';
