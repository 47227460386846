export const afa = {
  sent: 'Skickat',
  askForAvailabilityDetails: 'Bokningsförfrågan, detaljer',
  checkInCheckOut: 'Check in - check out',
  spotType: 'Spot type',
  make: 'Märke',
  model: 'Modell',
  year: 'Årsmodell',
  regId: 'Reg.nr',
  adultsChildren: 'Vuxna/barn',
  pets: 'Husdjur',
  additionalComments: 'Övrig information/önskemål',
  youHaveNewBookingOffer: 'Du har fått ett erbjudande',
  hello: 'Hej',
  hasOfferedYouSpot: 'Har erbjudit dig en plats',
  to: 'till',
  totalOfferedPrice: 'Totalt erbjudet pris',
  youBookThisSpot: 'Du accepterar erbjudandet/bokar genom att betala med ditt kort via länken nedan',
  youNeedToPay: 'Du behöver betala inom XX timmar för att acceptera erbjudandet och genomföra bokningen.',
  afaAdded: 'AFA tillade'
}