
import CampsiteCardList from '../../../../container/BrowseCampsites/CampsiteCard/list';
import LoadingUtils from '../utils';


const LoadingCardList = ({
  amount = 10,
  hideBorder = false,
}) => {
  const utils = LoadingUtils();

  const generateFakeCampsites = (count) => Array(count)
    .fill(null)
    .map((_, i) => ({
      id: `loading-campsite-${i}-${new Date().getTime()}`,
      gallery: {main: {thumbnail: '#'}}, // show empty image (no placeholder image)
      name: utils.getText(10, 25),
      amenities: [],
      city: utils.getText(10, 15),
      address: utils.getText(10, 20),
      types: [],
    }));

  return (
    <utils.LoadingEffectWrapper>
      <CampsiteCardList campsites={generateFakeCampsites(amount)} hideBorder={hideBorder} />
    </utils.LoadingEffectWrapper>
  )
}


export default LoadingCardList;
