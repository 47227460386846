import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

export const FooterMenuWrapper = styled.div`
    font-size: 16px;
    color: #fff;
    margin-top: 24px;

    ul {
        margin-top: 8px;

        li {
            background-color: transparent;
            text-align: left;
            line-height: 32px;
            padding: 0px !important;
            margin: 0px !important;

            :hover {
                background-color: transparent;
            }

            a {
                color: #ffffff;
                text-decoration: none;

                & .active {
                    font-weight: 800;
                }
            }
        }
    }
    
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
    
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
      margin-top: 0;
    }
`;

export const FooterMenuTitle = styled.h3`
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
`;
