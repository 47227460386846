import React, {useState} from 'react';


import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {Button} from '../../../../../components/UI/Button/Button';
import CurrencySign from '../../../../../components/UI/CurrencySign';
import useIsSwedish from '../../../../../library/hooks/useIsSwedish';
import {LOGIN_PAGE} from '../../../../../settings/constant';
import {
  SinglePagePricingGroupsSingleTitleWrapper, 
  SinglePagePricingGroupsSinglePriceWrapper, 
  SinglePagePricingGroupsSingleTop,
  SinglePagePricingGroupsSinglePrice, 
  SinglePagePricingGroupsSingleWrapper,
  SinglePagePricingGroupsSingleImage,
  SinglePagePricingGroupsSingleTitle, 
  SinglePagePricingGroupsSingleDescription,
  SinglePagePricingGroupsSingleHeadline,
  SinglePageButtonMobile,
  SinglePageButtonDesktop
} from '../style';

import SinglePagePricingGroupsSingleTags from './SinglePagePricingGroupsSingleTags';
import SinglePageConfirmationModal from './SinglePageConfirmationModal';
import SinglePageReserveModal from './SinglePageReserveModal';

const SinglePagePricingGroupsSingle = ({
  t,
  i18n,
  single = {},
  name,
  loggedIn,
  slug,
  mainImage,
  isLoading,
  dates
}) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isModalPaymentSuccessShowing, setIsModalPaymentSuccessShowing] = useState(false);

  const onPaymentCompleted = () => {
    setIsModalShowing(false);
    setIsModalPaymentSuccessShowing(true);
  }

  const {currency, spots_total_price, price, pricing_group_name} = single;

  return (
    <SinglePagePricingGroupsSingleWrapper>
      <SinglePagePricingGroupsSingleTop>
        <SinglePagePricingGroupsSingleHeadline>
          <SinglePagePricingGroupsSingleImage imageSrc={single?.photos?.main?.original || mainImage?.original} />
          <SinglePagePricingGroupsSingleTitleWrapper>
            <SinglePagePricingGroupsSingleTitle>{name}</SinglePagePricingGroupsSingleTitle>
            <SinglePagePricingGroupsSingleDescription >
              {useIsSwedish(i18n.language) ? pricing_group_name?.se : pricing_group_name?.en}
            </SinglePagePricingGroupsSingleDescription>
          </SinglePagePricingGroupsSingleTitleWrapper>
        </SinglePagePricingGroupsSingleHeadline>
        <SinglePagePricingGroupsSinglePriceWrapper onClick={() => setIsModalShowing(true)}>
          <SinglePagePricingGroupsSinglePrice>
            <span className="SinglePagePricingGroupsSinglePrice__from">{t('singlePage.From')} </span>
            {spots_total_price && (<CurrencySign isoCode={currency} amount={spots_total_price} />)}
            {!spots_total_price && (
              <>
                <CurrencySign isoCode={currency} amount={price} />
                <span className="SinglePagePricingGroupsSinglePrice__unit-label">/ {t('singlePage.night')}</span>
              </>
            )}
          </SinglePagePricingGroupsSinglePrice>
          {!isLoading && (
            <>
              <SinglePageButtonMobile>
                { !loggedIn &&
                  <Link to={LOGIN_PAGE}>
                    <Button text={t('singlePage.Reserve')} type="primary" useFullWidth />
                  </Link>
                }
                { loggedIn && <Button text={t('singlePage.Reserve')} type="primary" useFullWidth />
                }
              </SinglePageButtonMobile>
              <SinglePageButtonDesktop>
                { !loggedIn &&
                  <Link to={LOGIN_PAGE}>
                    <Button text={t('singlePage.Reserve')} type="primary" size="small" />
                  </Link>
                }
                { loggedIn && <Button text={t('singlePage.Reserve')} type="primary" size="small" />}
              </SinglePageButtonDesktop>
            </>
          )}
        </SinglePagePricingGroupsSinglePriceWrapper>
      </SinglePagePricingGroupsSingleTop>
      {single.features &&
        <SinglePagePricingGroupsSingleTags features={single.features} />
      }
      <SinglePageReserveModal
        name={name}
        slug={slug}
        single={single}
        mainImage={single?.photos?.main?.original || mainImage?.original}
        isModalShowing={isModalShowing}
        setIsModalShowing={setIsModalShowing}
        onPaymentCompleted={onPaymentCompleted}
        dates={dates}
      />
      <SinglePageConfirmationModal
        isModalShowing={isModalPaymentSuccessShowing}
        setIsModalShowing={setIsModalPaymentSuccessShowing}
      />
    </SinglePagePricingGroupsSingleWrapper>
  )
}

export default withTranslation()(SinglePagePricingGroupsSingle);
