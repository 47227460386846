import {useContext, createContext, useState, useEffect} from 'react';

import {useAPIProvider} from './AuthProvider';


const LocationHintsContext = createContext({});

const LocationHintsProvider = ({children}) => {
  const apiProvider = useAPIProvider();

  const [keys, setKeys] = useState([]);
  const [locationHintsNamesOnly, setLocationHintsNamesOnly] = useState({});
  const [locationHintsNormalized, setLocationHintsNormalized] = useState({});

  const fetchLocationHints = async () => {
    const response = await apiProvider.makeRequest('/map/list/autocomplete');
    const responseKeys = Object.keys(response);
    const responseValues = Object
      .values(response)
      .map(v => Object
        .entries(v)
        .sort((a, b) => b[1] - a[1])
        .map(([k, _]) => k)
      );

    setKeys(responseKeys);
    setLocationHintsNamesOnly(response?.name || {});
    setLocationHintsNormalized(
      Object.fromEntries(responseKeys.map((k, i) => [k, responseValues[i]]))
    );
  };

  const removeNonAsciiCharacters = (str) => str
    // eslint-disable-next-line no-control-regex
    ? str.replace(/[^\x00-\x7F]/g, '').toLowerCase()
    : ''; 

  const getByKey = async (key, query) => {
    const hintsThatStartsWith = locationHintsNormalized[key].filter(x => removeNonAsciiCharacters(x).startsWith(query));
    const hintsThatContains = locationHintsNormalized[key].filter(x => removeNonAsciiCharacters(x).includes(query));
    if ((hintsThatStartsWith.length > 0) || (hintsThatContains.length > 0)) {
      return [key, Array.from(new Set([...hintsThatStartsWith, ...hintsThatContains]))];
    }
  }

  const get = async (query) => {
    if (query && (typeof query === 'string' || query instanceof String)) {
      query = removeNonAsciiCharacters(query);
      const promises = keys.map(
        key => getByKey(key, query)
      )
      const data = await Promise.all(promises);
      return data.filter(x => x);
    }
    return [];
  };

  useEffect(() => fetchLocationHints(), []);

  const getSlugFromSelected = (key) => {
    return locationHintsNamesOnly[key];
  }

  return (
    <LocationHintsContext.Provider value={{get, getSlugFromSelected}}>
      {children}
    </LocationHintsContext.Provider>
  )
}

export const useLocationHints = () => {
  return useContext(LocationHintsContext);
}

export default LocationHintsProvider;
