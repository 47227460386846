import React, {useState, useEffect} from 'react';

import {withTranslation} from 'react-i18next';
import moment from 'moment';

import dateTimeHelper from '../../../library/helpers/dateTimeHelper';
import DateRangePickerBox from '../../UI/DatePicker/ReactDates';

import {ModalDatePickerWrapper} from './style';

const ModalDatePicker = ({
  t,
  i18n,
  onDateChange,
  dates = {},
  isDisabled = false,
}) => {
  const [value, setValue] = useState({setStartDate: moment(), setEndDate: moment()})

  useEffect(() => {
    if (dates && Object.keys(dates).length) {
      const newValues = {}
      if (dates.startDate) newValues.setStartDate = moment(dates.startDate);
      if (dates.endDate) newValues.setEndDate = moment(dates.endDate);
      if (Object.keys(newValues).length) setValue(newValues);
    }
  }, [dates.startDate, dates.endDate]);

  return (
    <ModalDatePickerWrapper>
      <div className="col-12">
        <div className="row">
          <div className="col-6 date_range_label">
            {t('singlePage.CheckIn')}: {isDisabled && value.setStartDate}
          </div>
          <div className="col-6 date_range_label">
            {t('singlePage.CheckOut')}:  {isDisabled && value.setEndDate}
          </div>
          { !isDisabled &&
            <DateRangePickerBox
              startDateId="check_in"
              endDateId="check_out"
              startDate={value ? moment(value.setStartDate) : null}
              endDate={value ? moment(value.setEndDate) : null}
              className="reservation_datepicker"
              dateFormat={dateTimeHelper.getLocale(i18n)}
              showClearDates={false}
              updateSearchData={(v) => {
                onDateChange(v);
                setValue(v);
              }}
            />
          }
        </div>
      </div>
    </ModalDatePickerWrapper>
  )

}

export default withTranslation()(ModalDatePicker);
