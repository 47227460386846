import {useTranslation} from 'react-i18next';
import {withErrorBoundary} from 'react-error-boundary'
import * as Sentry from '@sentry/browser';

import ErrorPage from '../../container/StaticPage/ErrorPage';
import Button from '../../components/UI/Button/Button';

export const withErrorBoundaries = (component) => {
  return withErrorBoundary(component, {
    FallbackComponent: () => {
      const {t} = useTranslation();
      const reloadPageBtn = (
        <Button size="small" onClick={() => window.location.reload()} key="reload-page">
          {t('error.error500.reloadPage')}
        </Button>
      );
      return (
        <ErrorPage
          title={t('error.error500.title')}
          header={t('error.error500.header')}
          content={t('error.error500.content')}
          additionalButtons={[reloadPageBtn]}
        />
      );
    },
    onError: (error, info) => {
      Sentry.addBreadcrumb({category: 'COMPONENT_ERROR', data: {error, info}});
      Sentry.captureException(error);
    }
  }) 
}

const ErrorBoundaries = ({children}) => (<>{children}</>);

export default withErrorBoundaries(ErrorBoundaries);
