import React from 'react';

import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {HOME_PAGE} from '../../../settings/constant';
import Button from '../../../components/UI/Button/Button';
import StaticPage from '../StaticPage';

import * as Style from './style';

const ErrorPage = ({
  t,
  title = '',
  header = '',
  content = '',
  additionalButtons = [],
  hideHomepageButton = false,
}) => {
  return (
    <StaticPage title={title}>
      <Style.Title>{header}</Style.Title>
      <Style.Description>{content}</Style.Description>
      <Style.ButtonsSection>
        {additionalButtons.map((btn, i) => (<span key={i}>{btn}</span>))}
        {!hideHomepageButton && (
          <NavLink exact to={`${HOME_PAGE}`}>
            <Button
              text={t('error.backToHomepage')}
              size="small"
              type="tertiary"
            />
          </NavLink>
        )}
      </Style.ButtonsSection>
    </StaticPage>
  )
}

export default withTranslation()(ErrorPage);
