import {themeGet} from '@styled-system/theme-get';
import styled, {css} from 'styled-components';

export const Container = styled.div`
  border-radius: ${props => (props.radius ? props.radius : 8)}px;
  ${props =>
    props.showPopup &&
    css`
      position: absolute;
      width: 100%;
      z-index: 999;
      min-width: 300px;
      background-color: ${themeGet('backgroundColor.white')};
      padding:  ${props => (props.noPadding ? '0' : '8px 16px')};
      margin-top: ${props => (props.noMargin ? '4px' : '28px')};
      box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
    `}

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-group-item {
      margin: 9px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .date_picker {
    margin-bottom: 0;

    .DateRangePicker {
      .DateRangePickerInput {
        border-radius: 3px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
