import styled from 'styled-components';

import BackgroundImage from '../../../../../assets/images/singlepage/spotsbg.png'


export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Icon = styled.div`
    ${props => props.disabled === true && 'filter: grayscale(1);'}
    ${props => props.disabled !== true && 'background-image: url(' + BackgroundImage + ');'}
    
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img { width: 50% }
`;
