import {createContext, useContext, useState, useEffect} from 'react';

import {AuthContext} from './AuthProvider';

export const ProfileContext = createContext({});

export const ProfileURL = Object.freeze({
  LIST: '/profile/read',
  ADD: '/profile/save',
});

export const ProfileProvider = ({children}) => {
  const authProvider = useContext(AuthContext);
  const authIsLoggedIn = authProvider.getLoggedIn();

  const [profile, setProfile] = useState(null);

  const updateProfile = async (data) => {
    if (authIsLoggedIn) {
      await authProvider.APIProvider.makeRequest(ProfileURL.ADD, data, 'POST');
      fetchProfile(); // Update cached profile in background
    }
  }

  const fetchProfile = async () => {
    if (authIsLoggedIn) {
      const data = await authProvider.APIProvider.makeRequest(ProfileURL.LIST);
      if (data) {
        setProfile(data);
        return data;
      }
    }
  }

  const readProfile = async () => {
    return profile ? profile : fetchProfile();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => readProfile(), [authIsLoggedIn]);

  return (
    <ProfileContext.Provider
      value={{authIsLoggedIn, updateProfile, readProfile, profile}}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
