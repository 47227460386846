export const singlePage = {
  AFASuccess: 'The request has been sent successfully.',
  Close: 'Close',
  PaymentCompleted: 'Payment completed',
  ThanksForPayment: 'Thank you for your payment',
  ReservationCompleted : 'Reservation completed',
  ThanksForReservation: 'Thank you for your registration',
  ReservationCompletedText: 'You find all details regarding your reservation in under Bookings in your HPY Camper account. For your convenience we have also sent you an e-mail with all details.\n\nIn the booking details, you will also find the contact information to the campsite. Please address any questions you might have about your stay directly to them.',
  Gallery : 'Gallery',
  TentSpots : 'Tent spots',
  TrailerSpots : 'Trailer spots',
  Cabins : 'Cabins',
  Description : 'Description',
  Amenities : 'Amenities',
  Rating : 'Rating',
  Ratings : 'Ratings',
  SeeAllRatings : 'See all ratings',
  ThisWasBad : 'This was Bad',
  ThisWasGood : 'This was Good',
  Published : 'Published',
  AskForAvailability : 'Ask for Availability',
  Make : 'Make',
  Model : 'Model',
  YearOfProd : 'Year of prod',
  RegistrationId : 'Registration ID',
  Pets : 'Pets',
  Checkifyouwanttotravelwithyourpets : 'Check if you want to travel with your pets',
  AdditionalComment : 'Additional comment',
  All : 'All',
  Vehicles : 'Vehicles',
  Tents : 'Tents',
  PricingGroups : 'Pricing Groups',
  night : 'night',
  MakePaymentForTotal : 'Make payment for total',
  AdditionalFeatures : 'Additional features',
  FirstName : 'First name',
  LastName : 'Last name',
  Email : 'Email',
  ZipCode : 'Zip code',
  CheckIn : 'Check in',
  CheckOut : 'Check out',
  SendRequest : 'Send Request',
  Reserve : 'Reserve',
  Location : 'Location',
  photo : 'Photo',
  photos : 'photos',
  SeePrices : 'See prices',
  From : 'from',
  ErrorDateNot : 'Not available on the selected dates.',
  OkDateNot : 'Available on the selected dates.',
  ThisFieldIsRequired : 'This field is required',
  PaymentInFuture : 'Thank you for booking the campsite with HpyCamper! The payments will be available soon and the information will be sent to the e-mail address you used for the registration process. Then you\'ll be able to proceed with payment via the Bookings page in your HpyCamper profile.',
  ReserveError : 'Some problems with register reservation. Please try again.',
  FulfillDates: 'Please enter the date of the planned reservation',
  Camper: 'RV',
  Cabin: 'Cabin',
  Tent: 'Tent',
  PreferredSpot: 'Preferred Spot',
  Adults: 'Adults',
  Children: 'Children',
  no_spots_available: 'There are no spots available in the selected period.',
}
