import React, {useState, useContext} from 'react';

import {Drawer} from 'antd';
import {withRouter} from 'react-router-dom';
import Sticky from 'react-stickynode';

import crossIcon from '../../../assets/images/icons/cross.svg';
import hamburgerIcon from '../../../assets/images/hamburger-icon.svg';
import logoFile from '../../../assets/images/logo.svg';
import Navbar from '../../../components/Navbar/Navbar';
import Logo from '../../../components/UI/Logo/Logo';
// import Text from 'components/UI/Text/Text';
// import TextLink from 'components/UI/TextLink/TextLink';
import {AuthContext} from '../../../context/AuthProvider';
import useWindowSize from '../../../library/hooks/useWindowSize';

// import { AGENT_PROFILE_PAGE } from 'settings/constant';
import AuthMenu from './AuthMenu/AuthMenu';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  LogoArea,
} from './Header.style';
import LangMenu from './LangMenu/LangMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';

// import ProfileMenu from './ProfileMenu';
// import NavbarSearch from './NavbarSearch';

export default withRouter(function Header() {
  // const [{ searchVisibility }] = useContext(LayoutContext);
  const {getLoggedIn} = useContext(AuthContext);
  const {width} = useWindowSize();
  const [state, setState] = useState(false);

  const sidebarHandler = () => {
    setState(!state);
  };

  const headerType = 'default';
  const loggedIn = getLoggedIn();
  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                <Logo
                  withLink
                  linkTo="/"
                  src={logoFile}
                  title="HPY Camper"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu isLogin={loggedIn} />}
            langMenu={<LangMenu />}
            // isLogin={loggedIn}
            // avatar={<Logo src={avatarImg} />}
            // profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <Logo
                withLink
                linkTo="/"
                src={logoFile}
                title="HPY Camper"
              />
            </LogoArea>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img src={hamburgerIcon} onClick={sidebarHandler} alt="open-menu-icon" />
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              visible={state}
            >
              <CloseDrawer>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img src={crossIcon} onClick={sidebarHandler} alt="close-menu-icon" />
              </CloseDrawer>
              <AuthMenu className="auth-menu" isLogin={loggedIn} />
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
