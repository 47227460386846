export const userBooking = {
  'name&address': 'Namn och adress',
  cancelReservation: 'Avboka bokning',
  cancelSuccess: 'Bokningen avbröts',
  cancelError: 'Något gick fel. Reservationen kunde inte avbokas',
  checkIn: 'Checka In',
  checkOut: 'Checka Ut',
  viewDetails: 'Visa detaljer',
  bookAgain: 'Boka igen',
  makePayment: 'Gör betalning',
  postReview: 'Lägg upp en recension',
  receipt: 'Kvitto',
  new: 'Ny',
  paid: 'Betalas',
  completed: 'Avslutad',
  current: 'Actuell',
  history: 'Historia',
  status: {
    'new': 'Ny',
    'paid': 'Betalas',
    'installment': 'Avbetalning',
    'cancelled': 'Inställt',
    'checked_out': 'Checkat Ut',
    'checked_in': 'Incheckat',
    'processingPayment': 'Bearbetar betalning',
  },
  noDataInformation: {
    header: 'Du har inte gjort några bokningar än',
    text: 'Sök bland alla campingar för att hitta ditt nästa resmål!',
    action: 'Campingkarta',
  },
  details: {
    visitors: 'Besökare',
    adults: 'Vuxna',
    children: 'Barn',
    price: 'Pris',
    created: 'Skapelsedagen',
    details: 'Ytterligare Information',
    reservationNumber: 'Bokningsnummer',
  },
  paymentInFuture : 'Betalningarna kommer att finnas tillgängliga inom kort och informationen kommer att skickas till den e-postadress du använde för registreringsprocessen. '
}
