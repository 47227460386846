import React from 'react';

import {Menu} from 'antd';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {
  HOME_PAGE,
  BROWSE_CAMPSITES,
  ABOUT_US_PAGE,
  FAQ_PAGE,
  CONTACT_PAGE
} from '../../../settings/constant';

const MainMenu = ({className, t}) => {
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          {t('menu.home')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${BROWSE_CAMPSITES}`}>
          {t('menu.browseCampsites')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${ABOUT_US_PAGE}`}>
          {t('menu.aboutUs')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${FAQ_PAGE}`}>
          {t('menu.FAQ')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${CONTACT_PAGE}`}>
          {t('menu.contact')}
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default withTranslation()(MainMenu);
