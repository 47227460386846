import {aboutUs} from './en/aboutUs_en';
import {afa} from './en/afa_en';
import {allSwedenCampsites} from './en/allSwedenCampsites_en';
import {auth} from './en/auth_en';
import {authMenu} from './en/authMenu_en';
import {BrowseCampsites} from './en/BrowseCampsites';
import {campsiteOffer} from './en/campsiteOffer_en';
import {campsitesInSpotlight} from './en/campsitesInSpotlight_en';
import {common} from './en/common_en';
import {contact} from './en/contact_en';
import {experiences} from './en/experiences_en';
import {faq} from './en/faq_en';
import {footer} from './en/footer_en';
import {hero} from './en/hero_en';
import {menu} from './en/menu_en';
import {error} from './en/error_en';
import {ratings} from './en/ratings_en';
import {searchForm} from './en/searchForm_en';
import {searchPlanBookDone} from './en/searchPlanBookDone_en';
import {singlePage} from './en/singlePage_en';
import {shareButton} from './en/shareButton_en';
import {userAFA} from './en/userAFA_en';
import {userBooking} from './en/userBooking_en';
import {userFavorites} from './en/userFavorites_en';
import {userProfileAsideMenu} from './en/userProfileAsideMenu_en';
import {userSettings} from './en/userSettings_en';

const en = {
  translations: {
    aboutUs, 
    afa,
    allSwedenCampsites,
    auth,
    authMenu,
    BrowseCampsites, 
    campsiteOffer, 
    campsitesInSpotlight,
    common,
    contact,
    experiences, 
    faq,
    footer,
    hero, 
    menu, 
    error,
    ratings, 
    searchForm, 
    searchPlanBookDone, 
    shareButton, 
    singlePage,
    userAFA,
    userBooking,
    userFavorites,
    userProfileAsideMenu, 
    userSettings  
  }
}

export default en;
