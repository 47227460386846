import ISO_CURRENCY_DATA from './data';

const CurrencySign = ({isoCode, amount}) => {

  const isoCodeUpperCase = String(isoCode).toUpperCase();
  const currentCurrencyData = ISO_CURRENCY_DATA[isoCodeUpperCase];

  if (isoCode && currentCurrencyData) {
    return (
      <>
        {currentCurrencyData.symbolBeforeValue !== true && (<>{amount}{'\u2009'}</>)}
        <span aria-label={currentCurrencyData.name} data-iso={isoCodeUpperCase}>{currentCurrencyData.symbolNative}</span>
        {currentCurrencyData.symbolBeforeValue === true && (<>{'\u2009'}{amount}</>)}
      </>
    );
  }

  return isoCode || '';
};

export default CurrencySign;
