import {createContext, useContext, useState, useEffect} from 'react';

import {AuthContext} from './AuthProvider';

export const FavoritesContext = createContext({});

export const FavoritesURL = Object.freeze({
  LIST: '/profile/favorites',
  ADD: '/profile/favorites/add',
  REMOVE: '/profile/favorites/remove',
});

export const FavoritesProvider = ({children}) => {
  const authProvider = useContext(AuthContext);
  const authIsLoggedIn = authProvider.getLoggedIn();

  const [favorites, setFavorites] = useState(new Set());
  const [invalidation, setInvalidation] = useState(0);
  const isFavorite = (slug) => favorites.has(slug);

  const setNewInvalidation = () => {
    setInvalidation((new Date()).getTime());
  }

  const setFavorite = async (slug) => {
    if (authIsLoggedIn) {
      setFavorites(favorites.add(slug));
      await authProvider.APIProvider.makeRequest(FavoritesURL.ADD, {slug}, 'POST');
    }
  }

  const unsetFavorite = async (slug) => {
    if (authIsLoggedIn) {
      favorites.delete(slug);
      setFavorites(favorites);
      await authProvider.APIProvider.makeRequest(FavoritesURL.REMOVE, {slug}, 'POST');
    }
  };

  const fetchList = async (isForceRequest = false) => {
    if (authIsLoggedIn || isForceRequest) {
      const data = await authProvider.APIProvider.makeRequest(FavoritesURL.LIST);
      if (data) {
        const newFavoritesSet = new Set(data.map(entry => entry.slug));
        setFavorites(newFavoritesSet);
        return newFavoritesSet;
      }
    }
  };

  useEffect(() => fetchList(), [authIsLoggedIn]);
  useEffect(() => setNewInvalidation(), [favorites]);

  return (
    <FavoritesContext.Provider
      value={{
        authIsLoggedIn, 
        invalidation, 
        favorites, 
        isFavorite, 
        setFavorite, 
        unsetFavorite, 
        fetchList
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
}

export default FavoritesProvider;
