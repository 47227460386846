import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

const ReactDatesStyleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .ant-form-item {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 0;
    margin-left: 0;
    border: none;

    &:last-child {
      margin-right: 0;
    }

    .ant-form-item-label {
      label {
        font-size: 13px;
      }
    }
  }
  
  .DateRangePickerInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding-top: 1px;
  }
  
  .DateRangePickerInput__withBorder {
    border: none;
  }
  
  .DateRangePickerInput__showClearDates {
    padding-right: 0;
  }
  
  .DateInput__small {
    width: auto;
  }
  
  .DateInput_input__small {
    padding: 0;
    margin-top: 6px;
    font-weight: 400;
    font-size: 16px;
    color: ${themeGet('textColor.secondary')};
  }
  
  .DateInput_input__focused {
    border-bottom: none;
  }
  
  .DateInput_input[aria-label="Start date"] {
    text-align: left;
  }
  
  .DateInput_input[aria-label="End date"] {
    text-align: right;
  }
  
  .DateInput_input[aria-label="Incheckning"] {
    text-align: left;
  }
  
  .DateInput_input[aria-label="Utcheckning"] {
    text-align: right;
  }
  
  .CalendarDay__selected {
    background: ${themeGet('backgroundColor.primary')};
    border-color: ${themeGet('textColor.primary')};
    font-weight: 600;
    
    &:hover {
      background: ${themeGet('backgroundColor.primary')};
      border-color: ${themeGet('textColor.primary')};
    }
  }
  
  .CalendarDay__selected_span {
    background: ${themeGet('backgroundColor.primaryLight')};
    border: ${themeGet('backgroundColor.primaryLight')};
    color: ${themeGet('textColor.primaryDark')};
    font-weight: 600;
    
    &:hover {
      background: ${themeGet('backgroundColor.primaryLight')};
      border: ${themeGet('backgroundColor.primaryLight')};
      color: ${themeGet('textColor.primaryDark')};
    }
  }
  
  .DateInput_fang {
    display: none;
  }
  
  .CalendarMonth_table {
    margin-top: 8px;
  }
  
  .DateRangePickerInput_clearDates {
    padding: 0;
    margin: 0;
    left: calc(100% - 16px);
    margin-top: 2px;
    
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
      left: calc(100%);
    }
            
    svg {
      transition: fill 0.1s ease-in;
      fill: ${themeGet('textColor.primaryDark')};
    }
    
    &:hover {
      background: none;
      svg {
        fill: ${themeGet('textColor.primary')};
      }
    }
    
  }
`;

export {ReactDatesStyleWrapper};
