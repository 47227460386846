import React from 'react';

import {Menu} from 'antd';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {
  PRIVACY_PAGE,
  TERMS_PAGE,
  FAQ_PAGE,
  ABOUT_US_PAGE,
  BROWSE_CAMPSITES,
  CONTACT_PAGE
} from '../../../../../settings/constant';

import {FooterMenuWrapper, FooterMenuTitle} from './style';


export const FooterMenu = ({
  t,
  title = '',
  isForCampsiteOwner = false
}) => {

  const getMenuLinks = () => {
    if (isForCampsiteOwner) return [
      {key: 'footer-menu-x-1', link: '#campsite-owner', translation: 'connectCampsite'}
    ];

    return [
      {link: BROWSE_CAMPSITES, translation: 'browseCampsites'},
      {link: CONTACT_PAGE, translation: 'contact'},
      {link: ABOUT_US_PAGE, translation: 'aboutUs'},
      {link: FAQ_PAGE, translation: 'faq'},
      {link: PRIVACY_PAGE, translation: 'privacyPolice', blank: true},
      {link: TERMS_PAGE, translation: 'termsAndConditions', blank: true},
    ].map((entry, i) => {
      entry.key = `footer-menu-${i}`;
      return entry;
    });
  }

  return (
    <FooterMenuWrapper>
      <FooterMenuTitle>{title}</FooterMenuTitle>
      <Menu>
        {getMenuLinks().map(menuLink => (
          <Menu.Item key={menuLink.key}>
            <NavLink to={menuLink.link} target={menuLink.blank ? '_blank' : '_self'}>{t(`footer.menu.${menuLink.translation}`)}</NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </FooterMenuWrapper>
  )
}

export default withTranslation()(FooterMenu);
