import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

export const GuestTypeContainer = styled.div`
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
      ${props => props.showInRow && `
        display: flex;
        gap: 1rem;
        flex-wrap: no-wrap;
        justify-content: space-between;
      `};
  }
`;

export const GuestTypeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${themeGet('textColor.primaryDark')};
  font-weight: 600;
  line-height: 18px;
  padding: 12px 0;
  font-size: 14px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &:not(.in-row) {
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
      max-width: 236px;
    }
  }

  &.in-row {
    width: 100%;
  }
`;

export const ChangeAmount = styled.div`
  width: 16px;
`;

export const ChangeAmountPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ChangeAmountIcon = styled.div`
  cursor: pointer;
  background: rgba(19,10,46,.08);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.disabled {
    opacity: .4;
    cursor: default;
  }
`;
