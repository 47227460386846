import {useState, useEffect, useMemo} from 'react';

import {Fade} from 'react-reveal';

import SpotList from './types/SpotList';
import CardList from './types/CardList';
import Layer from './types/Layer';
import Wrapper from './types/Wrapper';

const LOADING_VERSION = Object.freeze({
  WRAPPER: 'wrapper',
  LAYER: 'layer',
  CARD_LIST: 'cardList',
  SPOT_LIST: 'spotList'
});


const LoadingComponent = ({
  isLoading,
  children,
  amount = 10,
  height = '40rem',
  width = '100%',
  display = null,
  useScrollToTop = false,
  useUnloadingChangeTimeout = false,
  version = LOADING_VERSION.WRAPPER,
  hideBorder = false,
}) => {
  const [isLoadingState, setIsLoadingState] = useState(isLoading);
  const [timeoutPointer, setTimeoutPointer] = useState(null);

  useEffect(() => { 
    if(!isLoadingState && useScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [isLoadingState]);

  useEffect(() => {
    if (isLoadingState === isLoading) return;
    if (!isLoading && useUnloadingChangeTimeout) {
      const newTimeoutEvent = setTimeout(() => setIsLoadingState(isLoading), 2000);
      clearTimeout(timeoutPointer);
      setTimeoutPointer(newTimeoutEvent);
    } else {
      setIsLoadingState(isLoading);
    }
  }, [isLoading]);

  const SpotListMemo = useMemo(() => (<SpotList amount={amount} />), [amount]);
  const cardListMemo = useMemo(() => (<CardList hideBorder={hideBorder} amount={amount} />), [amount, hideBorder]);
  const wrapperMemo = useMemo(() => (<Wrapper height={height} width={width} />), [height, width]);

  const loadingComponent = (
    <>
      {version === LOADING_VERSION.WRAPPER && (isLoadingState ? wrapperMemo : <Fade>{children}</Fade>)}
      {version === LOADING_VERSION.LAYER && <Layer display={display} isLoading={isLoading}>{children}</Layer>}
      {version === LOADING_VERSION.CARD_LIST && (isLoadingState ? cardListMemo : <Fade>{children}</Fade>)}
      {version === LOADING_VERSION.SPOT_LIST && (isLoadingState ? SpotListMemo : <Fade>{children}</Fade>)}
    </>
  );

  return loadingComponent;
};


export const LoadingSpotList = (props) => LoadingComponent({...props, version: LOADING_VERSION.SPOT_LIST});
export const LoadingCardList = (props) => LoadingComponent({...props, version: LOADING_VERSION.CARD_LIST});
export const LoadingWrapper = (props) => LoadingComponent({...props, version: LOADING_VERSION.WRAPPER});
export const LoadingLayer = (props) => LoadingComponent({...props, version: LOADING_VERSION.LAYER});

