import {useState, useEffect, createContext} from 'react';

import {useLocation} from 'react-router-dom';

export const RouterContext = createContext({});

const RouterProvider = ({children}) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname
  });

  useEffect(()=> {
    setRoute((prev)=> ({to: location.pathname, from: prev.to}));
  }, [location]);

  return (
    <RouterContext.Provider value={{route, location}}>
      {children}
    </RouterContext.Provider>
  );
};

export default RouterProvider;
