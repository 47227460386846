import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

export const AuthMenuUserDropdownTrigger = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  font-size: 14px;
  color: ${themeGet('textColor.secondary')};
  min-width: max-content;
`;

export const AuthMenuUserDropdownAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background: ${themeGet('backgroundColor.secondary')};
  position: relative;
  border: 1px solid ${themeGet('backgroundColor.secondary')};
  
  img {
    height: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const AuthMenuUserDropdownIcon = styled.img`
  opacity: 0.3;
  margin-left: 8px;
`;

export const AuthMenuUserDropdownOptions = styled.ul`
  display: block !important;
  margin: 0 !important;
  
  a {
    color: ${themeGet('textColor.secondary')};
  }
`;

export const AuthMenuUserDropdownOptionItem = styled.li`
  padding: 8px 0 !important;
  font-size: 16px; !important;
  border-left: none !important;
  cursor: pointer;
  color: ${themeGet('textColor.secondary')};
  
  &:hover {
    color: ${themeGet('textColor.primary')} !important;
  }
`;