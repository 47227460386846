export const footer = {
  menu: {
    browseCampsites: 'Browse Campsites',
    contact: 'Contact',
    aboutUs: 'About us',
    faq: 'FAQ',
    privacyPolice: 'Privacy Police',
    termsAndConditions: 'Terms and conditions',
    connectCampsite: 'Connect your campsite',
  },
  copyrights: 'HPY Camper. All rights reserved',
  forTheCampsiteOwner: 'For the campsite owner',
  usefulLinks: 'Useful links',
  description: 'Please contact us with any questions. HPY Camper is an registered tradmark of HC Lifestyle & Reservations AB.',
  phone: '+46 10 303 13 00'
}
