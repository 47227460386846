import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 2px 0;
  margin: ${props => props.disuseMargin ? '0' : '10px 0'};
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const CheckboxInput = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CDDAE1;
  
  &.checked {
    background: ${themeGet('backgroundColor.primary')};
    border-color: ${themeGet('backgroundColor.primary')}; 
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.75;
    filter: grayscale(0.5);
  }
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  color: ${themeGet('textColor.primaryDark')};
  
  &.checked {
    font-weight: 600;
  }

  &.disabled {
    opacity: 0.75;
    cursor: auto;
  }
`;
