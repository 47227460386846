import React from 'react';

import fbIcon from '../../../../../assets/images/footer/social/fb.png'
import instagramIcon from '../../../../../assets/images/footer/social/instagram.png'
// import snapchatIcon from '../../../../../assets/images/footer/social/snapchat.png'
// import twitterIcon from '../../../../../assets/images/footer/social/twitter.png'

import {FooterSocialWrapper, FooterSocialSingleIcon, FooterSocialSingleWrapper} from './style';

const FooterSocial = () => {

  return (
    <FooterSocialWrapper>
      <FooterSocialSingleWrapper>
        <a href="https://www.facebook.com/HpyCamper.se" target="_blank" rel="noreferrer">
          <FooterSocialSingleIcon src={fbIcon} />
        </a>
      </FooterSocialSingleWrapper>
      {/*<FooterSocialSingle icon={snapchatIcon} link="" />*/}
      {/*<FooterSocialSingle icon={twitterIcon} link="" />*/}
      <FooterSocialSingleWrapper>
        <a href="https://www.instagram.com/hpycamper.se" target="_blank" rel="noreferrer">
          <FooterSocialSingleIcon src={instagramIcon} />
        </a>
      </FooterSocialSingleWrapper>
    </FooterSocialWrapper>
  )

}

export default FooterSocial;