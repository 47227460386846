import CampsiteRating from '../CampsiteRating';
import FavoriteButton from '../../FavoriteButton/FavoriteButton';
import ShareButton from '../../ShareButton';

import * as Style from './style';

const RateShareFavoriteBox = ({rating, slug}) => {
  return (
    <Style.RateShareFavoriteBox>
      <CampsiteRating rating={rating} />
      <Style.RateShareFavoriteBoxButtons>
        <ShareButton slug={slug} />
        <FavoriteButton slug={slug} />
      </Style.RateShareFavoriteBoxButtons>
    </Style.RateShareFavoriteBox>
  )
}

export default RateShareFavoriteBox;