import {useEffect, useState} from 'react';

import {Checkbox} from '../Checkbox/Checkbox';

/**
 * Store information about checked checkboxes and notify whenever data changes
 * 
 * @param {Array} items - an array of objects with properties 'label' and 'value'
 * @param {Callable} onStateChange - hook that get run after each data change
 */
export const CheckboxGroupWithState = ({
  items = [],
  isDisabled = false,
  forceClear = false,
  onStateChange = () => null,
}) => {
  const [checkedItems, setCheckedItems] = useState(new Set());

  const handleChange = (state, value) => {
    if (state) {
      setCheckedItems(checkedItems.add(value));
    } else {
      checkedItems.delete(value);
      setCheckedItems(checkedItems);
    }

    onStateChange(checkedItems);
  }

  useEffect(() => {
    const initItemsChecked = items.filter(e => e.isChecked).map(e => e.value);
    if (initItemsChecked.length > 0) {
      const newCheckedItems = new Set([...checkedItems, ...initItemsChecked]);
      const isNewData = !(newCheckedItems.size === checkedItems.size && [...newCheckedItems].every(v => checkedItems.has(v)));
      if (isNewData) {
        setCheckedItems(newCheckedItems);
        onStateChange(newCheckedItems);
      }
    }
  }, [items]);

  return (
    <>
      {(items || []).map(item =>
        <Checkbox
          {...item}
          onCheckStateChange={handleChange}
          key={item.value}
          isDisabled={isDisabled}
          forceClear={forceClear}
        />
      )}
    </>
  )
}
