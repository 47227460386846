import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

export const FooterSection = styled.section`
    background: ${themeGet('textColor.primaryDark')};
    padding: 60px 16px 26px 16px;
    width: 100%;
    
    .ant-menu {
        border:none;
        background:none;
    }
`;
export const FooterWrapper = styled.section`
    margin: 0 auto;
    max-width: 1192px;
    
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
       display: flex;
       gap: 48px;
       justify-content: space-between;
    }
`;

export const FooterColumn = styled.div`
  width: 100%;
`;

export const FooterDescription = styled.section`
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    padding: 24px 0 16px;
`;

export const FooterContact = styled.section``;
export const FooterCollaboration = styled.section``;
export const FooterNewsletter = styled.section``;

export const FooterBottom = styled.section`
    background: ${themeGet('textColor.primaryDark')};
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
    margin-top: 24px;
`;

export const FooterCopyrights = styled.section`
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 24px;
    text-align: center;
    
    @media only screen and (max-width: 768px) {
        padding-top: 20px;
        margin-top: 20px;
    }
    
    @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
      text-align: left;
    }
`;