export const searchForm = {
  addGuests: 'Add guests',
  adults: 'Adults',
  children: 'Children',
  whereYouGoing: 'Where are you going?',
  property: 'property',
  properties: 'properties',
  pickPropertyType: 'Pick property type',
  search: 'Search',
  location: 'Location',
  date: 'Date',
  propertiesCapitalize: 'Properties',
  guest: 'guest',
  guests: 'guests',
  guestsCapitalize: 'Guests',
  'type.cabin': 'Cabin',
  'type.vehicle': 'Caravan',
  'type.tent': 'Tent',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  hints: {
    name: 'Names',
    regions: 'Regions',
    city: 'Cities',
    address: 'Addresses',
  }
}
