import en from './en';
import se from './se';

const config = {
  resources: {
    en: en,
    se: se,
  },
  fallbackLng: {
    'sv': ['se'],
    'default': ['en']
  },
  debug: false,
  defaultNS: 'translations',
  keySeparator: '.',
  interpolation: {
    formatSeparator: false
  },
  react: {
    wait: true
  }
}

export default config;
