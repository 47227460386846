import {useContext} from 'react';

import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import {Button} from '../../components/UI/Button/Button';
import {AuthContext} from '../../context/AuthProvider';
import {REGISTRATION_PAGE, BROWSE_CAMPSITES} from '../../settings/constant';

import {
  SearchPlanBookDoneWrapper,
  SearchPlanSectionTitle,
  SearchPlanSectionContent,
  SearchPlanSectionButtonsMobile,
  SearchPlanSectionButtonsDesktop,
  ImageTile,
  WaveUnderlayMobile,
  WaveUnderlayDesktop
} from './SearchPlanBookDone.style';

const SearchPlanBookDone = ({t}) => {
  const authProvider = useContext(AuthContext);
  const isLoggedIn = authProvider.getLoggedIn();

  return (
    <SearchPlanBookDoneWrapper>
      <SearchPlanSectionTitle>
        <span>{t('searchPlanBookDone.search')}</span>
        <span>{t('searchPlanBookDone.plan')}</span>
        <span>{t('searchPlanBookDone.book')}</span>
        <span>{t('searchPlanBookDone.done')}</span>
      </SearchPlanSectionTitle>
      <SearchPlanSectionContent>
        {t('searchPlanBookDone.everyoneLikesDifferentThings')}
        <strong>{t('searchPlanBookDone.campingProfileHere')}</strong>
        {t('searchPlanBookDone.weWillHelpYou')}
        <strong>{t('searchPlanBookDone.sortOutTheCampsites')}</strong>
        {t('searchPlanBookDone.thatProbably')}!
      </SearchPlanSectionContent>
      <SearchPlanSectionButtonsMobile>
        { isLoggedIn
          ? (
            <NavLink to={`${BROWSE_CAMPSITES}`}>
              <Button text={t('searchPlanBookDone.browseCampsites')} />
            </NavLink>
          ) : (
            <>
              <NavLink to={`${REGISTRATION_PAGE}`}>
                <Button text={t('searchPlanBookDone.createAnAccount')} />
              </NavLink>
              <NavLink to={`${BROWSE_CAMPSITES}`}>
                <Button text={t('searchPlanBookDone.browseCampsites')} type="secondary"/>
              </NavLink>
            </>
          )
        }
      </SearchPlanSectionButtonsMobile>
      <SearchPlanSectionButtonsDesktop>
        { isLoggedIn
          ?
          <NavLink to={`${BROWSE_CAMPSITES}`}>
            <Button size={'small'} text={t('searchPlanBookDone.browseCampsites')} />
          </NavLink>
          :
          <>
            <NavLink to={`${REGISTRATION_PAGE}`}>
              <Button size={'small'} text={t('searchPlanBookDone.createAnAccount')} />
            </NavLink>
            <NavLink to={`${BROWSE_CAMPSITES}`}>
              <Button size={'small'} text={t('searchPlanBookDone.browseCampsites')} type="secondary" />
            </NavLink>
          </>
        }
      </SearchPlanSectionButtonsDesktop>
      <ImageTile className="ImageTile__tile-1" />
      <ImageTile className="ImageTile__tile-2" />
      <ImageTile className="ImageTile__tile-3" />
      <ImageTile className="ImageTile__tile-4" />
      <ImageTile className="ImageTile__tile-5" />
      <ImageTile className="ImageTile__tile-6" />
      <WaveUnderlayMobile />
      <WaveUnderlayDesktop />
    </SearchPlanBookDoneWrapper>
  )
}

export default withTranslation()(SearchPlanBookDone);
