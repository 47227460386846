import {css} from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const generateTransitionStateMachine = (style) => (state) => ({...(style.default || {}), ...(style[state] || {})})

export const animateImageBackgroundOnHover = css`
    transition: all 0.75s ease-in;

    &:hover {
        transform: scale(1.1, 1.1);
        box-shadow: inset 0px 0px 32px 8px rgba(0, 0, 0, 0.2);
    }
`;

export const animateImageIconOnHover = css`
  transition: all 0.75s ease-in;

  &:hover {
    filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.5));
  }
`;

export const animateLinkOnHover = css`
    transition: all 0.25s ease-in;

    &:hover {
        color: ${themeGet('textColor.primary')};
    }
`;

export const animateButtonOnHover = css`
    box-shadow: inset 0px 0px 8px 8px rgba(0, 0, 0, 0);
    filter: grayscale(0);
    transition: all 0.75s ease-in;

    &:hover {
        box-shadow: inset 0px 0px 8px 8px rgba(0, 0, 0, 0.2);
        filter: grayscale(0.1);
        box-shadow: ${themeGet('boxShadow.small')};
    }
`;

export const clickTransition = generateTransitionStateMachine({
  default: {opacity: 1, transition: 'opacity 0.25s ease-in'},
  entering: {opacity: 0.75},
});
