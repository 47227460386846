export const searchPlanBookDone = {
  everyoneLikesDifferentThings: 'Everyone likes different things, what do you appreciate about your holiday? By creating your and your family\'s own ',
  campingProfileHere: 'camping profile here at HPYCamper',
  weWillHelpYou: ' we will help you ',
  sortOutTheCampsites: 'sort out the campsites',
  thatProbably: ' that you probably appreciate a little extra',
  search: 'Search',
  plan: 'Plan',
  book: 'Book',
  done: 'Done',
  createAnAccount: 'Create an account',
  browseCampsites: 'Browse campsites'
}