export const authMenu = {
  login: 'Login',
  register: 'Register',
  userSettings: 'User settings',
  signOut: 'Sign out',
  myAccount: 'My account',
  bookings: 'Bookings',
  afa: 'AFA',
  favorites: 'Favorites',
  loggedOut: 'Logged out'
}
