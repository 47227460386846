export const campsiteOffer = {
  type: {
    tent: 'Tält',
    cabin: 'Stuga',
    vehicle: 'Husbil/husvagn',
  },
  sent: 'Skickat',
  pets: 'Husdjur',
  no: 'Nej',
  yes: 'Ja',
  hello: 'Hej',
  submit: 'Skicka erbjudande',
  decline: 'Neka erbjudande',
  seeDetails: 'Visa detaljer',
  checkInCheckOut: 'Checkin - checkout',
  bookingReference: 'Bokningsreferens: ',
  detailsIntro: 'Väntar på bokningsdetaljer',
  detailsIntroName: 'John',
  spotType: 'Boendetyp',
  adultsAndChildren: 'Vuxna/Barn',
  additionalComments: 'Överiga kommentarer',
  spotKindQuestion: 'Vilken typ av boende har du ledigt?',
  additionalInfo: 'Övrig information om erbjudet boende',
  additionalInfoPlaceholder: 'T.ex. 2 bäddars stuga med utsikt över sjön, nära servicehus',
  priceOffer: 'Ditt erbjudna pris (i kr)',
  totalPrice: 'Totalt pris',
  missingRequestInformation: 'Vänligen dubbelkolla din URL adress, viss information verkar saknas.',
  warningHeading: 'När du skickat erbjudandet har {{firstName}} 48 timmar på sig att acceptera eller neka.',
  warningText: '' + 
`Om kunden accepterar ditt erbjudande betalar dom totalsumman direkt till HPY Camper. HPY Camper
överför däreter beloppet till dig efter det att vår kommission om XX% är avdragen.
Denna bokning är inte avbokningsbar för kunden.


Vi Kontaktar dig så snart kunden accepterar ditt erbjudande. Kunden kan även välja att neka erbjudandet, givetvis kontaktar vi dig även om så sker.
`
};
