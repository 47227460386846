export const userSettings = {
  editProfile: 'Ändra profil',
  changePassword: 'Byt lösenord',
  userQuestionnaire: 'Campingprofil',
  unsavedChanges: 'Du måste spara ändringarna',
  saveSettings: 'Spara',
  email: 'E-post',
  phoneNumber: 'Mobilnummer',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  address: 'Adress',
  city: 'Ort',
  zipCode: 'Postnummer',
  oldPassword: 'Nuvarande lösenord',
  newPassword: 'Nytt lösenord',
  confirmNewPassword: 'Bekräfta nytt lösenord',
  howUsuallyStay: 'Hur brukar du normalt campa?',
  howManyPeople: 'Hur många i familjen är ni normalt som campar tillsammans?',
  whenDoYouCamping: 'När föredrar ni att campa?',
  howManyWeeks: 'Hur många veckor per år brukar ni campa?',
  whatIsImportant: 'Vad bör finnas på en campingplats som passar dig? (välj max 5)',
  whereToYouPrefer: 'Vilket bor du helst i närheten av:? (välj max 2)',
  'type.vehicle': 'I egen husbil/vagn',
  'type.tent': 'I tält',
  'type.cabin': 'I stuga',
  userQuestionnaireDesc: 'Alla gillar olika saker, -Vad gör er semester extra speciell? Genom att skapa er egna Campingprofil här på HPY Camper hjälper vi er att hitta de campingar som ni troligtvis uppskattar lite extra!',
  settingsSaved: 'Inställningar Sparade'
}