import styled from 'styled-components';

import * as Animations from '../../../../../assets/style/Animations.style';

export const ContactWrapper = styled.div`
    font-size: 14px;
    color: #fff;
    width: 100%;
    margin-top: 16px;
    cursor: pointer;
    
    ${() => Animations.animateLinkOnHover}
`;

export const ContactIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 16px;
`;

export const ContactSection = styled.div`
    margin: 12px 0 48px;
`;