import React from 'react';

import check from '../../../../../assets/images/singlepage/check.png';
import useIsSwedish from '../../../../../library/hooks/useIsSwedish';
import {SinglePagePricingGroupsSingleTagsWrapper, SinglePagePricingGroupsSingleTagsWrapperFeature} from '../style';



const SinglePagePricingGroupsSingleTags = ({features}) => {
  return (
    <>
      {features && features.length > 0 &&
        <SinglePagePricingGroupsSingleTagsWrapper>
          {features.map(entry => (
            <SinglePagePricingGroupsSingleTagsWrapperFeature key={`${entry.feature_offer_id}-${entry.tag_id}-${entry.id}`}>
              <img src={check} alt={entry.tag_name_en} /> 
              <div>{useIsSwedish() ? entry.tag_name_se : entry.tag_name_en}</div>
            </SinglePagePricingGroupsSingleTagsWrapperFeature>
          ))}
        </SinglePagePricingGroupsSingleTagsWrapper>
      }
    </>
  )
}


export default SinglePagePricingGroupsSingleTags;
