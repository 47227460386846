export const userSettings = {
  editProfile: 'Edit profile',
  changePassword: 'Change password',
  userQuestionnaire: 'User questionnaire',
  unsavedChanges: 'You have unsaved changes',
  saveSettings: 'Save settings',
  email: 'Email',
  phoneNumber: 'Phone number',
  firstName: 'First name',
  lastName: 'Last name',
  address: 'Address',
  city: 'City',
  zipCode: 'Zip code',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  howUsuallyStay: 'How do you usually stay while camping?',
  howManyPeople: 'How many people are normally camping together in your family?',
  whenDoYouCamping: 'When do you normally go camping?',
  howManyWeeks: 'How many weeks do you usually camp a year?',
  whatIsImportant: 'What is important for you on the campsite? (select max 5)',
  whereToYouPrefer: 'Where to you prefer to stay close to? (select max 2)',
  'type.vehicle': 'In vehicle',
  'type.tent': 'In tent',
  'type.cabin': 'In cabin',
  userQuestionnaireDesc: `Everyone likes different things, what do you appreciate about your holiday?
      By creating your and your family's own camping profile here at HPYCamper, we will help you sort out the campsites 
      that you probably appreciate a little extra!`,
  settingsSaved: 'Settings saved'
}