import '@adyen/adyen-web/dist/adyen.css';

import React, {useContext, useEffect, useState} from 'react';

import {faSpinner} from '@fortawesome/fontawesome-free-solid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Input, Switch} from 'antd';
import Modal from 'react-bootstrap/Modal';
import {withTranslation} from 'react-i18next';

import bad from '../../../../../assets/images/singlepage/bad.png';
import good from '../../../../../assets/images/singlepage/good.png';
import ModalDatePicker from '../../../../../components/Modals/ModalDatePicker';
import Button from '../../../../../components/UI/Button/Button';
import CurrencySign from '../../../../../components/UI/CurrencySign';
import GuestAmountPicker from '../../../../../components/UI/GuestAmountPicker/GuestAmountPicker';
import PaymentBox from '../../../../../components/UI/PaymentBox';
import {LoadingWrapper} from '../../../../../components/UI/LoadingComponent/LoadingComponent';
import {CheckboxGroupWithState} from '../../../../../components/UI/CheckboxGroup/CheckboxGroupWithState';
import {AuthContext} from '../../../../../context/AuthProvider';
import {ProfileContext} from '../../../../../context/ProfileProvider';
import useIsSwedish from '../../../../../library/hooks/useIsSwedish';
import {
  ReservationModalSpacer,
  ReservationModalPaymentTotal,
  ReservationModalFormRow,
  SinglePagePricingGroupsSingleForm,
  SinglePagePricingGroupsSingleTitleWrapper,
  SinglePagePricingGroupsSingleModalTop,
  SinglePagePricingGroupsSingleImage,
  SinglePagePricingGroupsSingleTitle,
  SinglePagePricingGroupsSingleDescription,
  SinglePagePricingGroupsSingleFormDateErrorNot,
  SinglePagePricingGroupsSingleFormDateOkNot,
  ReserveModalCheckboxLabelMainText,
  ReserveModalCheckboxLabelSubText,
} from '../style';

const URL = Object.freeze({
  RESERVATION_CHECK: '/reservation/total-price-check',
  RESERVATION_SAVE: '/reservation/save',
});

const SinglePageReserveModal = ({
  t,
  name,
  single,
  slug,
  mainImage,
  isModalShowing,
  setIsModalShowing,
  dates,
  onPaymentCompleted = () => null,
}) => {
  const {TextArea} = Input;
  const [form] = Form.useForm();
  const {pricing_group_description, pricing_group_id} = single;
  
  const {profile} = useContext(ProfileContext);
  const {APIProvider} = useContext(AuthContext);

  const [featuresChecked, setFeaturesChecked] = useState([]);
  const [datesChecked, setDatesChecked] = useState({checkIn: null, checkOut: null});
  const [reserveData, setReserveData] = useState(null);
  const [reserveDataError, setReserveDataError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTotalPrice, setIsLoadingTotalPrice] = useState(false);

  const [isReservationAvailable, setIsReservationAvailable] = useState(null);
  const [reservationTotalPrice, setReservationTotalPrice] = useState(null);
  const [reservationCurrency, setReservationCurrency] = useState(null);
  const [guestAmount, setGuestAmount] = useState({adults: 0, children: 0});

  useEffect(() => {
    setGuestAmount({adults: profile?.adults || 0, children: profile?.children || 0});
  }, [profile])

  useEffect(() => checkReservation(), [datesChecked, featuresChecked]);

  const checkReservation = () => {
    const features = featuresChecked;
    const check_in = datesChecked.checkIn;
    const check_out = datesChecked.checkOut;

    form.setFieldsValue({check_in});
    form.setFieldsValue({check_out});
    form.setFieldsValue({features});

    if (check_in && check_out) {
      setIsLoadingTotalPrice(true);
      APIProvider.makeRequest(URL.RESERVATION_CHECK, {
        slug, check_in, check_out, pricing_group_id, features
      }, 'POST').then(response => {
        if (response && (response.error || response.pricing_group_id)) {
          setIsReservationAvailable(false);
          setReservationTotalPrice(null);
          setReservationCurrency(null);
        }
        else if (response && (!response.pricing_group_id)) {
          setIsReservationAvailable(true);
          setReservationTotalPrice(response.price.toString());
          setReservationCurrency(response.currency);
        }
        else {
          setReservationTotalPrice(null);
          setReservationCurrency(null);
        }
        setIsLoadingTotalPrice(false);
      });
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const response = await APIProvider.makeRequest(URL.RESERVATION_SAVE, values, 'POST');
    if (response.id) {
      setReserveData({...response, payload: values});
      setReserveDataError(null);
    }
    else {
      // Once API response is {'pricing_group_id': 'no_spots_available'}
      setReserveDataError(response.error || response.pricing_group_id  || 'ReserveError');
    }
    setIsLoading(false);
  };

  const featuresOptionsParsed = single.features
    .filter(entry => entry.price)
    .map(entry => ({
      value: entry.feature_offer_id, 
      label: (<>
        <ReserveModalCheckboxLabelMainText>
          {useIsSwedish() ? entry.tag_name_se : entry.tag_name_en }
        </ReserveModalCheckboxLabelMainText>
        <ReserveModalCheckboxLabelSubText>
        +<CurrencySign isoCode={entry.currency} amount={entry.price} />
        </ReserveModalCheckboxLabelSubText>
      </>),
      isChecked: entry.isChecked
    }));

  return (
    <Modal
      show={isModalShowing}
      onHide={() => setIsModalShowing(false)}
      dialogClassName="modal-90w modal-reserve"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{t('singlePage.Reserve')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SinglePagePricingGroupsSingleModalTop>
          <SinglePagePricingGroupsSingleImage imageSrc={mainImage} />
          <SinglePagePricingGroupsSingleTitleWrapper>
            <SinglePagePricingGroupsSingleTitle>{name}</SinglePagePricingGroupsSingleTitle>
            <SinglePagePricingGroupsSingleDescription >{pricing_group_description.se || pricing_group_description.en}</SinglePagePricingGroupsSingleDescription>
          </SinglePagePricingGroupsSingleTitleWrapper>
        </SinglePagePricingGroupsSingleModalTop>

        <SinglePagePricingGroupsSingleForm>
          <Form
            form={form}
            name="reservation"
            initialValues={{
              slug: slug,
              first_name: profile?.first_name || '',
              last_name: profile?.last_name || '',
              adults: profile?.adults || 0,
              children: profile?.children || 0,
              zip_code: profile?.zip_code || '',
              email: profile?.email || '',
              v_make: profile?.v_make || '',
              v_model: profile?.v_model || '',
              v_year: profile?.v_year || '',
              v_reg_id: profile?.v_reg_id || '',
              additional_info: '',
              pets: profile?.pets || 0,
              pricing_group_id: pricing_group_id || '',
              features: []
            }}
            onFinish={onFinish}
          >
            <ModalDatePicker
              onDateChange={(v) => setDatesChecked({checkIn: v.setStartDate, checkOut: v.setEndDate})}
              dates={dates}
              isDisabled={!!reserveData} />
            {!reserveData && <>
              {isReservationAvailable === false &&
                <SinglePagePricingGroupsSingleFormDateErrorNot>
                  <img src={bad} alt="check-bad" />
                  {t('singlePage.ErrorDateNot')}
                </SinglePagePricingGroupsSingleFormDateErrorNot>
              }
              {isReservationAvailable === true &&
                <SinglePagePricingGroupsSingleFormDateOkNot>
                  <img src={good} alt="check-good" />
                  {t('singlePage.OkDateNot')}
                </SinglePagePricingGroupsSingleFormDateOkNot>
              }
            </>}

            <div className="d-none">
              <Form.Item name="slug"><Input type="hidden" /></Form.Item>
              <Form.Item name="check_in"><Input type="hidden" /></Form.Item>
              <Form.Item name="check_out"><Input type="hidden" /></Form.Item>
              <Form.Item name="adults"><Input type="hidden" /></Form.Item>
              <Form.Item name="children"><Input type="hidden" /></Form.Item>
              <Form.Item name="pricing_group_id" ><Input type="hidden" /></Form.Item>
              <Form.Item label={t('singlePage.Make')} name="v_make"><Input type="hidden" /></Form.Item>
              <Form.Item label={t('singlePage.Model')} name="v_model"><Input type="hidden" /></Form.Item>
              <Form.Item label={t('singlePage.YearOfProd')} name="v_year"><Input type="hidden" /></Form.Item>
              <Form.Item label={t('singlePage.RegistrationId')} name="v_reg_id"><Input type="hidden" /></Form.Item>
              <Form.Item label={t('singlePage.Pets')} name="pets" valuePropName="checked"><Switch /></Form.Item>
            </div>

            <ReservationModalSpacer />

            <div className="col-12">
              <ReservationModalFormRow>
                <Form.Item
                  label={t('singlePage.FirstName')}
                  name="first_name"
                  rules={[{required: true, message: t('singlePage.ThisFieldIsRequired')}]}
                >
                  {reserveData ? reserveData.payload.first_name : (<Input />)}
                </Form.Item>
                <Form.Item
                  label={t('singlePage.LastName')}
                  name="last_name"
                  rules={[{required: true, message: t('singlePage.ThisFieldIsRequired')}]}
                >
                  {reserveData ? reserveData.payload.last_name : (<Input />)}
                </Form.Item>
              </ReservationModalFormRow>

              <ReservationModalFormRow>
                <Form.Item
                  label={t('singlePage.Email')}
                  name="email"
                  rules={[{required: true, message: t('singlePage.ThisFieldIsRequired')}]}
                >
                  {reserveData ? reserveData.payload.email : (<Input />)}
                </Form.Item>
                <Form.Item
                  label={t('singlePage.ZipCode')}
                  name="zip_code"
                  rules={[{required: true, message: t('singlePage.ThisFieldIsRequired')}]}
                >
                  {reserveData ? reserveData.payload.zip_code : (<Input />)}
                </Form.Item>
              </ReservationModalFormRow>
            </div>

            <ReservationModalSpacer />

            <GuestAmountPicker
              guestAmount={guestAmount}
              setGuestAmount={setGuestAmount}
              isDisabled={!!reserveData}
              showInRow
            />

            {featuresOptionsParsed.length > 0 &&
              <div className="col-12 additional_features">
                <div className="row">
                  <Form.Item className="col-lg-6 col-12" label={t('singlePage.AdditionalFeatures')} name="features">
                    <CheckboxGroupWithState 
                      isDisabled={!!reserveData}
                      items={featuresOptionsParsed} 
                      onStateChange={(v) => setFeaturesChecked(Array.from(v))} />
                  </Form.Item>
                </div>
              </div>
            }

            <ReservationModalSpacer />

            <div className="col-12">
              <Form.Item label={t('singlePage.AdditionalComment')} name="additional_info">
                {reserveData
                  ? (reserveData.additional_info || reserveData.details || '-------------')
                  : (<TextArea className="w-100" rows={4} />)
                }
              </Form.Item>
            </div>

            {reservationTotalPrice &&
                <div className="col-12 additional_comment">
                  <LoadingWrapper isLoading={isLoadingTotalPrice} height="5rem">
                    <>
                      <div className="row">
                        <ReservationModalPaymentTotal>
                          {t('singlePage.MakePaymentForTotal')}:
                          {' '}<CurrencySign amount={reservationTotalPrice} isoCode={reservationCurrency} />
                        </ReservationModalPaymentTotal>
                      </div>
                      {!reserveData &&
                        <>
                          {isLoading
                            ? <Button disabled><FontAwesomeIcon icon={faSpinner} spin /></Button>
                            : <Button text={t('singlePage.SendRequest')} htmlType="submit" />
                          }
                        </>
                      }
                    </>
                  </LoadingWrapper>
                </div>
            }
          </Form>

          {!reservationTotalPrice &&
              <div className="col-12 additional_comment">
                <div className="row">
                  <span className="col-12">{t('singlePage.FulfillDates')}</span>
                </div>
              </div>

          }

          {reserveData &&
              <div className="col-12">
                <div className="row">
                  <PaymentBox
                    reservationSub={reserveData.reservation_sub}
                    onPaymentCompleted={onPaymentCompleted}
                  />
                </div>
              </div>
          }

          {reserveDataError &&
              <SinglePagePricingGroupsSingleFormDateErrorNot>
                <img src={bad} alt="check-bad" /> {t(`singlePage.${reserveDataError}`)}
              </SinglePagePricingGroupsSingleFormDateErrorNot>
          }
        </SinglePagePricingGroupsSingleForm>
      </Modal.Body>
    </Modal>
  )
}

export default withTranslation()(SinglePageReserveModal);
