export const aboutUs = {
  aboutUs: 'About us',
  title: 'All of Swedens campsites, in one place!',
  contentPart1: `HPY Camper was founded in 2018 with the vision to make it easier to plan and reserve a camping
    vacation in Sweden.`,
  contentPart2: `HPY Camper is Sweden booking site for campsites. Here you will find information about almost 1400
    campsites, which makes it easy for you to find destinations you did not know before,
    and campsites you didn't even know existed.`,
  contentPart3: `Whether you want to live in your own camping vehicle, tent or rent a cabin, you will find the options
    with us, either through our website or our app which you can download from your app store.`,
  contentPart4: `Take the opportunity to register and answer some questions about how you usually camp, and we will,
    based on your answers, suggest new destinations and campsites for you and your family.`,
  contentPart5: 'I\'m a HPY Camper! :)'
}