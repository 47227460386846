import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

const NavbarWrapper = styled.nav`
  display: none;
  padding: 0 125px 0 125px;
  height: 98px;
  align-items: center;
  background-color: ${themeGet('backgroundColor.white')};
  justify-content: center;
  
  @media only screen and (min-width: 991px) {
    display: flex;
  }

  &.is_transparent {
    background: transparent;
    position: fixed;
    ${'' /* width: 100%; */}
    left: 0;
    top: 0;
    z-index: 999;
    border-bottom: 0;
    background-color: transparent;
    transition: all 0.3s ease-out;

    .ant-menu {
      li {
        &.ant-menu-item-selected {
          background-color: transparent;
        }
        a {
          color: ${themeGet('textColor.white', '#0E263E')};
          &.active {
            border-bottom: 0;
          }
        }
      }
      &.dropdown-menu {
        li {
          a {
            color: ${themeGet('textColor.primaryDark', '#0E263E')};
          }
        }
      }
    }
  }
`;

export const LogoArea = styled.div`
  align-items: center;
  min-width: 170px;
  padding-right: 112px;
`;

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  margin-left: 46px;

  .avatar-dropdown {
    position: relative;

    .dropdown-handler {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .dropdown-menu {
      min-width: 180px;
      border-right: 0;
      position: absolute;
      right: 0;
      top: 45px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        height: auto;
        padding: 0;
        transition: color 0.2s ease-in-out;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }
        a {
          display: block;
          padding: 8px 16px;
          color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet('textColor.primary', '#008489')};
          }
          &.active {
            font-weight: 700;
            color: ${themeGet('textColor.primary', '#008489')};
          }
        }
        button {
          padding: 0;
          border: 0;
          cursor: pointer;
          padding: 8px 16px;
          width: 100%;
          text-align: left;
          background-color: transparent;
          transition: color 0.2s ease-in-out;
          &:hover {
            color: ${themeGet('textColor.primary', '#008489')};
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 40px;
    li {
      margin: 0;
      padding: 0;
      margin: 0 16px 0 16px;
      margin-bottom: 0 !important;
      color: ${themeGet('textColor.primaryDark', '#2C2C2C')};
      top: -0.5px;
      height: 24px;
      transition: color 0.2s ease-in-out;
      
      &:first-child {
        margin-left: 0;
      }
      
      &:last-child {
        margin-right: 0;
      }
      
      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        background-color: transparent;
      }

      a {
        padding: 0px;
        color: ${themeGet('textColor.inactive', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        font-weight: normal;
        font-family: 'Inter';
        font-style: normal;
        font-size: 14px;
        line-height: 24px;

        display: inline-block;
        vertical-align: top;
        position: relative;
        text-align: center;
        
        &:hover {
          color: ${themeGet('textColor.primary', '#008489')};
        }
        &.active {
          font-weight: 500;
          color: ${themeGet('textColor.primaryDark', '#0C0C0C')};
          ${'' /* border-bottom: 3px solid ${themeGet('textColor.primary', '#008489')}; */}
          
        }
        &.active:before {
          transform: translateX(-50%);
          border-radius: 100%;
          position: absolute;
          background: ${themeGet('textColor.primary', '#008489')};
          bottom: -10px;
          height: 5px;
          content: '';
          width: 5px;
          left: 50%;
        }
      }
    }
  }
`;

export const LangWrapper = styled.div`
  padding-left: 20px;
  ${'' /* display: flex; */}
  img {
    width: 32px;
    height: 32px;
  }
`;


export const AuthWrapper = styled.div`
  padding-left: 112px;
  min-width: 170px;
  
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    li {
      margin: 0;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('textColor.inactive', '#2C2C2C')};
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      transition: color 0.2s ease-in-out;
      &.ant-menu-item-selected {
        background-color: transparent;
      }

      a {
        display: flex;
        padding: 0 10px 0 10px;
        align-items: center;
        justify-content: center;
        min-height: 18px;
        color: ${themeGet('textColor.inactive', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('textColor.primary', '#008489')};
          opacity: 0.9;
        }
      }

      &:last-child {
          border-left-width: 1px;
          border-left-color: ${themeGet('textColor.info', '#000000')}; 
          border-left-style: solid;
      }
    }
  }
`;

export default NavbarWrapper;
