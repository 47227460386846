import {useState, useContext, useEffect} from 'react';

import styled from 'styled-components';

import LikeFullIcon from '../../assets/images/icons/hearth-full.svg';
import LikeIcon from '../../assets/images/icons/hearth.svg';
import {animateImageIconOnHover} from '../../assets/style/Animations.style';
import {FavoritesContext} from '../../context/FavoritesProvider';


const FavoriteButtonWrapper = styled.div`
    ${animateImageIconOnHover}
    cursor: ${props => props.isWorking ? 'wait' : 'pointer'};
    filter: grayscale(${props => props.isWorking ? 1 : 0});
    transition: all 0.5s ease-in-out;

    &:active {
      opacity: .2;
    }
`;

const FavoriteButton = ({slug}) => {
  const favoritesProvider = useContext(FavoritesContext);
  const favoritesInvalidation = favoritesProvider.invalidation;
  const isLoggedIn = favoritesProvider.authIsLoggedIn;

  const [isWorking, setIsWorking] = useState(false);
  const [isLiked, setIsLiked] = useState(
    favoritesProvider.isFavorite(slug)
  );

  const onClickHandler = async () => {
    setIsWorking(true);
    if (isLiked) {
      setIsLiked(false);
      await favoritesProvider.unsetFavorite(slug)
    } else {
      setIsLiked(true);
      await favoritesProvider.setFavorite(slug);
    }
    setIsWorking(false);
  }

  useEffect(() => setIsLiked(favoritesProvider.isFavorite(slug)), [favoritesInvalidation]);
    
  return (<>
    {isLoggedIn &&
      <FavoriteButtonWrapper onClick={() => !isWorking && onClickHandler()} isWorking={isWorking}>
        <img src={isLiked || isWorking ? LikeFullIcon : LikeIcon} alt="Hearth Icon"/>
      </FavoriteButtonWrapper>
    } 
  </>);
}

export default FavoriteButton;
