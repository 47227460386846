import React from 'react';

import CamperIcon from '../../../../../assets/images/icons/camper.svg';
import HomeIcon from '../../../../../assets/images/icons/home.svg';
import TentIcon from '../../../../../assets/images/icons/tent.svg';

import * as Style from './style';

const SPOT_TYPES_MAP = Object.freeze({
  tents: {icon: TentIcon, translation: 'singlePage.TentSpots'},
  caravans: {icon: HomeIcon, translation: 'singlePage.TrailerSpots'},
  cabins: {icon: CamperIcon, translation: 'singlePage.Cabins'},
});

const SpotTypeIcon = ({
  spotType,
  value,
}) => {
  const currentType = SPOT_TYPES_MAP[spotType];
  const isDisabled = Number(value) <= 0;

  return (
    <Style.Wrapper>
      {currentType.icon &&
        <Style.Icon disabled={isDisabled}>
          <img src={currentType.icon} alt={spotType} />
        </Style.Icon>
      }
    </Style.Wrapper>
  )
}

export default SpotTypeIcon;
