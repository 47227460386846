const defaultTheme = {
  backgroundColor: {
    'white': '#FFFFFF',
    'primary': '#FF8E00',
    'primaryLight': '#FFF2E0',
    'primaryWithOpacity': 'rgba(255, 142, 0, 0.2)',
    'secondary': '#BCC5D2',
    'lightBlue': '#F3F7FA',
    'lightBlueBox': '#CDDAE1',
    'ratingBox': '#FFD166',
    'dropdownHover': '#F7F7F7',
    'yellow': '#FFD166'
  },
  textColor: {
    'primary': '#FF8E00',
    'primaryDark': '#0E263E',
    'primaryLight': '#73818F',
    'secondary': '#6E7D8B',
    'white': '#FFFFFF',
    'sectionContent': '#495867',
    'success': '#5C9D28',
    'black': '#000000',
    'inactive': '#707376',
    'info': '#777777'
  },
  boxShadow: {
    'small': '0px 8px 32px rgba(19, 10, 46, 0.07), 0px 1px 3px rgba(19, 10, 46, 0.13)',
    'focus': '0px 0px 16px rgba(205, 218, 225, 1)',
  },
  lineHeight: {
    'default': '140%',
  },
  borderRadius: {
    'small': '4px',
  },
  borderLines: {
    'light': '#EBEBEB',
    'wrapper': '#E4EDF3',
    'inputBorder': '#CDDAE1',
    'pricing': '#E6E6E6'
  },
  breakpoint: {
    'medium': '768px',
    'large': '991px',
    'x-large': '1441px'
  },
  containerWidth: {
    'large': '1104px',
  },
  status: {
    text: {
      'info': '#145EB6',
      'success': '#5C9D28',
      'error': '#F44021',
      'disabled': '#6E7D8B',
    },
    background: {
      'info': 'rgba(20, 94, 182, 0.1)',
      'error': 'rgba(244, 64, 33, 0.1)',
      'success': 'rgba(92, 157, 40, 0.1)',
    },
  },
  globalPaddingH: {
    'mobile': '16px',
    'medium': '110px',
    'desktop': '204px'
  },
  mapPinColor: {
    'primary': 'rgba(255, 142, 0, 1)',
    'secondary': 'rgba(255, 142, 0, 0.6)',
  }
};

export default defaultTheme;
