import {withTranslation} from 'react-i18next';

import minusIcon from '../../../assets/images/header/minus.svg';
import plusIcon from '../../../assets/images/header/plus.svg';

import {
  ChangeAmount,
  ChangeAmountIcon,
  ChangeAmountPanel,
  GuestTypeRow,
  GuestTypeContainer
} from './GuestAmountPicker.style';


const GuestAmountPicker = ({
  t,
  guestAmount,
  setGuestAmount,
  showInRow = false,
  isDisabled = false,
}) => {

  const types = Object.keys(guestAmount) || ['adults', 'children'];

  const addGuest = (type) => {
    const newGuestAmount = {...guestAmount, [type]: guestAmount[type] + 1};
    if (guestAmount[type] !== 10) {
      setGuestAmount(newGuestAmount);
    }
  }

  const removeGuest = (type) => {
    const newGuestAmount = {...guestAmount, [type]: guestAmount[type] - 1};
    if (guestAmount[type] !== 0) {
      setGuestAmount(newGuestAmount);
    }
  }

  return (
    <GuestTypeContainer
      showInRow={showInRow}
      onClick={evt => evt.preventDefault()}
    >
      {types.map(type => (
        <GuestTypeRow key={`guest-amount-${type}`} className={showInRow ? 'in-row' : ''}>
          <div>
            {t(`searchForm.${type}`)}
          </div>
          <ChangeAmountPanel>
            {!isDisabled &&
              <ChangeAmountIcon
                onClick={() => removeGuest(type)}
                className={`${guestAmount[type] === 0 ? 'disabled' : ''}`}
              >
                <img src={minusIcon} alt={`Remove ${type} guest`} />
              </ChangeAmountIcon>
            }
            <ChangeAmount>{guestAmount[type]}</ChangeAmount>
            {!isDisabled &&
              <ChangeAmountIcon
                onClick={() => addGuest(type)}
                className={`${guestAmount[type] === 10 ? 'disabled' : ''}`}
              >
                <img src={plusIcon} alt={`Add ${type} guest`} />
              </ChangeAmountIcon>
            }
          </ChangeAmountPanel>
        </GuestTypeRow>
      ))}
    </GuestTypeContainer>
  )
}

export default withTranslation()(GuestAmountPicker);
