import {withTranslation} from 'react-i18next';

import shareButton from '../../assets/images/share.svg';
import useNotification from '../../library/hooks/useNotification';

import * as Style from './style';

const ShareButton = ({slug, t}) => {
  const urlToCopy = `${location.host}/campsite/${slug}`;
  const notification = useNotification();
  
  const onButtonClick = () => {
    navigator.clipboard.writeText(urlToCopy).then(() => {
      notification.show(t('shareButton.linkCopied'));
    });
  }

  return (
    <Style.ShareButton
      src={shareButton}
      alt="Share icon"
      title={t('shareButton.copyLinkToClipboard')}
      onClick={onButtonClick}
    />
  )
}

export default withTranslation()(ShareButton);