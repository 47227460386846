import React, {useState, useRef} from 'react';

import Portal from '../Portal/Portal';

import {useOnClickOutside} from './useOnClickOutside';
import {Wrapper, Container} from './ViewWithPopup.style';

export default function ViewWithPopup({
  view,
  popup,
  noView = false,
  style,
  className,
  noPadding = false,
  noMargin = false,
  forceShowPopupEventType
}) {
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowPopup(false));
  const addAllClasses = ['view_with__popup'];

  if (className) {
    addAllClasses.push(className);
  }

  const FORCE_SHOW_POPUP_EVENT_PROP = {
    [forceShowPopupEventType]: () => forceShowPopup()
  }

  const getForceShowPopupEventProp = () => {
    return forceShowPopupEventType ? {...FORCE_SHOW_POPUP_EVENT_PROP} : {}
  }

  const forceShowPopup = () => {
    if (!showPopup) {
      setShowPopup(true);
    }
  }

  const onPopupHandlerKeyPress = (event) => {
    if (event.which === '13') {
      setShowPopup(!showPopup);
    }
  }

  return (
    <Wrapper
      className={`${addAllClasses.join(' ')} ${showPopup ? 'active' : ''}`}
      ref={ref}
    >
      {view && noView && (
        <div
          className="popup_handler"
          onClick={() => setShowPopup(!showPopup)}
          onKeyPress={onPopupHandlerKeyPress}
          role="button"
          tabIndex="0"
          {...getForceShowPopupEventProp()}
        >
          {view}
        </div>
      )}
      <Container
        className="popup_container"
        showPopup={showPopup}
        noPadding={noPadding}
        noMargin={noMargin}
        onClick={() => setShowPopup(true)}
        style={style}
      >
        {view && !noView && view}
        {showPopup && (
          <div id="popup">
            <Portal rendererId="popup">{popup && popup}</Portal>
          </div>
        )}
      </Container>
    </Wrapper>
  );
}
