import React from 'react';

import {Menu} from 'antd';
import i18next from 'i18next';
import {withTranslation}  from 'react-i18next';

import flagEnglish from '../../../../assets/images/flags/english.svg';
import flagSwedish from '../../../../assets/images/flags/swedish.svg';
import useIsSwedish from '../../../../library/hooks/useIsSwedish';

import {LangMenuFlag} from './LangMenu.style';

const LangMenu = ({i18n}) => {
  const isSwedish = useIsSwedish(i18n.language);

  const handleClick = (e) => {
    i18next.changeLanguage(e.key);
  }

  return (
    <Menu onClick={handleClick}>
      { isSwedish &&
          <Menu.Item key="en">
            <LangMenuFlag src={flagEnglish} alt="English" />
          </Menu.Item>
      }
      { !isSwedish &&
          <Menu.Item key="se">
            <LangMenuFlag src={flagSwedish} alt="Swedish" />
          </Menu.Item>
      }
    </Menu>
  )
};

export default withTranslation()(LangMenu);
