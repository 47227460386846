export default Object.freeze(
  {
    USD: {
      name: 'US Dollar',
      symbol: '$',
      symbolNative: '$'
    },
    CAD: {
      name: 'Canadian Dollar',
      symbol: 'CA$',
      symbolNative: '$'
    },
    EUR: {
      name: 'Euro',
      symbol: '€',
      symbolNative: '€',
      symbolBeforeValue: true
    },
    AED: {
      name: 'United Arab Emirates Dirham',
      symbol: 'AED',
      symbolNative: 'د.إ.\u200f'
    },
    AFN: {
      name: 'Afghan Afghani',
      symbol: 'Af',
      symbolNative: '؋'
    },
    ALL: {
      name: 'Albanian Lek',
      symbol: 'ALL',
      symbolNative: 'Lek'
    },
    AMD: {
      name: 'Armenian Dram',
      symbol: 'AMD',
      symbolNative: 'դր.'
    },
    ARS: {
      name: 'Argentine Peso',
      symbol: 'AR$',
      symbolNative: '$'
    },
    AUD: {
      name: 'Australian Dollar',
      symbol: 'AU$',
      symbolNative: '$'
    },
    AZN: {
      name: 'Azerbaijani Manat',
      symbol: 'man.',
      symbolNative: 'ман.'
    },
    BAM: {
      name: 'Bosnia-Herzegovina Convertible Mark',
      symbol: 'KM',
      symbolNative: 'KM'
    },
    BDT: {
      name: 'Bangladeshi Taka',
      symbol: 'Tk',
      symbolNative: '৳'
    },
    BGN: {
      name: 'Bulgarian Lev',
      symbol: 'BGN',
      symbolNative: 'лв.'
    },
    BHD: {
      name: 'Bahraini Dinar',
      symbol: 'BD',
      symbolNative: 'د.ب.\u200f'
    },
    BIF: {
      name: 'Burundian Franc',
      symbol: 'FBu',
      symbolNative: 'FBu'
    },
    BND: {
      name: 'Brunei Dollar',
      symbol: 'BN$',
      symbolNative: '$'
    },
    BOB: {
      name: 'Bolivian Boliviano',
      symbol: 'Bs',
      symbolNative: 'Bs'
    },
    BRL: {
      name: 'Brazilian Real',
      symbol: 'R$',
      symbolNative: 'R$'
    },
    BWP: {
      name: 'Botswanan Pula',
      symbol: 'BWP',
      symbolNative: 'P'
    },
    BYN: {
      name: 'Belarusian Ruble',
      symbol: 'Br',
      symbolNative: 'руб.'
    },
    BZD: {
      name: 'Belize Dollar',
      symbol: 'BZ$',
      symbolNative: '$'
    },
    CDF: {
      name: 'Congolese Franc',
      symbol: 'CDF',
      symbolNative: 'FrCD'
    },
    CHF: {
      name: 'Swiss Franc',
      symbol: 'CHF',
      symbolNative: 'CHF'
    },
    CLP: {
      name: 'Chilean Peso',
      symbol: 'CL$',
      symbolNative: '$'
    },
    CNY: {
      name: 'Chinese Yuan',
      symbol: 'CN¥',
      symbolNative: 'CN¥'
    },
    COP: {
      name: 'Colombian Peso',
      symbol: 'CO$',
      symbolNative: '$'
    },
    CRC: {
      name: 'Costa Rican Colón',
      symbol: '₡',
      symbolNative: '₡'
    },
    CVE: {
      name: 'Cape Verdean Escudo',
      symbol: 'CV$',
      symbolNative: 'CV$'
    },
    CZK: {
      name: 'Czech Republic Koruna',
      symbol: 'Kč',
      symbolNative: 'Kč'
    },
    DJF: {
      name: 'Djiboutian Franc',
      symbol: 'Fdj',
      symbolNative: 'Fdj'
    },
    DKK: {
      name: 'Danish Krone',
      symbol: 'Dkr',
      symbolNative: 'kr'
    },
    DOP: {
      name: 'Dominican Peso',
      symbol: 'RD$',
      symbolNative: 'RD$'
    },
    DZD: {
      name: 'Algerian Dinar',
      symbol: 'DA',
      symbolNative: 'د.ج.\u200f'
    },
    EEK: {
      name: 'Estonian Kroon',
      symbol: 'Ekr',
      symbolNative: 'kr'
    },
    EGP: {
      name: 'Egyptian Pound',
      symbol: 'EGP',
      symbolNative: 'ج.م.\u200f'
    },
    ERN: {
      name: 'Eritrean Nakfa',
      symbol: 'Nfk',
      symbolNative: 'Nfk'
    },
    ETB: {
      name: 'Ethiopian Birr',
      symbol: 'Br',
      symbolNative: 'Br'
    },
    GBP: {
      name: 'British Pound Sterling',
      symbol: '£',
      symbolNative: '£'
    },
    GEL: {
      name: 'Georgian Lari',
      symbol: 'GEL',
      symbolNative: 'GEL'
    },
    GHS: {
      name: 'Ghanaian Cedi',
      symbol: 'GH₵',
      symbolNative: 'GH₵'
    },
    GNF: {
      name: 'Guinean Franc',
      symbol: 'FG',
      symbolNative: 'FG'
    },
    GTQ: {
      name: 'Guatemalan Quetzal',
      symbol: 'GTQ',
      symbolNative: 'Q'
    },
    HKD: {
      name: 'Hong Kong Dollar',
      symbol: 'HK$',
      symbolNative: '$'
    },
    HNL: {
      name: 'Honduran Lempira',
      symbol: 'HNL',
      symbolNative: 'L'
    },
    HRK: {
      name: 'Croatian Kuna',
      symbol: 'kn',
      symbolNative: 'kn'
    },
    HUF: {
      name: 'Hungarian Forint',
      symbol: 'Ft',
      symbolNative: 'Ft'
    },
    IDR: {
      name: 'Indonesian Rupiah',
      symbol: 'Rp',
      symbolNative: 'Rp'
    },
    ILS: {
      name: 'Israeli New Sheqel',
      symbol: '₪',
      symbolNative: '₪'
    },
    INR: {
      name: 'Indian Rupee',
      symbol: 'Rs',
      symbolNative: 'টকা'
    },
    IQD: {
      name: 'Iraqi Dinar',
      symbol: 'IQD',
      symbolNative: 'د.ع.\u200f'
    },
    IRR: {
      name: 'Iranian Rial',
      symbol: 'IRR',
      symbolNative: '﷼'
    },
    ISK: {
      name: 'Icelandic Króna',
      symbol: 'Ikr',
      symbolNative: 'kr'
    },
    JMD: {
      name: 'Jamaican Dollar',
      symbol: 'J$',
      symbolNative: '$'
    },
    JOD: {
      name: 'Jordanian Dinar',
      symbol: 'JD',
      symbolNative: 'د.أ.\u200f'
    },
    JPY: {
      name: 'Japanese Yen',
      symbol: '¥',
      symbolNative: '￥'
    },
    KES: {
      name: 'Kenyan Shilling',
      symbol: 'Ksh',
      symbolNative: 'Ksh'
    },
    KHR: {
      name: 'Cambodian Riel',
      symbol: 'KHR',
      symbolNative: '៛'
    },
    KMF: {
      name: 'Comorian Franc',
      symbol: 'CF',
      symbolNative: 'FC'
    },
    KRW: {
      name: 'South Korean Won',
      symbol: '₩',
      symbolNative: '₩'
    },
    KWD: {
      name: 'Kuwaiti Dinar',
      symbol: 'KD',
      symbolNative: 'د.ك.\u200f'
    },
    KZT: {
      name: 'Kazakhstani Tenge',
      symbol: 'KZT',
      symbolNative: 'тңг.'
    },
    LBP: {
      name: 'Lebanese Pound',
      symbol: 'L.L.',
      symbolNative: 'ل.ل.\u200f'
    },
    LKR: {
      name: 'Sri Lankan Rupee',
      symbol: 'SLRs',
      symbolNative: 'SL Re'
    },
    LTL: {
      name: 'Lithuanian Litas',
      symbol: 'Lt',
      symbolNative: 'Lt'
    },
    LVL: {
      name: 'Latvian Lats',
      symbol: 'Ls',
      symbolNative: 'Ls'
    },
    LYD: {
      name: 'Libyan Dinar',
      symbol: 'LD',
      symbolNative: 'د.ل.\u200f'
    },
    MAD: {
      name: 'Moroccan Dirham',
      symbol: 'MAD',
      symbolNative: 'د.م.\u200f'
    },
    MDL: {
      name: 'Moldovan Leu',
      symbol: 'MDL',
      symbolNative: 'MDL'
    },
    MGA: {
      name: 'Malagasy Ariary',
      symbol: 'MGA',
      symbolNative: 'MGA'
    },
    MKD: {
      name: 'Macedonian Denar',
      symbol: 'MKD',
      symbolNative: 'MKD'
    },
    MMK: {
      name: 'Myanma Kyat',
      symbol: 'MMK',
      symbolNative: 'K'
    },
    MOP: {
      name: 'Macanese Pataca',
      symbol: 'MOP$',
      symbolNative: 'MOP$'
    },
    MUR: {
      name: 'Mauritian Rupee',
      symbol: 'MURs',
      symbolNative: 'MURs'
    },
    MXN: {
      name: 'Mexican Peso',
      symbol: 'MX$',
      symbolNative: '$'
    },
    MYR: {
      name: 'Malaysian Ringgit',
      symbol: 'RM',
      symbolNative: 'RM'
    },
    MZN: {
      name: 'Mozambican Metical',
      symbol: 'MTn',
      symbolNative: 'MTn'
    },
    NAD: {
      name: 'Namibian Dollar',
      symbol: 'N$',
      symbolNative: 'N$'
    },
    NGN: {
      name: 'Nigerian Naira',
      symbol: '₦',
      symbolNative: '₦'
    },
    NIO: {
      name: 'Nicaraguan Córdoba',
      symbol: 'C$',
      symbolNative: 'C$'
    },
    NOK: {
      name: 'Norwegian Krone',
      symbol: 'Nkr',
      symbolNative: 'kr'
    },
    NPR: {
      name: 'Nepalese Rupee',
      symbol: 'NPRs',
      symbolNative: 'नेरू'
    },
    NZD: {
      name: 'New Zealand Dollar',
      symbol: 'NZ$',
      symbolNative: '$'
    },
    OMR: {
      name: 'Omani Rial',
      symbol: 'OMR',
      symbolNative: 'ر.ع.\u200f'
    },
    PAB: {
      name: 'Panamanian Balboa',
      symbol: 'B/.',
      symbolNative: 'B/.'
    },
    PEN: {
      name: 'Peruvian Nuevo Sol',
      symbol: 'S/.',
      symbolNative: 'S/.'
    },
    PHP: {
      name: 'Philippine Peso',
      symbol: '₱',
      symbolNative: '₱'
    },
    PKR: {
      name: 'Pakistani Rupee',
      symbol: 'PKRs',
      symbolNative: '₨'
    },
    PLN: {
      name: 'Polish Zloty',
      symbol: 'zł',
      symbolNative: 'zł'
    },
    PYG: {
      name: 'Paraguayan Guarani',
      symbol: '₲',
      symbolNative: '₲'
    },
    QAR: {
      name: 'Qatari Rial',
      symbol: 'QR',
      symbolNative: 'ر.ق.\u200f'
    },
    RON: {
      name: 'Romanian Leu',
      symbol: 'RON',
      symbolNative: 'RON'
    },
    RSD: {
      name: 'Serbian Dinar',
      symbol: 'din.',
      symbolNative: 'дин.'
    },
    RUB: {
      name: 'Russian Ruble',
      symbol: 'RUB',
      symbolNative: '₽.'
    },
    RWF: {
      name: 'Rwandan Franc',
      symbol: 'RWF',
      symbolNative: 'FR'
    },
    SAR: {
      name: 'Saudi Riyal',
      symbol: 'SR',
      symbolNative: 'ر.س.\u200f'
    },
    SDG: {
      name: 'Sudanese Pound',
      symbol: 'SDG',
      symbolNative: 'SDG'
    },
    SEK: {
      name: 'Swedish Krona',
      symbol: 'SEK',
      symbolNative: 'kr'
    },
    SGD: {
      name: 'Singapore Dollar',
      symbol: 'S$',
      symbolNative: '$'
    },
    SOS: {
      name: 'Somali Shilling',
      symbol: 'Ssh',
      symbolNative: 'Ssh'
    },
    SYP: {
      name: 'Syrian Pound',
      symbol: 'SY£',
      symbolNative: 'ل.س.\u200f'
    },
    THB: {
      name: 'Thai Baht',
      symbol: '฿',
      symbolNative: '฿'
    },
    TND: {
      name: 'Tunisian Dinar',
      symbol: 'DT',
      symbolNative: 'د.ت.\u200f'
    },
    TOP: {
      name: 'Tongan Paʻanga',
      symbol: 'T$',
      symbolNative: 'T$'
    },
    TRY: {
      name: 'Turkish Lira',
      symbol: 'TL',
      symbolNative: 'TL'
    },
    TTD: {
      name: 'Trinidad and Tobago Dollar',
      symbol: 'TT$',
      symbolNative: '$'
    },
    TWD: {
      name: 'New Taiwan Dollar',
      symbol: 'NT$',
      symbolNative: 'NT$'
    },
    TZS: {
      name: 'Tanzanian Shilling',
      symbol: 'TSh',
      symbolNative: 'TSh'
    },
    UAH: {
      name: 'Ukrainian Hryvnia',
      symbol: '₴',
      symbolNative: '₴'
    },
    UGX: {
      name: 'Ugandan Shilling',
      symbol: 'USh',
      symbolNative: 'USh'
    },
    UYU: {
      name: 'Uruguayan Peso',
      symbol: '$U',
      symbolNative: '$'
    },
    UZS: {
      name: 'Uzbekistan Som',
      symbol: 'UZS',
      symbolNative: 'UZS'
    },
    VEF: {
      name: 'Venezuelan Bolívar',
      symbol: 'Bs.F.',
      symbolNative: 'Bs.F.'
    },
    VND: {
      name: 'Vietnamese Dong',
      symbol: '₫',
      symbolNative: '₫'
    },
    XAF: {
      name: 'CFA Franc BEAC',
      symbol: 'FCFA',
      symbolNative: 'FCFA'
    },
    XOF: {
      name: 'CFA Franc BCEAO',
      symbol: 'CFA',
      symbolNative: 'CFA'
    },
    YER: {
      name: 'Yemeni Rial',
      symbol: 'YR',
      symbolNative: 'ر.ي.\u200f'
    },
    ZAR: {
      name: 'South African Rand',
      symbol: 'R',
      symbolNative: 'R'
    },
    ZMK: {
      name: 'Zambian Kwacha',
      symbol: 'ZK',
      symbolNative: 'ZK'
    },
    ZWL: {
      name: 'Zimbabwean Dollar',
      symbol: 'ZWL$',
      symbolNative: 'ZWL$'
    }
  }
);      
