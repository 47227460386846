import {useEffect, useState} from 'react';

import tickIcon from '../../../assets/images/tick.svg';

import {CheckboxWrapper, CheckboxInput, CheckboxLabel} from './Checkbox.style';

export const Checkbox = ({
  value,
  isChecked = false,
  label,
  onCheckStateChange = () => null,
  disuseMargin = false,
  isDisabled = false,
  forceClear = false
}) => {

  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    if (forceClear === true) {
      setChecked(false);
    }
  }, [forceClear]);

  const handleClick = () => {
    if (!isDisabled) {
      const currentCheckState = !checked;
      setChecked(currentCheckState);
      onCheckStateChange(currentCheckState, value);
    }
  }

  return (
    <CheckboxWrapper onClick={handleClick} disuseMargin={disuseMargin}>
      <CheckboxInput className={`${checked ? 'checked' : ''} ${isDisabled ? 'disabled' : ''} `}>
        <img src={tickIcon} alt="Tick icon" />
      </CheckboxInput>
      <CheckboxLabel className={`${checked ? 'checked' : ''} ${isDisabled ? 'disabled' : ''} `}>
        {label}
      </CheckboxLabel>
    </CheckboxWrapper>
  )
}

export default Checkbox;
