export const afa = {
  sent: 'Sent',
  askForAvailabilityDetails: 'Ask for availability details',
  checkInCheckOut: 'Check in - check out',
  spotType: 'Spot type',
  make: 'Make',
  model: 'Model',
  year: 'Year',
  regId: 'Reg. ID',
  adultsChildren: 'Adults/children',
  pets: 'Pets',
  additionalComments: 'Additional comments',
  youHaveNewBookingOffer: 'You have a new booking offer',
  hello: 'Hello',
  hasOfferedYouSpot: 'has offered You a spot',
  to: 'to',
  totalOfferedPrice: 'Total offered price',
  youBookThisSpot: 'You book this spot by paying with your card by clicking on link below',
  youNeedToPay: 'You need to pay within XX hours to complete your booking and lock your offer',
  afaAdded: 'AFA added'
}