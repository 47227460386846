export const searchForm = {
  addGuests: 'Lägg till gäster',
  adults: 'Vuxna',
  children: 'Barn',
  whereYouGoing: 'Vart vill du åka?',
  property: 'Boendetyp',
  properties: 'Boendtyper',
  pickPropertyType: 'Hur vill du bo?',
  search: 'Sök',
  location: 'Destination',
  date: 'Datum',
  propertiesCapitalize: 'Boendetyper',
  guest: 'gäst',
  guests: 'gäster',
  guestsCapitalize: 'Gäster',
  'type.cabin': 'Stuga',
  'type.vehicle': 'Husbil/husvagn',
  'type.tent': 'Tält',
  checkIn: 'Incheckning',
  checkOut: 'Utcheckning',
  hints: {
    name: 'Namn',
    regions: 'Regioner',
    city: 'Städer',
    address: 'Adresser',
  }
}
