export const footer = {
  menu: {
    browseCampsites: 'Campingkarta',
    contact: 'Kontakt',
    aboutUs: 'Om oss',
    faq: 'Frågor och Svar',
    privacyPolice: 'Integritetspolicy',
    termsAndConditions: 'Villkor',
    connectCampsite: 'Anslut din camping',
  },
  copyrights: 'HPY Camper. Alla rättigheter förbehållna',
  forTheCampsiteOwner: 'För dig som driver Camping',
  usefulLinks: 'Snabblänkar',
  description: 'Kontakta oss med dina frågor. HPY Camper är ett registrerat varumärke tillhörande HC Lifestyle & Reservations AB.',
  phone: '010-303 13 00'
}
