import {themeGet} from '@styled-system/theme-get';
import styled from 'styled-components';

import polygonImage from '../../assets/images/search-plan-book-done/polygon.svg';
import tile1Img from '../../assets/images/search-plan-book-done/tile-1.png';
import tile2Img from '../../assets/images/search-plan-book-done/tile-2.png';
import tile3Img from '../../assets/images/search-plan-book-done/tile-3.png';
import tile4Img from '../../assets/images/search-plan-book-done/tile-4.png';
import tile5Img from '../../assets/images/search-plan-book-done/tile-5.png';
import tile6Img from '../../assets/images/search-plan-book-done/tile-6.png';
import waveImageDesktop from '../../assets/images/search-plan-book-done/wave-desktop.svg';
import waveImageMobile from '../../assets/images/search-plan-book-done/wave-mobile.svg';

export const SearchPlanBookDoneWrapper = styled.div`
  padding: 0 ${themeGet('globalPaddingH.mobile')} 48px;
  position: relative;
  margin-top: 32px;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    padding: 100px ${themeGet('globalPaddingH.desktop')} 200px;
    margin-top: 80px;
  }
`;

export const SearchPlanSectionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  color: ${themeGet('textColor.primaryDark')};
  text-align: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  position: relative;
    
  span {
    display: flex;
    align-items: center;
    gap: 10px;
    
    &:not(:last-child) {
      &:after {
        content: "";
        display: block;
        background-image: url(${polygonImage});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 8px;
        height: 8px;
      }
    }
  }
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    font-size: 30px;
    max-width: 780px;
  }
`;

export const SearchPlanSectionContent = styled.p`
  margin: 16px auto 24px;
  color: ${themeGet('textColor.sectionContent')};
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  position: relative;
  z-index: 2;
  
  .highlighted {
    font-weight: 600;
  }
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    font-size: 18px;
    max-width: 780px;
  }
`;

export const SearchPlanSectionButtonsMobile = styled.div`
  position: relative;
  z-index: 2;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    display: none;
  }
`;

export const SearchPlanSectionButtonsDesktop = styled.div`
  position: relative;
  z-index: 2;
  display: none;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
`;

export const ImageTile = styled.div`
  width: 140px;
  height: 140px;
  background: black;
  position: absolute;
  border-radius: 8px;
  display: none;
  background-size: cover;
  background-position: center;
  z-index: 2;
  
  &.ImageTile__tile-1 {
    right: calc(50% + 432px);
    bottom: 159px;
    background-image: url(${tile1Img});
  }
  
  &.ImageTile__tile-2 {
    right: calc(50% + 629px);
    bottom: 216px;
    background-image: url(${tile2Img});
  }
  
  &.ImageTile__tile-3 {
    right: calc(50% + 483px);
    bottom: 389px;
    background-image: url(${tile3Img});
  }
  
  &.ImageTile__tile-4 {
    left: calc(50% + 407px);
    bottom: 98px;
    background-image: url(${tile4Img});
  }
  
  &.ImageTile__tile-5 {
    left: calc(50% + 610px);
    bottom: 224px;
    background-image: url(${tile5Img});
  }
  
  &.ImageTile__tile-6 {
    left: calc(50% + 451px);
    bottom: 389px;
    background-image: url(${tile6Img});
  }
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    display: block;
  }
`;

const WaveUnderlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  z-index: 1;
  height: 100%;
`;

export const WaveUnderlayMobile = styled(WaveUnderlay)`
  background-image: url(${waveImageMobile});
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    display: none;
  }
`;

export const WaveUnderlayDesktop = styled(WaveUnderlay)`
  background-image: url(${waveImageDesktop});
  display: none;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    display: block;
  }
`;