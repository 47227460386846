export const singlePage = {
  AFASuccess: 'Begäran har skickats framgångsrikt.',
  Close: 'Stänga',
  PaymentCompleted: 'Betalning genomförd', 
  ThanksForPayment: 'Tack för din betalning',
  ReservationCompletedText: 'Du hittar all information gällande din bokning under Bokningar på ditt HPY Camper konto. För din bekvämlighet har vi även skickat dig ett mail med alla detaljer.\n\nI din bokningsbekräftelse hittar du även kontaktuppgifter direkt till campingen, vänligen kontakta dem direkt i det fall du har specifika frågor gällande din vistelse.',
  Gallery : 'Galleri',
  TentSpots : 'Tältplatser',
  TrailerSpots : 'Husbil/vagn',
  Cabins : 'Stugor',
  Description : 'Information',
  Amenities : 'Bekvämligheter',
  Rating : 'Omdöme',
  Ratings : 'Omdömen',
  SeeAllRatings : 'visa alla omdömen',
  ThisWasBad : 'Detta var dåligt',
  ThisWasGood : 'Detta var bra',
  Published : 'Publicerad',
  AskForAvailability : 'Bokningsförfrågan',
  Make : 'Märke',
  Model : 'Modell',
  YearOfProd : 'Årsmodell',
  RegistrationId : 'Reg.nr',
  Pets : 'Husdjur',
  Checkifyouwanttotravelwithyourpets : 'Kryssa här om du reser med husdjur',
  AdditionalComment : 'Övrig kommentar/önskemål',
  All : 'Alla',
  Vehicles : 'Husbil/vagn',
  Tents : 'Tält',
  PricingGroups : 'Boendetyper',
  night : 'natt',
  MakePaymentForTotal : 'Totalt pris för vistelsen',
  AdditionalFeatures : 'Tillval',
  FirstName : 'Förnamn',
  LastName : 'Efternamn',
  Email : 'E-post',
  ZipCode : 'Postnummer',
  CheckIn : 'Incheckning',
  CheckOut : 'Utcheckning',
  SendRequest : 'Skicka bokning',
  Reserve : 'Reservera',
  Location : 'Plats',
  photo : 'Foto',
  photos : 'foton',
  SeePrices : 'Visa boendetyper',
  From : 'från',
  ErrorDateNot : 'Ej tillgängligt de valda datumen.',
  OkDateNot : 'Tillgänglig på valda datum.',
  ThisFieldIsRequired : 'Detta fält är obligatoriskt',
  PaymentInFuture : 'Tack för att du bokat campingen med HpyCamper! Betalningarna kommer att finnas tillgängliga inom kort och informationen kommer att skickas till den e-postadress du använde för registreringsprocessen. Sedan kommer du att kunna fortsätta med betalningen via bokningssidan i din HpyCamper-profil.',
  ReserveError : 'Vissa problem med registerbokning. Var god försök igen.',
  FulfillDates: 'Vänligen ange datumet för den planerade bokningen',
  Camper: 'Husbil/husvagn',
  Cabin: 'Tält',
  Tent: 'Stuga',
  PreferredSpot: 'Boendetyper',
  Adults: 'Vuxna',
  Children: 'Barn',
  no_spots_available: 'Det finns inga lediga platser under den valda perioden.',
}
