import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const Wrapper = styled.div``;

export const Header = styled.div`
    color: ${themeGet('textColor.secondary')};
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
`;

export const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-top: ${props => props.isSmall ? '0' : '0.75rem'};

    img {
        margin-right: 0.75rem;
        width: 1rem;
    }
`;
