export const experiences = {
  experiences: 'Experiences',
  connectionToNature: 'Connection to nature',
  connectionToNature_desc: 'Feel the forest, hear the wind, free your mind in the peace of nature.',
  closeToSea: 'Close to the sea',
  closeToSea_desc: 'The sea gives peace to the soul, and cools you down on a hot day. Here you will find campsites near the sea!',
  golfTime: 'Golf Time!',
  golfTime_desc: 'Life is better when you\'re golfing.',
  enjoyWithTheFamily: 'Enjoy with the family',
  enjoyWithTheFamily_desc: 'There is no better satisfaction than seeing smile of a son.'
}
