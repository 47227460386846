import {useContext} from 'react';

import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import arrowDown from '../../../../../assets/images/select-arrow.jpg';
import userIcon from '../../../../../assets/images/user.svg';
import ViewWithPopup from '../../../../../components/UI/ViewWithPopup/ViewWithPopup';
import {AuthContext} from '../../../../../context/AuthProvider';
import {
  USER_PROFILE_PAGE_AFA,
  USER_PROFILE_PAGE_FAVORITES,
  USER_PROFILE_PAGE_SETTINGS,
  USER_PROFILE_PAGE_BOOKINGS,
} from '../../../../../settings/constant';

import {
  AuthMenuUserDropdownTrigger,
  AuthMenuUserDropdownOptionItem,
  AuthMenuUserDropdownOptions,
  AuthMenuUserDropdownAvatar,
  AuthMenuUserDropdownIcon
} from './AuthMenuUserDropdown.style';

const AuthMenuUserDropdown = ({t}) => {
  const apiProvider = useContext(AuthContext);

  return (
    <ViewWithPopup
      noView={true}
      noMargin={true}
      view={
        <AuthMenuUserDropdownTrigger>
          <AuthMenuUserDropdownAvatar>
            <img src={userIcon} alt="user" />
          </AuthMenuUserDropdownAvatar>
          <div>
            <span>{t('authMenu.myAccount')}</span>
            <AuthMenuUserDropdownIcon src={arrowDown} alt="Arrow down dropdown icon" />
          </div>
        </AuthMenuUserDropdownTrigger>
      }
      popup={
        <AuthMenuUserDropdownOptions>
          <NavLink to={USER_PROFILE_PAGE_BOOKINGS}>
            <AuthMenuUserDropdownOptionItem>
              {t('authMenu.bookings')}
            </AuthMenuUserDropdownOptionItem>
          </NavLink>
          <NavLink to={USER_PROFILE_PAGE_FAVORITES}>
            <AuthMenuUserDropdownOptionItem>
              {t('authMenu.favorites')}
            </AuthMenuUserDropdownOptionItem>
          </NavLink>
          <NavLink to={USER_PROFILE_PAGE_SETTINGS}>
            <AuthMenuUserDropdownOptionItem>
              {t('authMenu.userSettings')}
            </AuthMenuUserDropdownOptionItem>
          </NavLink>
          <NavLink to={USER_PROFILE_PAGE_AFA}>
            <AuthMenuUserDropdownOptionItem>
              {t('authMenu.afa')}
            </AuthMenuUserDropdownOptionItem>
          </NavLink>
          <AuthMenuUserDropdownOptionItem onClick={() => apiProvider.logOut()}>
            {t('authMenu.signOut')}
          </AuthMenuUserDropdownOptionItem>
        </AuthMenuUserDropdownOptions>
      }
    />
  )
}

export default withTranslation()(AuthMenuUserDropdown);
