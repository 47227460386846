export const auth = {
  welcomeTraveler: 'Välkommen!',
  welcomeBack: 'Välkommen tillbaka!',
  logIntoAccount: 'Vänligen logga in på ditt konto',
  dontHaveAccount: 'Har du inget konto än?',
  registerForYourAccount: 'Vänligen registrera dig för ditt konto',
  haveAccountAlready: 'Har du redan ett konto?',
  enterYourEmail: 'Skriv din e-postadress så skickar vi en länk för att återställa ditt lösenord.',
  forgotPassword: 'Har du glömt lösenordet?',
  forgotYourPassword: 'Glömt ditt lösenord?',
  emailIsRequired: 'E-postadress är oblikatorisk',
  rememberMe: 'Kom ihåg mig',
  emailAddress: 'E-postadress',
  enterValidEmail: 'Ange en giltig e-postadress',
  password: 'Lösenord',
  provideValidPassword: 'Lösenordet måste vara minst 8 tecken långt samt innehålla minst en liten bokstav, en stor bokstav och en siffra.',
  confirmPassword: 'Bekräfta lösenordet',
  passwordIsRequired: 'Du behöver ange ett lösenord',
  passwordConfirmationIsRequired: 'Du behöver ange ditt lösenord',
  passwordConfirmationNotMatch: 'Lösenorden stämmer inte med varandra',
  registrationCompleted: 'Registreringen har slutförts. Kontrollera din e-post för att bekräfta kontot',
  sendRequest: 'Skicka',
  somethingWentWrong: 'Något gick fel, vänligen prova igen.',
  messageHasBeenSent: 'Ett meddelande med länk för att återställa ditt lösenord är skickat till din e-post.',
  errorIncorrectUsernameOrPassword: 'Error: Ogiltigt användarnamn eller lösenord.',
  errorUsernameAlreadyExists: 'Error: Användaren finns redan.',
  errorInvalidPassword: 'Error: Ogiltigt lösenord.',
  login: 'Logga in',
  register: 'Registrera',
  submit: 'Skicka in',
  resendCode: 'Få en ny bekräftelsekod',
  codeResent: 'Bekräftelsekoden är skickad, titta i din e-post',
  registrationConfirmationCode: 'Bekräftelsekod',
  registrationCodeIsRequired: 'Du behöver din bekräftelsekod',
  welcomeNewHeader: 'Välkommen!',
  welcomeNewContent: 'Kontrollera din inkorg och klistra in \nbekräftelsekoden nedan.',
  'Internal Server Error': 'Någonting blev fel, vänligen försök igen.',
  'user.account_confirmed': 'Ditt konto är skapat, vi skickar dig till inloggningssidan.',
  'user.new_password_confirmed': 'Ditt nya lösenord har sparats. Du kommer att omdirigeras till inloggningssidan.',
  'user.unauthorized.incorrect_username_or_password': 'Felaktigt användarnamn eller lösenord.',
  'user.username_already_exists': 'Användaren finns redan.',
  'user.invalid_parameter': 'Det verkar som om en av leverantörsparametrarna är felaktig',
  'user.password.incorrect_confirmation_code': 'Du har angivit en felaktig bekräftelsekod',
  'CodeMismatchException': 'Du har angivit en felaktig bekräftelsekod',
  decodeEmailError: 'Fel vid avkodning av e-postmeddelande. Vänligen fyll i e-postinmatning manuellt',
  missingRequestData: 'Kan inte göra förfrågan. Vissa data saknas',
  InvalidPasswordException: 'Felaktigt lösenord.',
  ExpiredCodeException: 'Din bekräftelsekod stämmer inte, vänligen debbelkolla att den är rätt. Behöver du en ny kod så klickar du på länken nedan',
  NotAuthorizedException: 'Du kan inte utföra den operationen just nu',
  simplifiedBookingFor: 'Alla Sveriges campingplatser',
  campers: 'På ett',
  and: 'och',
  camping: 'samma ställe',
}
