export const error = {
  backToHomepage: 'Tillbaka till startsidan',

  error404: {
    pageNotFound: 'Sidan hittas inte',
    nothingWasFoundHere: 'Här hittades ingenting',
    contentText: `Sidan du letar efter kan ha tagits bort eller är tillfälligt otillgänglig. Försök att använda sökning, 
    toppnavigering eller länkar från sidfoten.`,
  },

  error500: {
    title: 'Fel inträffade',
    reloadPage: 'Ladda om sidan',
    header: 'Något gick fel.',
    content: 'Försök att uppdatera sidan eller kontakta oss gärna om problemet kvarstår.'
  }
}
